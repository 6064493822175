import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import Utility from '../../../../Utility/Utility';
import moment from 'moment';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary'
import CustomInput from '../../../../Utility/Components/CustomInput'
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent'
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker'

import { withNamespaces } from 'react-i18next';

function BookingCancellationDetails(props) {
    let bookingFromDate = moment(props.bookingDetailsData.checked_in).format('DD-MM-YYYY')
    let bookingToDate = moment(props.bookingDetailsData.checked_out).format('DD-MM-YYYY')
    const currentDate = new Date();
    let cancellationDate = moment(currentDate).format('DD-MM-YYYY')
    let totalAmount = props.bookingDetailsData.hasOwnProperty('total_amount') && props.bookingDetailsData.total_amount
    let paidAmount = props.bookingDetailsData.hasOwnProperty('total_paid') && props.bookingDetailsData.total_paid
    let minimumBookingPercent = props.bookingDetailsData.hasOwnProperty('minimum_percent') && props.bookingDetailsData.minimum_percent
    let minimumBookingAmount = props.bookingDetailsData.hasOwnProperty('minimum_booking_amount') && props.bookingDetailsData.minimum_booking_amount

    let cancellationPercent = props.bookingDetailsData.hasOwnProperty('cancellation_percent') && props.bookingDetailsData.cancellation_percent
    let cancellationAmount = props.bookingDetailsData.hasOwnProperty('cancellation_amount') && props.bookingDetailsData.cancellation_amount
    let amountRefunded = props.bookingDetailsData.hasOwnProperty('amount_refunded') && props.bookingDetailsData.amount_refunded

    let currency = props.bookingDetailsData.hasOwnProperty('currency') && props.bookingDetailsData.currency

    let cancellation_gst_percentage = props.bookingDetailsData.hasOwnProperty('cancellation_gst_percentage') && props.bookingDetailsData.cancellation_gst_percentage
    let gstAmount = 0
    if (props.cancellationAmount > 0) {
        gstAmount = (cancellation_gst_percentage / 100) * props.cancellationAmount;
    }
    let withoutFlowCancellationPoliciesRefundedAmount = Number(paidAmount) - (Number(gstAmount) + Number(props.cancellationAmount));

    let full_amount_without_any_tax = props.bookingDetailsData.hasOwnProperty('full_amount_without_any_tax') && props.bookingDetailsData.full_amount_without_any_tax

    let cancellation_gst_amount = props.bookingDetailsData.hasOwnProperty('cancellation_gst_amount') && props.bookingDetailsData.cancellation_gst_amount
    let cancellation_full_amount = props.bookingDetailsData.hasOwnProperty('cancellation_full_amount') && props.bookingDetailsData.cancellation_full_amount

    let room_amount_without_any_tax = props.bookingDetailsData.hasOwnProperty('room_amount_without_any_tax') && props.bookingDetailsData.room_amount_without_any_tax
    let cancellation_amount_on_room = props.bookingDetailsData.hasOwnProperty('cancellation_amount_on_room') && props.bookingDetailsData.cancellation_amount_on_room
    let cancellation_room_gst_amount = props.bookingDetailsData.hasOwnProperty('cancellation_room_gst_amount') && props.bookingDetailsData.cancellation_room_gst_amount
    let cancellation_room_full_amount = props.bookingDetailsData.hasOwnProperty('cancellation_room_full_amount') && props.bookingDetailsData.cancellation_room_full_amount
    let amount_room_refunded = props.bookingDetailsData.hasOwnProperty('amount_room_refunded') && props.bookingDetailsData.amount_room_refunded

    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension">
                    <div className='bookingDetailsInner'>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Check-In <span>|</span></label>
                            <label className="rightBox">{bookingFromDate != "" ? bookingFromDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Check-Out <span>|</span></label>
                            <label className="rightBox">{bookingToDate != "" ? bookingToDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow'>
                            <label className="leftBox">Cancellation Date <span>|</span></label>
                            <label className="rightBox">{cancellationDate != "" ? cancellationDate : ""}</label>
                        </div>
                        <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Total Amount <span className='inclusiveExclusive'>(Exclusive of all taxes)</span> <span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(full_amount_without_any_tax).toFixed(2)}</label>
                        </div>
                        {/* <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Room Amount <span className='inclusiveExclusive'>(Exclusive of all taxes)</span> <span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(room_amount_without_any_tax).toFixed(2)}</label>
                        </div>
                        <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Total Amount <span className='inclusiveExclusive'>(Inclusive of all taxes)</span> <span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(totalAmount).toFixed(2)}</label>
                        </div> */}

                        <div className='bookingDetailsRow boldText amountBox'>
                            <label className="leftBox">Paid Amount <span className='tillDate'>(Till {cancellationDate})</span><span>|</span></label>
                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(paidAmount).toFixed(2)}</label>
                        </div>
                    </div>
                    {/* <div className="edittext bookingRulesRadioBtn">
                        <label className="checkboxcontainer">Apply cancellation policies.
                            <input type="checkbox" checked={props.bookingRulesFlag} name="checkbox" onClick={(e) => props.handleChangeBookingRules(e, "follow")} />
                            <span className="checkmark"></span>
                        </label>
                        {props.bookingRulesFlag &&
                            <div className='subOptionTaxes'>
                                <div className="edittext bookingRulesRadioBtn">
                                    <label className="checkboxcontainer">Total Amount <span className='exLabel'>(Exclusive of all taxes)</span>
                                        <input type="checkbox" checked={!props.totalAmountExAllTaxesFlag} name="checkbox" onClick={(e) => props.handleChangeBookingRules(e, "total_amount_ex_all_taxes")} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="checkboxcontainer">Room Amount <span className='exLabel'>(Exclusive of all taxes)</span>
                                        <input type="checkbox" checked={props.totalAmountExAllTaxesFlag} name="checkbox" onClick={(e) => props.handleChangeBookingRules(e, "total_amount_room_ex_all_taxes")} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>}
                        <label className="checkboxcontainer">Without cancellation policies.
                            <input type="checkbox" checked={!props.bookingRulesFlag} name="checkbox" onClick={(e) => props.handleChangeBookingRules(e, "unfollow")} />
                            <span className="checkmark"></span>
                        </label>
                    </div> */}

                    <div className='bookingDetailsInner topSpace'>
                        {props.bookingRulesFlag &&
                            <>
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Deduct ({Number(cancellationPercent).toFixed(2)} %)<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellationAmount).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_amount_on_room).toFixed(2)}</label>}
                                </div>
                                {cancellation_gst_percentage > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Cancellation GST ({Number(cancellation_gst_percentage).toFixed(2)} %)<span>|</span></label>
                                        {!props.totalAmountExAllTaxesFlag ?
                                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_gst_amount).toFixed(2)}</label> :
                                            <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(cancellation_room_gst_amount).toFixed(2)}</label>
                                        }
                                    </div>
                                }
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Total Cancellation Amount<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(cancellation_full_amount).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(cancellation_room_full_amount).toFixed(2)}</label>
                                    }
                                </div>
                                {/* new add */}
                                <div className='bookingDetailsRow boldText amountBox'>
                                    <label className="leftBox">Refund Amount<span>|</span></label>
                                    {!props.totalAmountExAllTaxesFlag ?
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(amountRefunded).toFixed(2)}</label> :
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {parseFloat(amount_room_refunded).toFixed(2)}</label>
                                    }
                                </div>
                            </>}

                        {!props.bookingRulesFlag &&
                            <>
                                <div className='bookingDetailsRow '>
                                    <ErrorBoundary title="CustomInput Error">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName={"Cancellation Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                            errorLabel={props.cancellationAmountError}
                                            name="cancellation_amount"
                                            type="text"
                                            value={props.cancellationAmount}
                                            labelPresent={true}
                                            requiredStar={false}
                                            onChange={(e) => props.handleChangeBookingRules(e, 'cancellation_amount')}
                                        />
                                    </ErrorBoundary>
                                </div>
                                {cancellation_gst_percentage > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Cancellation GST ({Number(cancellation_gst_percentage).toFixed(2)} %)<span>|</span></label>
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {Number(gstAmount).toFixed(2)}</label>
                                    </div>
                                }
                                {amountRefunded != "" && amountRefunded > 0 &&
                                    <div className='bookingDetailsRow boldText amountBox'>
                                        <label className="leftBox">Refund Amount<span>|</span></label>
                                        <label className="rightBox">{currency != "" ? Utility.getCurrencySymbol(currency) : ""} {withoutFlowCancellationPoliciesRefundedAmount.toFixed(2)}</label>
                                    </div>}
                            </>
                        }
                    </div>

                    {amountRefunded != "" && amountRefunded > 0 &&
                        <div className='paymentModeSectionInner'>
                            <div className="dropdowninnerbox">
                                <ErrorBoundary title="CustomInput Error">
                                    <label>Payment Mode <span>*</span></label>
                                    <AutosuggestComponent
                                        handleOnChange={(e) => props.handelChangePaymentMode(e, "payment_mode")}
                                        options={props.paymentModeList}
                                        selectedValue={props.selectedPaymentModeData}
                                        name=''
                                        isMulti={false}
                                        placeholder=""
                                        isSearchable={true}
                                        menuPosition="fixed"
                                        isDisabled={true}
                                    />
                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.paymentModeData}</div>
                                </ErrorBoundary>
                            </div>
                            {/* {props.selectedPaymentModeData.value == "cash" &&
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                    errorLabel={props.paymentModeFormDataError.amount}
                                                    name="amount"
                                                    type="text"
                                                    value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                    labelPresent={true}
                                                    requiredStar={true}
                                                    isDisabled={true}
                                                    onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='dropdowninnerbox cancellationDropPosition'>
                                                <label>Transaction Date & Time <span>*</span></label>
                                                <ErrorBoundary title="CommonDateTimePicker Error">
                                                    <CommonDateTimePicker
                                                        dateRange={false}
                                                        selected={props.selectedDateTime}
                                                        onChange={(e) => props.handelChangePaymentMode(e, "transaction_date")}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={1}
                                                        dateFormat="dd-MM-yyyy HH:mm"
                                                        placeholderText="Select date"
                                                        minDate={new Date(moment.now())}
                                                    />
                                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.transaction_date}</div>
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={props.paymentModeRef}></div>
                                </div>}
                            {props.selectedPaymentModeData.value == "online" &&
                                <div className='col-md-12'>
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                    errorLabel={props.paymentModeFormDataError.amount}
                                                    name="amount"
                                                    type="text"
                                                    value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                    labelPresent={true}
                                                    requiredStar={true}
                                                    isDisabled={true}
                                                    onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="Account Holder Name"
                                                errorLabel={props.paymentModeFormDataError.account_holder_name}
                                                name="account_holder_name"
                                                type="text"
                                                value={props.paymentModeFormData.account_holder_name}
                                                labelPresent={true}
                                                requiredStar={props.isUpiIdGiven ? false : true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'account_holder_name')}
                                            />

                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="Account Number"
                                                errorLabel={props.paymentModeFormDataError.account_number}
                                                name="account_number"
                                                type="text"
                                                value={props.paymentModeFormData.account_number}
                                                labelPresent={true}
                                                requiredStar={props.isUpiIdGiven ? false : true}
                                                onChange={(e) => { props.handelChangePaymentMode(e, "account_number") }}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="IFSC Code"
                                                errorLabel={props.paymentModeFormDataError.ifsc_code}
                                                name="ifsc_code"
                                                type="text"
                                                value={props.paymentModeFormData.ifsc_code}
                                                labelPresent={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'ifsc_code')}
                                                requiredStar={false}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="Bank Name"
                                                errorLabel={props.paymentModeFormDataError.bank_name || ""}
                                                name="bank_name"
                                                type="text"
                                                value={props.paymentModeFormData.bank_name}
                                                labelPresent={true}
                                                requiredStar={props.isUpiIdGiven ? false : true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'bank_name')}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="Branch Name"
                                                errorLabel={props.paymentModeFormDataError.branch_name}
                                                name="branch_name"
                                                type="text"
                                                value={props.paymentModeFormData.branch_name}
                                                labelPresent={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'branch_name')}
                                                requiredStar={false}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="UPI Id"
                                                errorLabel={props.paymentModeFormDataError.upi_id}
                                                name="upi_id"
                                                type="text"
                                                value={props.paymentModeFormData.upi_id}
                                                labelPresent={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'upi_id')}
                                                requiredStar={false}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName="Reference Id"
                                                errorLabel={props.paymentModeFormDataError.reference_id}
                                                name="reference_id"
                                                type="text"
                                                value={props.paymentModeFormData.reference_id}
                                                labelPresent={true}
                                                requiredStar={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'reference_id')}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='dropdowninnerbox cancellationDropPosition'>
                                                <label>Transaction Date & Time <span>*</span></label>
                                                <ErrorBoundary title="CommonDateTimePicker Error">
                                                    <CommonDateTimePicker
                                                        dateRange={false}
                                                        selected={props.selectedDateTime}
                                                        onChange={(e) => props.handelChangePaymentMode(e, "transaction_date")}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={1}
                                                        dateFormat="dd-MM-yyyy HH:mm"
                                                        placeholderText="Select date"
                                                        minDate={new Date(moment.now())}
                                                    />
                                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.transaction_date}</div>
                                                </ErrorBoundary>
                                            </div>
                                        </div>
                                        <div className="col-md-4 relativeBox">
                                            <div className="screenshotBoxes">
                                                <div className='input_field_inner screenshot'>
                                                    <label className="input_label">Upload Screenshot </label>
                                                    <div className="input_containers">
                                                        <div className="upload-btn-wrapper">
                                                            <button className="btn">Browse</button>
                                                            <input type="file" name="myfile"
                                                                onChange={(e) => props.handelChangePaymentMode(e, 'screenshot')}
                                                            />
                                                        </div>
                                                        <p>{props.selectedImageName ? props.selectedImageName : ""}</p>

                                                    </div>
                                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.screenshot}</div>
                                                </div>
                                                <div className="canvas">
                                                    <img src={props.addPreviewImage.length > 0 ? props.addPreviewImage[0] : require('../../../../Utility/Public/images/no-picture.jpg')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ref={props.paymentModeRef}></div>
                                </div>
                            } */}
                        </div>
                    }
                    {amountRefunded != "" && amountRefunded > 0 && props.selectedPaymentModeData.value == "credit_note" &&
                        <div className='creditNotesSection'>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <ErrorBoundary title="CustomInput Error">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                errorLabel={props.paymentModeFormDataError.amount}
                                                name="amount"
                                                type="text"
                                                value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                labelPresent={true}
                                                requiredStar={true}
                                                isDisabled={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='dropdowninnerbox cancellationDropPosition'>
                                            <label>Credit Note Valid Till <span>*</span></label>
                                            <ErrorBoundary title="CommonDateTimePicker Error">
                                                <CommonDateTimePicker
                                                    dateRange={false}
                                                    selected={props.selectedCreditNoteValidityDateTime}
                                                    onChange={(e) => props.handelChangePaymentMode(e, "credit_notes_validity")}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Select date"
                                                    minDate={new Date(moment.now())}
                                                    disabled={true}
                                                />
                                                <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.credit_notes_validity}</div>
                                            </ErrorBoundary>

                                        </div>
                                    </div>
                                </div>
                                <div className='credit-note-text'>
                                    Note: <span>The refunded amount will be converted into credit notes, and corresponding confirmation will be sent to you over the email. For any other refund processes, please contact the administrator.</span>
                                </div>
                                <div ref={props.paymentModeRef}></div>
                            </div>
                        </div>
                    }
                    <div className='col-md-12 cancellation_reason_box'>
                        <ErrorBoundary title="CustomInput Error">
                            <CustomInput
                                parentClassName="input_field_inner"
                                labelName="Reason for Cancellation"
                                errorLabel={props.paymentModeFormDataError.cancellation_reason}
                                name="cancellation_reason"
                                type="textarea"
                                value={props.paymentModeFormData.cancellation_reason}
                                labelPresent={true}
                                requiredStar={true}
                                onChange={(e) => props.handelChangePaymentMode(e, 'cancellation_reason')}
                            />
                        </ErrorBoundary>
                    </div>
                    <div ref={props.reasonForCancellationRef}></div>
                </div>
            </div >
        </div >
    );
};

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token
    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(BookingCancellationDetails));
