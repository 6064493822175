import React, { Component, useState, useEffect } from 'react';
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/homedoc.scss';
import '../Assets/css/homeresponsivedoc.scss';
import CustomInput from '../../../../Utility/Components/CustomInput';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { filterPanelList } from '../Controller/HomeController';
import { loaderStateTrue, loaderStateFalse, selectChooseBranch, userSearchData, roleWisePermission } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials, logOutApp } from '../../../../../src/GlobalModules/Login/Actions/LoginAction';
import MultiRangeSlider from "../../../../Utility/Components/MultiRangeSlider";
//import MultiRangeSlider from "multi-range-slider-react";

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import SkeletonThemeFilterContent from '../../../../Utility/Components/SkeletonThemeFilterContent'
import Utility from '../../../../Utility/Utility';
function FilterPanel(props) {

    const [isViewportBottom, setIsViewportBottom] = useState(false);
    const [filterDataFromApi, setFilterDataFromApi] = useState({});
    const [isFilterDataChanged, setIsFilterDataChanged] = useState(false);
    const [apiCallFilterFinish, setApiCallFilterFinish] = useState(false)

    // const [propertyTypeCheckedValue, setPropertyTypeCheckedValue] = useState([]);
    // const [amenitiesTypeCheckedValue, setAmenitiesTypeCheckedValue] = useState([]);
    const [budgetRange, setBudgetRange] = useState({ min: 0, max: 0 });
    const [budgetValue, setBudgetValue] = useState({
        min: props.userSearchDataFromReducer?.filterData?.budget_min ? props.userSearchDataFromReducer.filterData.budget_min : 0,
        max: props.userSearchDataFromReducer?.filterData?.budget_max ? props.userSearchDataFromReducer.filterData.budget_max : 0
    });

    const [filterPanelData, setFilterPanelData] = useState({
        // budget_range_min: props.userSearchDataFromReducer?.filterData?.budget_range_min? props.userSearchDataFromReducer.filterData.budget_range_min: 0,
        // budget_range_max: props.userSearchDataFromReducer?.filterData?.budget_range_max? props.userSearchDataFromReducer.filterData.budget_range_max: 10000,

        budget_min: props.userSearchDataFromReducer?.filterData?.budget_min ? props.userSearchDataFromReducer.filterData.budget_min : 0,
        budget_max: props.userSearchDataFromReducer?.filterData?.budget_max ? props.userSearchDataFromReducer.filterData.budget_max : 0,
        property_type: props.userSearchDataFromReducer?.filterData?.property_type ? props.userSearchDataFromReducer.filterData.property_type : [],
        property_rating: props.userSearchDataFromReducer?.filterData?.property_rating ? props.userSearchDataFromReducer.filterData.property_rating : [],
        amenities: props.userSearchDataFromReducer?.filterData?.amenities ? props.userSearchDataFromReducer.filterData.amenities : [],
        meals: props.userSearchDataFromReducer?.filterData?.meals ? props.userSearchDataFromReducer.filterData.meals : []
    })

    const [mealList, setMealList] = useState(["Breakfast", "Lunch", "Dinner"]);

    const [propertyRatingList, setPropertyRatingList] = useState(["1 star", "2 stars", "3 stars", "4 stars", "5 stars"]);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY;
            if (scrollTop >= 1050) {
                setIsViewportBottom(true);
            } else {
                setIsViewportBottom(false);
            }
            if (scrollPosition + windowHeight > documentHeight - 600) {

                setIsViewportBottom(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };


    }, []);


    useEffect(() => {
        getFilterPanelList();
    }, [])

    const getFilterPanelList = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let data = {}
        setApiCallFilterFinish(false)
        setFilterDataFromApi([])
        //loaderStateTrue();
        filterPanelList(data).then((response) => {
            setFilterDataFromApi(response.data)
            //loaderStateFalse();
            setApiCallFilterFinish(true)
            props.filterApiSuccessCheckFn(true)
        }).catch((error) => {
            setApiCallFilterFinish(true)
            //loaderStateFalse();
        });
    }

    useEffect(() => {

        setBudgetRange({ min: 0, max: filterDataFromApi.room_rate ? parseInt(filterDataFromApi.room_rate) : 0 })
        // setBudgetValue({ min: 0, max: filterDataFromApi.room_rate? parseInt(filterDataFromApi.room_rate): 0 })
        setBudgetValue({
            min: props.userSearchDataFromReducer?.filterData?.budget_min ? props.userSearchDataFromReducer.filterData.budget_min : 0,
            max: props.userSearchDataFromReducer?.filterData?.budget_max ? props.userSearchDataFromReducer.filterData.budget_max : filterDataFromApi.room_rate ? parseInt(filterDataFromApi.room_rate) : 0
        });

    }, [filterDataFromApi])



    const propertyTypeUi = () => {
        if (!filterDataFromApi || !filterDataFromApi.property_type) {
            return null;
        }
        let propertyType = filterDataFromApi.property_type;
        let arry = [];
        if (propertyType.length > 0) {
            propertyType.map((val, idx) => {
                arry.push(
                    <>
                        <li key={idx}>
                            <label className="chkContainer"> {val}
                                <input type="checkbox"
                                    value={val}
                                    onChange={handlePropertyTypeCheckboxChange}
                                    //checked={filterPanelData.property_type.includes(val)}

                                    checked={props.userSearchDataFromReducer?.filterData?.property_type && props.userSearchDataFromReducer.filterData.property_type.length > 0 ?
                                        props.userSearchDataFromReducer.filterData.property_type.includes(val)
                                        : false
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    </>
                )
            })
        }
        return arry;
    }

    const handlePropertyTypeCheckboxChange = (event) => {
        const { value, checked } = event.target;
        debounceDispatchPropertyTypeList(value, checked);
    };

    const debounceDispatchPropertyTypeList = Utility.debounce((value, checked) => {
        setFilterPanelData({
            ...filterPanelData,
            property_type: checked
                ? [...filterPanelData.property_type, value]
                : filterPanelData.property_type.filter((item) => item !== value)
        });
        // setIsFilterDataChanged(true)
    }, 300);




    const propertyRatingUi = () => {

        let arry = [];
        if (propertyRatingList.length > 0) {
            propertyRatingList.map((val, idx) => {
                arry.push(
                    <>
                        <li key={idx}>
                            <label className="chkContainer"> {val}
                                <input type="checkbox"
                                    value={val}
                                    onChange={handlePropertyRatingCheckboxChange}
                                    //checked={filterPanelData.property_rating.includes(val)}
                                    checked={props.userSearchDataFromReducer?.filterData?.property_rating && props.userSearchDataFromReducer.filterData.property_rating.length > 0 ?
                                        props.userSearchDataFromReducer.filterData.property_rating.includes(val)
                                        : false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    </>
                )
            })
        }
        return arry;
    }

    const handlePropertyRatingCheckboxChange = (event) => {
        const { value, checked } = event.target;
        debounceDispatchPropertyRatingList(value, checked);
    };

    const debounceDispatchPropertyRatingList = Utility.debounce((value, checked) => {
        setFilterPanelData({
            ...filterPanelData,
            property_rating: checked
                ? [...filterPanelData.property_rating, value]
                : filterPanelData.property_rating.filter((item) => item !== value)
        });
        setIsFilterDataChanged(true)
    }, 300);



    const amenitiesTypeUi = () => {
        if (!filterDataFromApi || !filterDataFromApi.amenities_type) {
            return null;
        }
        let amenitiesType = filterDataFromApi.amenities_type;
        let arry = [];
        if (amenitiesType.length > 0) {
            amenitiesType.map((val, idx) => {
                arry.push(
                    <>
                        <li key={idx}>
                            <label className="chkContainer"> {val}
                                <input type="checkbox"
                                    value={val}
                                    onChange={handleAmenitiesTypeCheckboxChange}
                                    //checked={filterPanelData.amenities.includes(val)}
                                    checked={props.userSearchDataFromReducer?.filterData?.amenities && props.userSearchDataFromReducer.filterData.amenities.length > 0 ?
                                        props.userSearchDataFromReducer.filterData.amenities.includes(val)
                                        : false
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    </>
                )
            })
        }
        return arry;
    }

    const handleAmenitiesTypeCheckboxChange = (event) => {
        const { value, checked } = event.target;
        debounceDispatchAmenitiesTypeList(value, checked);
    };

    const debounceDispatchAmenitiesTypeList = Utility.debounce((value, checked) => {
        setFilterPanelData({
            ...filterPanelData,
            amenities: checked
                ? [...filterPanelData.amenities, value]
                : filterPanelData.amenities.filter((item) => item !== value)
        });
        setIsFilterDataChanged(true)
    }, 300);


    const mealListUi = () => {
        let arry = [];
        if (mealList.length > 0) {
            mealList.map((val, idx) => {
                arry.push(
                    <>
                        <li key={idx}>
                            <label className="chkContainer"> {val}
                                <input type="checkbox"
                                    value={val}
                                    onChange={mealListCheckboxChange}
                                    //checked={filterPanelData.meals.includes(val)}
                                    checked={props.userSearchDataFromReducer?.filterData?.meals && props.userSearchDataFromReducer.filterData.meals.length > 0 ?
                                        props.userSearchDataFromReducer.filterData.meals.includes(val)
                                        : false}

                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>

                    </>
                )
            })
        }
        return arry;
    }

    const mealListCheckboxChange = (event) => {
        const { value, checked } = event.target;
        debounceDispatchMealList(value, checked);
    };

    const debounceDispatchMealList = Utility.debounce((value, checked) => {
        setFilterPanelData({
            ...filterPanelData,
            meals: checked
                ? [...filterPanelData.meals, value]
                : filterPanelData.meals.filter((item) => item !== value)
        });
        setIsFilterDataChanged(true)
    }, 300);






    useEffect(() => {
        if (isFilterDataChanged) {
            setIsFilterDataChanged(false);
        }
    }, [isFilterDataChanged]);


    useEffect(() => {
        if (filterPanelData) {
            let reducerData = props.userSearchDataFromReducer
            let tempSearchData = { ...reducerData, ["filterData"]: filterPanelData }
            props.userSearchData(tempSearchData)
        }
        props.sendDataToParent(filterPanelData);

    }, [filterPanelData]);

    // useEffect(() => {
    //     const { userSearchDataFromReducer } = props;
    // //console.log("userSearchDataFromReducer1111", props.userSearchDataFromReducer);

    //     if (userSearchDataFromReducer?.start_date && userSearchDataFromReducer.start_date != "" && userSearchDataFromReducer?.end_date && userSearchDataFromReducer.end_date != "" && userSearchDataFromReducer?.destination && userSearchDataFromReducer.destination != "" && userSearchDataFromReducer?.adult && userSearchDataFromReducer.adult != "") {
    //         props.searchHotelBranch();
    //     }

    // }, [props.userSearchDataFromReducer])


    const handleSliderChange = (newValue) => {

        const newMin = Math.max(budgetRange.min, newValue.min); // Ensure minimum doesn't go below budgetRange.min
        const newMax = Math.min(budgetRange.max, newValue.max);

        if (newValue.max <= budgetRange.max) {
            setBudgetValue({ min: newMin, max: newMax })
            setIsFilterDataChanged(true);
        }
    };

    const handleSliderFinalChange = (newValue) => {
        //console.log("aaa newMin", newValue.min)

        //console.log("aaa newMax", newValue.max)
        setFilterPanelData({ ...filterPanelData, budget_min: newValue.min, budget_max: newValue.max });
        setIsFilterDataChanged(true);
    };


    return (
        <>
            {apiCallFilterFinish ?
                <div className={` ${props.leftSearchFlag + ' ' + "col-xl-3 col-lg-3 col-md-4 col-sm-4 pushMenu"}`}>
                    <div className='searchLeft'>
                        <button onClick={() => { props.crossFN() }} className='crossBtn'>✖</button>
                        <div className={`innsearchLeftBox ${isViewportBottom ? 'is-sticky' : ''}`}>

                            <h6>Filter by</h6>
                            <div className='innfilterBoxes'>
                                <div className='filterBox'>
                                    <strong>Your budget (per night)</strong>
                                    <InputRange
                                        minValue={budgetRange.min}
                                        maxValue={budgetRange.max}
                                        value={budgetValue}
                                        onChange={handleSliderChange}
                                        onChangeComplete={handleSliderFinalChange}
                                    //allowSameValues={true}
                                    //draggableTrack={false}
                                    />
                                    {/* <MultiRangeSlider
                                    min={ 0}
                                    max={ 10000 }
                                    value={500}
                                    //value={props.userSearchDataFromReducer?.filterData?.budget_min ? props.userSearchDataFromReducer?.filterData?.budget_min : 0}
                                    onChange={({ min, max }) => onChangeBudgetFilter(min, max)}

                                /> */}

                                </div>
                                <div className='filterBox'>
                                    <strong>Property type</strong>
                                    <ul>
                                        {propertyTypeUi()}

                                    </ul>

                                </div>
                                <div className='filterBox'>
                                    <strong>Property rating</strong>
                                    <ul>
                                        {propertyRatingUi()}
                                    </ul>
                                </div>

                                <div className='filterBox'>
                                    <strong>Property amenities</strong>
                                    <ul>
                                        {amenitiesTypeUi()}
                                    </ul>
                                </div>
                                <div className='filterBox'>
                                    <strong>Meals</strong>
                                    <ul>
                                        {mealListUi()}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> :
                <SkeletonThemeFilterContent />}
        </>
    );
}

FilterPanel.defaultProps = {

}

const mapStateToProps = (globalState) => {
   // console.log("globalState", globalState)
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        userSearchDataFromReducer: globalState.mainReducerData.userSearchData
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials, logOutApp, roleWisePermission, userSearchData, setToken, })(FilterPanel);
