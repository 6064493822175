import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Assets/css/footerListdoc.scss';
import '../Assets/css/footerListresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu';

function Faq(props) {


    const history = useHistory();

    useEffect(() => {


    }, []);

    const homeMenuClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }



    return (

        <div className="aboutUsPage">
            <div className='footerBoxCover'>
                <div className='container'>
                    <div className="filterBox filterBoxMBottom">
                        <div className="leftSectionFilter">
                            <h2 className="pageTitle">Frequently Asked Questions</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    faq_Display={true}
                    homeMenuClick={homeMenuClick}
                    active_faq={true}
                />

                <div className='aboutPageDetails'>
                    <div className='row'>
                    <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">What is The Staying?</h5>
                                 <div className="card-body">
                                    <p className="card-text">The Staying is an online platform that allows users to search for properties, compare prices, and make reservations.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">How do I search for properties on The Staying?</h5>
                                 <div className="card-body">
                                    <p className="card-text">To search for properties, simply enter your destination, check-in and check-out dates, number of guests. Click the "Search" button to see a list of available properties.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">Can I book multiple rooms for the same stay?</h5>
                                 <div className="card-body">
                                    <p className="card-text">Yes, you can book multiple rooms for the same stay. Simply select the number of rooms you need when making your reservation.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">How do I make a reservation on The Staying?</h5>
                                 <div className="card-body">
                                    <p className="card-text">To make a reservation, select the property and room type you want, enter your details, and proceed to the payment page to confirm your booking.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">How do I know if my reservation is confirmed?</h5>
                                 <div className="card-body">
                                    <p className="card-text">Once you complete the booking process, you will receive a confirmation email with all the details of your reservation.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                            <h5 className="card-header">Can I cancel or modify my reservation?</h5>
                                 <div className="card-body">
                                    <p className="card-text">Yes, you can cancel or modify your reservation. Please check the cancellation policy of the property you booked for more information.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}





const mapStateToProps = (globalState) => {
    return {
        // userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })
    (withNamespaces()(Faq));