import React, { Component } from 'react';
import '../Public/css/footer.scss';
import '../Public/css/footerresponsivedoc.scss';
import { Container } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

function Footer(props) {

    const history = useHistory();
    const { type } = useParams();
    const aboutUs = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/about_us`);
    }
    const termsConditions = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/terms_Conditions`);
    }
    const faq = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/faq`);
    }
    const PrivacyPolicy = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/privacy_policy`);
    }
    const Sitemap = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/sitemap`);
    }
    
    
    return (
        <>
            <div className="footerSec">
                <div className="footerSecTop">
                    <Container>
                        <div className="footerInn">
                            <div className="row">
                                <div className="col-xl-4 col-md-12 col-sm-12">
                                    <div className="footerBox">
                                    <a href="#" className="onlyMob"><img src={require('../Public/images/stayingLogo.png')} className="footerLogo" /></a>
                                          <p>Users can search for properties based on criteria such as location, check-in/check-out dates, number of guests, and room preferences. <a href="#" className="onlyDesktop"><img src={require('../Public/images/stayingLogo.png')} className="footerLogo" /></a> Detailed information about each property, including room types, amenities, photos, and reviews.</p>
                                        <p>A mobile-responsive design that ensures the site is accessible and user-friendly on smartphones and tablets.</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-4 col-sm-6 col-6">
                                    <div className="footerBox">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a><button onClick={() => { aboutUs() }}>About Us</button></a></li>
                                            {/* <li><a href="/">Resorts</a></li>
                                            <li><a href="/">Villas</a></li>
                                            <li><a href="/">Hostels</a></li>
                                            <li><a href="/">Guest houses</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-4 col-sm-6 col-6">
                                    <div className="footerBox">
                                        <ul>
                                            <li><a><button onClick={() => { faq() }}>FAQ</button></a></li>
                                            <li><a><button onClick={() => { PrivacyPolicy() }}>Privacy Policy</button></a></li>
                                            <li><a><button onClick={() => { termsConditions() }}>Terms &amp; Conditions</button></a></li>
                                            <li><a><button onClick={() => { Sitemap() }}>Sitemap</button></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-12">
                                    <div className="footerBox footInfo">
                                        <ul>
                                            <li><strong>Location -</strong>
                                                S-129, B.P. Township,<br />
                                                Patuli, Kolkata-700094</li>
                                            <li><strong>Contact -</strong><a href="tel:3340705047">+91 33 40705047</a></li>
                                            <li><strong>Mail Us -</strong><a href="mailto:info@thestaying.com">info@thestaying.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                {/* <div className="footerSecBottom">
                    <Container>
                        <div className="footerInn">
                            <div className="row">
                                <div className="col-xl-6 col-md-12 col-sm-12">
                                    <div className="footerBox">
                                        <h6>Why The Staying?</h6>
                                        <p>Online hotel booking management refers to the process of handling reservations, customer information, and other aspects of hotel operations through an online platform. This system streamlines the booking process, making it more convenient for both guests and hotel staff.</p>
                                        <p>Here are key components and features typically associated with online hotel booking management.</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-12 col-sm-12">
                                    <div className="footerBox">
                                        <h6>Booking Hotels with The Staying</h6>
                                        <p>A hotel booking platform like The Staying, where users can search for hotels, view details such as room availability and pricing, and make reservations online. </p>
                                        <p>To make a reservation, select the hotel and room type you want, enter your details, and proceed to the payment page to confirm your booking.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div> */}
            </div>


            <div className="footerBottom">
                <div className="container">
                    <div className="footerBtext">
                        <p>Copyright © 2024 The Staying . All Rights Reserved.</p>
                        <p><span className="content-label">Design & developed by - <a href="https://mettletech.co/" target="_blank">Mettle Tech</a></span></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default (Footer);