import React from 'react';
import { get, post, put, del, patch } from '../../../../../Utility/Http';
import { personalDetailsUpdateApi, userPersonalInfoGetApi, userBasicInfoUpdatePatch, currentUser ,verifySoftwareTokenPut,mfaAssignPut } from '../Model/PersonalModel';

export const userPersonalInfoGet = (id) => {
    return get(`${process.env.EXTENDED_URL}user_personal_info/${id}`).then((response) => {
        return userPersonalInfoGetApi(response)
    });
};

export const personalDetailsUpdate = (data, type, id,) => {
    return patch(`${process.env.EXTENDED_URL}user_personal_info/${id}`, data).then((response) => {
        return personalDetailsUpdateApi(response)
    });
};

export const userBasicInfoUpdate = (data, id, type) => {
    return patch(`${process.env.EXTENDED_URL}admin/${id}`, data).then((response) => {
        return userBasicInfoUpdatePatch(response)
    });

};

export const getCurrentUser = () => {
    return get(`${process.env.EXTENDED_URL}currentuser`).then((response) => {
        return currentUser(response)
    })
};

export const verifySoftwareToken = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/verify_software_token`, data);
    return verifySoftwareTokenPut(response);
};
export const mfaAssign = async (data) => {
    const response = await put(`${process.env.EXTENDED_URL}users/usr_mfa_assign`, data);
    return mfaAssignPut(response);
};