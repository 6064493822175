import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import Auth from "../../../Layout/Auth";
import AboutUs from "../Pages/AboutUs";
import TermsConditions from "../Pages/TermsConditions";
import Faq from "../Pages/Faq";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Sitemap from "../Pages/Sitemap";
function FooterListRoute() {
    const history = useHistory();
    //console.log("payment route history----",history);
    return (

        <Switch>
            <Route
                exact
                path="/:lng/about_us"
                render={() => (
                    <Auth history={history}>
                        <Suspense>
                            <AboutUs/>
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/terms_Conditions"
                render={() => (
                    <Auth history={history}>
                        <Suspense>
                            <TermsConditions/>
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/faq"
                render={() => (
                    <Auth history={history}>
                        <Suspense>
                            <Faq/>
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/privacy_policy"
                render={() => (
                    <Auth history={history}>
                        <Suspense>
                            <PrivacyPolicy/>
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/sitemap"
                render={() => (
                    <Auth history={history}>
                        <Suspense>
                            <Sitemap/>
                        </Suspense>

                    </Auth>
                )}
            />

        </Switch>
    );
}


export default FooterListRoute;
