import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import CustomInput from '../../../../Utility/Components/CustomInput';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import PhoneNumberWithCountryCode from '../../../../Utility/Components/PhoneNumberWithCountryCode';


function BookingUserDetails(props) {

    return (
        <div className="bookingUserDetailsinner">
            <div className='row'>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="First name"
                            errorLabel={props.errorFormData.firstName}
                            name="first_name"
                            type="text"
                            value={props.formData.firstName}
                            labelPresent={true}
                            onChange={(e) => props.handelChange(e, 'first_name')}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="Last name"
                            errorLabel={props.errorFormData.lastName}
                            name="last_name"
                            type="text"
                            value={props.formData.lastName}
                            labelPresent={true}
                            onChange={(e) => props.handelChange(e, 'last_name')}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="Email"
                            errorLabel={props.errorFormData.email}
                            name="email"
                            type="text"
                            value={props.formData.email}
                            labelPresent={true}
                            onChange={(e) => props.handelChange(e, 'email')}
                        />
                    </ErrorBoundary>
                </div>
                <div className="col-md-6">
                    <ErrorBoundary title="CustomInput Error">
                        <PhoneNumberWithCountryCode
                            title="Contact"
                            country={props.selectedCountryCode}
                            value={props.formData.contact}
                            onChange={(e) => props.handelChange(e, 'contact')}
                            error={props.errorFormData.contact}
                            labelShow={true}
                            requirStart={true}
                            disableDropdown={true}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="Address 1"
                            errorLabel={props.errorFormData.address1}
                            name="address1"
                            type="text"
                            value={props.formData.address1}
                            labelPresent={true}
                            requirStart={true}
                            onChange={(e) => props.handelChange(e, 'address1')}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="Description"
                            errorLabel={props.errorFormData.description}
                            name="description"
                            type="textarea"
                            value={props.formData.description}
                            labelPresent={true}
                            requiredStar={false}
                            onChange={(e) => props.handelChange(e, 'description')}
                        />
                    </ErrorBoundary>
                </div>
                
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                           parentClassName="input_field_inner"
                           labelName="City"
                           errorLabel={props.errorFormData.city}
                           name="city"
                           type="text"
                           value={props.formData.city}
                           labelPresent={true}
                           onChange={(e) => props.handelChange(e, 'city')}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName="State"
                            errorLabel={props.errorFormData.state}
                            name="state"
                            type="text"
                            value={props.formData.state}
                            labelPresent={true}
                            onChange={(e) => props.handelChange(e, 'state')}
                        />
                    </ErrorBoundary>
                </div>
                <div className='col-md-6'>
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                             parentClassName="input_field_inner"
                             labelName="Pin Code"
                             errorLabel={props.errorFormData.zip}
                             name="zip"
                             type="text"
                             value={props.formData.zip}
                             labelPresent={true}
                             onChange={(e) => props.handelChange(e, 'zip')}
                        />
                    </ErrorBoundary>
                </div>
                <div className="col-md-6">
                    <ErrorBoundary title="Dropdown Error">
                        <div className="dropdowninnerbox">
                            <label>Country <span>*</span></label>
                            <AutosuggestComponent
                                handleOnChange={(e) => props.handelChange(e, 'country')}
                                options={props.CountryList}
                                selectedValue={props.selectedCountry}
                                name=''
                                isMulti={false}
                                placeholder=""
                                isDisabled={false}
                                isSearchable={true}
                                //menuPosition="fixed"
                                //isClearable={props.selectedCountry && Object.keys(props.selectedCountry).length > 0 ? true : false}
                            />
                            <div className="col-md-12 errorClass error_div">{props.errorFormData.country}</div>
                        </div>
                    </ErrorBoundary>
                </div>
                <div className='col-sm-12 text-center topspace'>
                <button type="button" className="modbtn" onClick={(e) => props.onSubmitBookingUserDetails()}>Next</button>
                </div>
            </div>

        </div>
    );
}

export default BookingUserDetails;
