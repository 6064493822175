import React, { Component } from 'react';
import Default from "../../../Layout/Default";
import { Routes, Route, Switch, useHistory } from "react-router-dom";
//AppLandingPage
import AppLandingPage from '../Pages/AppLandingPage';
//Login Module
import LoginPage from '../Pages/LoginPage';
import ForgotPassword from '../Pages/ForgotPassword';
import RegisterPage from '../Pages/RegisterPage';
import logger from '../../../logger';
import Auth from "../../../Layout/Auth";

function LoginRoute() {

    //logger.log("logout entry")
    const history = useHistory();
    return (

        <Switch>
            {/* login route */}
            <Route
                exact
                path="/:lng/login/:type?"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <LoginPage history={history} />
                    </Auth>
                )}
            />

            {/* foget password route */}
            <Route
                exact
                path="/:lng/forgot_password/:type?"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <ForgotPassword history={history} />
                    </Auth>
                )}
            />
            
            {/* register route */}
            <Route
                exact
                path="/:lng/register/:type?"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <RegisterPage history={history} />
                    </Auth>
                )}
            />

        </Switch>
    );

}


export default LoginRoute;
