import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import Auth from "../../../Layout/Auth";
import ManageAccount from "../Pages/ManageAccount";
import ChangePassword from "../Pages/ChangePassword";


function ProfileRoute({ isLoggedIn, ...routeProps }) {
    const history = useHistory();
    //console.log("payment route history----",history);
    return (

        <Switch>
            <Route
                exact
                path="/:lng/manage_account"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <Suspense>
                            <ManageAccount isLoggedIn={isLoggedIn} history={routeProps.history} />
                        </Suspense>

                    </Auth>
                )}
            />

            <Route
                exact
                path="/:lng/change_password"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <Suspense>
                            <ChangePassword isLoggedIn={isLoggedIn} history={routeProps.history} />
                        </Suspense>

                    </Auth>
                )}
            />

        </Switch>
    );
}


export default ProfileRoute;
