import React, { useState, useEffect, useRef } from 'react'
import { Rating } from 'react-simple-star-rating';
import CustomInput from '../../../../Utility/Components/CustomInput';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal';
import EmojiContent from '../../../../Utility/Components/EmojiContent';
import Utility from '../../../../Utility/Utility';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reviewPost, reviewImagePost } from '../Controller/BookingController';


function ReviewRating(props) {
    //console.log('selectedGridObj---------->', props.selectedGridObj)
    const [rating, setRating] = useState(0);
    const [src, setSrc] = useState(null);
    const [formData, setFormData] = useState({ 'comments': "", 'rating_image': [{ 'image_url': "" }] });
    const [emojiShowFlag, setEmojiShowFlag] = useState(false);

    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleChange = (e, type) => {
        if (type == 'comments') {
            if (e.target.value != "") {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ['comments']: e.target.value
                }));
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    ['comments']: ''
                }));
            }
        }
    }

    const emojiShowContentFn = () => {
        setEmojiShowFlag(true)
    }

    const closeEmojiContentFn = () => {
        setEmojiShowFlag(false)
    }

    const emojiSelect = (emoji) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            ['comments']: prevFormData.comments + emoji.native
        }));
    }

    const addInputProfileImageChanged = (event, index) => {
        let tempformData = { ...formData };
        const fileInput = event.target;
        const storedFiles = event.target.files[0] // Store the files before clearing the input
        const storedAllFiles = event.target.files

        let targetFileSplit = storedFiles.name.split('.');
        let lastElement = targetFileSplit.pop();
        let user_profile_image = {
            "file_name": "",
            "file_obj": ""
        };
        // if (lastElement == 'JPEG' || lastElement == 'jpeg' || lastElement == 'jpg' || lastElement == 'JPG' || lastElement == 'png' || lastElement == 'PNG' || lastElement == '') {
        //     const fsize = storedFiles.size;
        //     const file = Math.round((fsize / 1024));
        //     if (file >= 300) {
        //         Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
        //     } else {
        //         if (storedAllFiles && storedAllFiles.length > 0) {
        //             const reader = new FileReader();
        //             reader.addEventListener('load', () =>
        //                 setSrc(reader.result)
        //             );
        //             reader.readAsDataURL(storedFiles);
        //             reader.onload = () => {
        //                 const file = storedFiles;
        //                 const imageUrl = reader.result;
        //                 user_profile_image["file_name"] = storedFiles.name
        //                 user_profile_image["file_obj"] = reader.result;
        //                 user_profile_image["file"] = file;
        //                 tempformData['rating_image'][index]['image_url'] = user_profile_image
        //                 setFormData(tempformData)
        //                 fileInput.value = '';
        //             }
        //         }
        //     }

        // } else {
        //     tempformData['rating_image'][index]['image_url'] = "";
        //     setFormData(tempformData)
        //     Utility.toastNotifications("File format is not supported, it should be in jpg, jpeg or png format", "Warning", "warning");
        //     fileInput.value = '';
        // }
        const allowedTypes = Utility.acceptFileType();
        Utility.checkFileType(storedFiles, allowedTypes)
            .then(results => {
                if (results.isValid) {
                    const fsize = event.target.files[0].size;
                    const fileSize = Math.round((fsize / 1024));
                    if (fileSize >= 300) {
                        Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
                    } else {
                        if (storedAllFiles && storedAllFiles.length > 0) {
                            const reader = new FileReader();
                            reader.addEventListener('load', () =>
                                setSrc(reader.result)
                            );
                            reader.readAsDataURL(storedFiles);
                            reader.onload = () => {
                                const file = storedFiles;
                                const imageUrl = reader.result;
                                user_profile_image["file_name"] = storedFiles.name
                                user_profile_image["file_obj"] = reader.result;
                                user_profile_image["file"] = file;
                                tempformData['rating_image'][index]['image_url'] = user_profile_image
                                setFormData(tempformData)
                                fileInput.value = '';
                            }
                        }
                    }
                } else {
                    tempformData['rating_image'][index]['image_url'] = "";
                    setFormData(tempformData)
                    Utility.toastNotifications("File format is not supported, it should be in jpg, jpeg or png format", "Warning", "warning");
                    fileInput.value = '';
                }
            })
            .catch(error => {
                console.error("Error:", error);

            });

    }
    const addImagesModalFn = () => {
        if (formData.rating_image.length < 5) {
            setFormData(prevFormData => ({
                ...prevFormData,
                rating_image: [...prevFormData.rating_image, { 'image_url': "" }]
            }));
        } else {
            Utility.toastNotifications("Can't Add more than 5 images", "Warning", "warning");
        }
    }
    const removeUplodedImage = (index) => {
        setFormData(prevFormData => {
            const updatedImages = [...prevFormData.rating_image];
            updatedImages[index]['image_url'] = ""
            return {
                ...prevFormData,
                rating_image: updatedImages
            };
        });
    };

    useEffect(() => {
        //console.log("formData-------->", formData)
    }, [formData])

    const formatsubmitReviewDataFn = () => {
        let dataSet = [];
        let dataSetObj = {};

        dataSetObj['user_id'] = props.selectedGridObj?.customer_id
        dataSetObj['hotel_id'] = props.selectedGridObj?.hotel_id
        dataSetObj['booking_id'] = props.selectedGridObj?.id;
        dataSetObj['branch_id'] = props.selectedGridObj?.branch_id;
        dataSetObj['overall_rating'] = rating.toString();
        dataSetObj['comments'] = formData.comments;

        dataSet.push(dataSetObj)
        return dataSet;
    }

    const submitReviewFn = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let dataset = formatsubmitReviewDataFn();
        loaderStateTrue();
        reviewPost(dataset).then((response) => {
            //console.log("response------------>", response)
            if (response.length > 0) {
                if (response[0].success) {
                    let imageId = response[0].hasOwnProperty('data') && response[0].data && response[0].data.hasOwnProperty('id') && response[0].data.id ? response[0].data.id : "";
                    let imageUploadFlag = false;
                    formData.rating_image.forEach((imgObj, index) => {
                        if (imgObj.image_url != "" && imgObj.image_url.file_obj) {
                            imageUploadFlag = true;
                        }
                    })
                    if (imageUploadFlag) {
                        reviewAddImagePostCall(imageId);
                    }
                    loaderStateFalse();
                    if (!imageUploadFlag) {
                        props.reviewPostSuccessFn();
                    }
                    Utility.toastNotifications(response[0].message, "Success", "success");
                } else {
                    loaderStateFalse();
                }
            }

        }).catch((error) => {
            loaderStateFalse();
        });
    }

    const reviewAddImagePostCall = (id) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        const formDataImage = new FormData();


        formDataImage.append('formData', JSON.stringify([{ 'customer_rating_id': id }]));
        formData.rating_image.forEach((imgObj, index) => {
            if (imgObj.image_url != "" && imgObj.image_url.file_obj) {
                formDataImage.append('file', imgObj.image_url.file);
            }
        });

        loaderStateTrue();
        reviewImagePost(formDataImage).then((response) => {
            //console.log("response---img--------->", response)
            if (response.success) {
                props.reviewPostSuccessFn();
                // Utility.toastNotifications(response.message, "Success", "success");
            }
            loaderStateFalse();
        }).catch((error) => {
            loaderStateFalse();
        });
    }

    useEffect(() => {
        if (props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length > 0) {
            setRatingReviewModalEditData();
        }
    }, [props.selectedGridObj])

    const imagePickFunction = (data) => {
        let imgArray = []
        if (data.length > 0) {
            data.map((e, id) => {
                if (e.hasOwnProperty('img_url') && e.img_url && JSON.parse(e.img_url).hasOwnProperty('img_url') && JSON.parse(e.img_url).img_url) {
                    imgArray.push(JSON.parse(e.img_url).img_url)
                }
            })
        }

        return imgArray;
    }

    const setRatingReviewModalEditData = () => {
        let rating = props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length > 0 && props.selectedGridObj.BookingMasterRatingRelation[0].hasOwnProperty('overall_rating') && props.selectedGridObj.BookingMasterRatingRelation[0].overall_rating ? Number(props.selectedGridObj.BookingMasterRatingRelation[0].overall_rating) : 0;
        let comments = props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length > 0 && props.selectedGridObj.BookingMasterRatingRelation[0].hasOwnProperty('comments') && props.selectedGridObj.BookingMasterRatingRelation[0].comments ? props.selectedGridObj.BookingMasterRatingRelation[0].comments : "";

        setRating(rating);
        setFormData(prevFormData => ({
            ...prevFormData,
            ['comments']: comments
        }));

        let imageArray = props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length > 0 && props.selectedGridObj.BookingMasterRatingRelation[0].hasOwnProperty('CustomerRatingImageRelation') && props.selectedGridObj.BookingMasterRatingRelation[0].CustomerRatingImageRelation && props.selectedGridObj.BookingMasterRatingRelation[0].CustomerRatingImageRelation.length > 0 ? props.selectedGridObj.BookingMasterRatingRelation[0].CustomerRatingImageRelation : [{ 'image_url': { 'file_obj': "" } }];
        let uploadedImageArray = imagePickFunction(imageArray);
        if (uploadedImageArray.length > 0) {
            let imgArr = [];
            uploadedImageArray.map((e, id) => {
                let imgObj = { 'image_url': { 'file_obj': e } }
                imgArr.push(imgObj);
            })
            setFormData(prevFormData => ({
                ...prevFormData,
                ['rating_image']: imgArr
            }));
        }

    }

    return (
        <>
            <div className={props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 ? "innerBodySection" : "innerBodySection disableReview"}>
                {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length > 0 &&  props.selectedGridObj.BookingMasterRatingRelation[0].hasOwnProperty('status') &&  props.selectedGridObj.BookingMasterRatingRelation[0].status && Number(props.selectedGridObj.BookingMasterRatingRelation[0].status) == 2 ?
                    <div className='rejectedBox'><button>Rejected</button></div>:null}
                <div className="ratingBox">
                    <p className="currentRatting"><span>Rating:</span> {rating}</p>
                    {/* {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 ? */}
                    <div className="ratingContent">
                        <Rating
                            onClick={handleRating}
                            ratingValue={rating}
                            size={18}
                            transition
                            fillColor="orange"
                            emptyColor="#a2a2a2"
                            iconsCount={5}
                            initialValue={props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 ? 0 : rating}
                        />
                    </div>
                </div>
                <div className="customerCommentsBox">
                    <ErrorBoundary title="CustomInput Error">
                        <CustomInput
                            parentClassName="input_field_inner"
                            labelName={`Comments`}
                            errorLabel={''}
                            name="comments"
                            type="textarea"
                            value={formData.comments}
                            labelPresent={true}
                            onChange={(e) => handleChange(e, "comments")}
                            requiredStar={false}
                        //placeholder=""
                        //readOnly={true}
                        />
                        <div className='emoji_box' onClick={() => emojiShowContentFn()}>
                            <img src={require('../../../../Utility/Public/images/happy.png')} />
                        </div>
                    </ErrorBoundary>
                </div>
                <ErrorBoundary title="ModalGlobal Error">
                    <ModalGlobal
                        show={emojiShowFlag}
                        onHide={closeEmojiContentFn}
                        className="modalcustomize emoji_modal_content"
                        headerShow={true}
                        closeButton={true}
                        footer={false}
                        title="Choose emoji"
                        bodyClassName="jobinfoModalContent"
                        headerclassName="close_btn_icon"
                        onCancel={closeEmojiContentFn}
                        body={
                            <>
                                <ErrorBoundary title="EmojiContent Error">
                                    <EmojiContent
                                        showEmojiContent={emojiShowFlag}
                                        // text_template_description={formData.comments}
                                        emojiSelect={emojiSelect}
                                    />
                                </ErrorBoundary>
                            </>
                        }
                    />
                </ErrorBoundary>
                <div className='imageRow'>
                    {
                        formData.rating_image.length > 0 &&
                        formData.rating_image.map((item, index) => (
                            <>
                                {!props.isEdit ? <div className='imageRowInner' key={index}>
                                    <div className='withChkBox'>
                                        <div className='imgDetailBox'>
                                            <div className="imageBox">
                                                <span className={item.image_url !== "" ? "filetext filetextImg" : "filetext"}>
                                                    <img className='defaultImg' src={item.image_url !== "" ? item.image_url.file_obj : require('../../../../Utility/Public/images/cloud-computing.png')} alt="Uploaded" />
                                                    {item.image_url == "" &&
                                                        <p className="uploadText">Upload Image</p>
                                                    }
                                                    <span className="plusicon">
                                                        <input type="file" accept={Utility.acceptFileType()} onChange={(e) => { addInputProfileImageChanged(e, index) }} />
                                                    </span>
                                                </span>
                                            </div>
                                            {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 &&
                                                <>
                                                    {item.image_url !== "" && (
                                                        <div className="deleteImg">
                                                            <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                <button className='deleteIcon' onClick={() => removeUplodedImage(index)}>✖</button>
                                                            </OverlayTrigger>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div> :
                                    <div className='imageRowInner' key={index}>
                                        {item.image_url !== "" && <div className='withChkBox'>
                                            <div className='imgDetailBox'>
                                                <div className="imageBox">
                                                    <span className={item.image_url !== "" ? "filetext filetextImg" : "filetext"}>
                                                        <img className='defaultImg' src={item.image_url !== "" ? item.image_url.file_obj : require('../../../../Utility/Public/images/cloud-computing.png')} alt="Uploaded" />
                                                        {item.image_url == "" &&
                                                            <p className="uploadText">Upload Image</p>
                                                        }
                                                        <span className="plusicon">
                                                            <input type="file" onChange={(e) => { addInputProfileImageChanged(e, index) }} />
                                                        </span>
                                                    </span>
                                                </div>
                                                {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 &&
                                                    <>
                                                        {item.image_url !== "" && (
                                                            <div className="deleteImg">
                                                                <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                                                                    <button className='deleteIcon' onClick={() => removeUplodedImage(index)}>✖</button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )}
                                                    </>
                                                }
                                            </div>
                                        </div>}
                                    </div>
                                }
                            </>
                        ))
                    }
                    {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 &&
                        <div className="upload-btn-wrapper">
                            <button className="btn addMoreImg" onClick={() => addImagesModalFn()}><span className="plus">+</span><span className="text">Add Images</span></button>
                        </div>
                    }
                </div>
            </div>
            {props.selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && props.selectedGridObj.BookingMasterRatingRelation && props.selectedGridObj.BookingMasterRatingRelation.length == 0 &&
                <div className="col-md-12 submitSuccessBtn">
                    <button className="btn submitBtn" onClick={() => submitReviewFn()}>Submit</button>
                </div>}
        </>
    );
}

ReviewRating.defaultProps = {
    isEdit: false
}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(ReviewRating));