class AllActionTypes {
    //for loader
    static LOADER_STATE_TRUE = 'LOADER_STATE_TRUE';
    static LOADER_STATE_FALSE = 'LOADER_STATE_FALSE';
    static HANDLE_LEFT = 'HANDLE_LEFT';
    static ACTIVE_LINK = 'ACTIVE_LINK';
    static CLEAR_DATA = 'CLEAR_DATA';
    static ROLE_PERMISSION = 'ROLE_PERMISSION';
    static CONNECT_DISCONNECT_TO_WEBSOCKET = 'CONNECT_DISCONNECT_TO_WEBSOCKET';
    static LOCK_FLAG = 'LOCK_FLAG';
    static PLANNING_EDITABLE_BEFORE_CURRENT_DAY = 'PLANNING_EDITABLE_BEFORE_CURRENT_DAY';
    static SELECT_BRANCH_DATA = 'SELECT_BRANCH_DATA';
    static USER_SEARCH_DATA = 'USER_SEARCH_DATA';
    static USER_SEARCH_DATA_ERROR = 'USER_SEARCH_DATA_ERROR';
    static PRICE_DISTRIBUTION_REQUEST_DATA = 'PRICE_DISTRIBUTION_REQUEST_DATA';
    static PRICE_DISTRIBUTION_RESPONSE_DATA = 'PRICE_DISTRIBUTION_RESPONSE_DATA';
    static TRENDING_DESTINATION = 'TRENDING_DESTINATION';

}
export default AllActionTypes;