import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Assets/css/footerListdoc.scss';
import '../Assets/css/footerListresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu';

function AboutUs(props) {


    const history = useHistory();

    useEffect(() => {


    }, []);

    const homeMenuClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }



    return (

        <div className="aboutUsPage">
            <div className='footerBoxCover'>
                <div className='container'>
                    <div className="filterBox filterBoxMBottom">
                        <div className="leftSectionFilter">
                            <h2 className="pageTitle">About Us</h2>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    aboutDisplay={true}
                    homeMenuClick={homeMenuClick}
                    activeAbout={true}
                />

                <div className='aboutPageDetails'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <h4>About The Staying</h4>
                                <p>A property booking platform like The Staying, where users can search for properties, view details such as room availability and pricing, and make reservations online. Here are some key features and components typically found on a Online property booking management platform:</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Search Functionality</h5>
                                    <p className="card-text">Users can search for properties based on criteria such as location, check-in/check-out dates, number of guests, and room preferences.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Property Listings</h5>
                                    <p className="card-text">A list of properties matching the search criteria, with brief descriptions, pricing, and ratings displayed for each property.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Property Details</h5>
                                    <p className="card-text">Detailed information about each property, including room types, amenities, photos, and reviews.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Booking Engine</h5>
                                    <p className="card-text">A system for users to select rooms, enter guest information, and make reservations.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">User Accounts</h5>
                                    <p className="card-text">User registration and login functionality, allowing users to manage their bookings, view past reservations, and save preferences.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Responsive Design</h5>
                                    <p className="card-text"> A mobile-responsive design that ensures the site is accessible and user-friendly on smartphones and tablets.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Reviews and Ratings</h5>
                                    <p className="card-text">User-generated reviews and ratings for properties, helping users make informed decisions.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Payment Processing</h5>
                                    <p className="card-text">Secure payment options integration for users to pay for their bookings online.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}





const mapStateToProps = (globalState) => {
    return {
        // userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })
    (withNamespaces()(AboutUs));