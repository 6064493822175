import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Utility from '../../../../Utility/Utility'
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/bookingdoc.scss';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary'
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker'
import { DebounceInput } from 'react-debounce-input';
import { creditPointListing } from '../Controller/CreditNoteController';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import { useParams } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BreadcrumbMenu from '../../../../Utility/Components/BreadcrumbMenu'


function CreditNotes(props) {
    const { customer_type } = useParams();
    const [selectedStatus, setSelectedStatus] = useState({ label: "Available", value: "2" });
    const [statusList, setStatusList] = useState([
        { label: "Available", value: "2" },
        { label: "Redeemed", value: "1" },
        { label: "Expired", value: "0" }
    ]);
    const [searchCreditNoteData, setSearchCreditNoteData] = useState("");
    const [creditNoteOffset, setCreditNoteOffset] = useState(0);
    const [creditNoteLimit, setCreditNoteLimit] = useState(12);
    const [creditNoteData, setCreditNoteData] = useState([])
    const [searchStartDate, setSearchStartDate] = useState(null);
    const [searchEndDate, setSearchEndDate] = useState(null);
    const [hasMoreCreditNoteList, setHasMoreCreditNoteList] = useState(true)
    const [statusFilterFlag, setStatusFilterFlag] = useState(false);
    const [isGuestFlag, setIsGuestFlag] = useState(false);
    const [creditPointDataStatus, setCreditPointDataStatus] = useState(false);
    const [dateRangeFilterFlag, setDateRangeFilterFlag] = useState(false);


    useEffect(() => {
        if (Object.keys(props.userCredentials).length === 0) {
            props.history.push(`/${localStorage.getItem('i18nextLng')}/login`);
        }
        if (customer_type == 'guest') {
            setIsGuestFlag(true);
        }
        breadcrumbUrlFn();
    }, []);

    useEffect(() => {
        if (selectedStatus != "" && selectedStatus.hasOwnProperty('value') && statusFilterFlag && !isGuestFlag) {
            creditNoteList();
        }
        if (selectedStatus == "" && statusFilterFlag && !isGuestFlag) {
            creditNoteList();
        }
    }, [selectedStatus])

    useEffect(() => {
        if (!isGuestFlag) {
            creditNoteList();
            setCreditNoteOffset(0)
            setCreditNoteLimit(12)
            setCreditNoteData([])
        }
    }, [searchCreditNoteData])

    const breadcrumbUrlFn = () => {
        let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
        let newArray = breadcrumb.slice(0, breadcrumb.indexOf('credit_note') + 1);
        if (!breadcrumb.includes('credit_note')) {
            breadcrumb.push('credit_note')
            localStorage.setItem('pageArr', JSON.stringify(breadcrumb));
        } else {
            localStorage.setItem('pageArr', JSON.stringify(newArray));
        }
    }

    function creditNoteStatusColor(status) {
        let returnStringClass = "";
        if (status == "2") {
            returnStringClass = <div className="greenFront">Available</div>;
        } else if (status == "1") {
            returnStringClass = <div className="yellowFront">Redeemed</div>;
        } else if (status == "0") {
            returnStringClass = <div className="redFront">Expired</div>;
        }
        return returnStringClass
    }

    const handleCopyClick = (e, referenceNo) => {
        const textArea = document.createElement('textarea');
        let copyData = referenceNo;
        textArea.value = copyData;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        Utility.toastNotifications("Copied Successfully!", "Success", "success")
    };

    const creditNoteDataUi = () => {
        let labelArray = []
        if (creditNoteData.length > 0) {
            creditNoteData.map((creditNoteObj, idx) => {
                labelArray.push(

                    <tr>
                        <td>
                            <div className='creditPointRefBox'>
                                {(creditNoteObj.hasOwnProperty('credit_point_ref_id')) ? <span className='creditPointRefId'>{creditNoteObj.credit_point_ref_id}</span> : ""}
                                <button onClick={(e) => { handleCopyClick(e, creditNoteObj.credit_point_ref_id) }} className="creditPointCopyButton" type="button">
                                    <OverlayTrigger overlay={<Tooltip>{"Copy"}</Tooltip>}>
                                        <i className="fa fa-clone" aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </button>
                            </div>
                        </td>
                        <td>
                            {(creditNoteObj.createdAt != "" && creditNoteObj.createdAt != null) ? moment(creditNoteObj.createdAt.toString()).format('DD-MM-YYYY') : ""}
                        </td>
                        <td>
                            {(creditNoteObj.vaild_through != "" && creditNoteObj.vaild_through != null) ? moment(creditNoteObj.vaild_through.toString()).format('DD-MM-YYYY') : ""}
                        </td>
                        <td>
                            {(creditNoteObj.currency != "" && creditNoteObj.currency != null) ? Utility.getCurrencySymbol(creditNoteObj.currency) : ""} {creditNoteObj.credit_points}
                        </td>
                        <td>
                            {(creditNoteObj.hasOwnProperty('redeemed_date')) ? ((creditNoteObj.redeemed_date != "" && creditNoteObj.redeemed_date != null) ? creditNoteObj.redeemed_date.toString() : "-") : "-"}
                        </td>
                        <td>
                            {creditNoteStatusColor(creditNoteObj.status)}
                        </td>
                    </tr>

                )
            })
        } else if (creditNoteData.length == 0) {
            labelArray = []
            if (creditPointDataStatus == true) {
                labelArray.push(<tr>
                    <td colSpan={6} className='borderNone'>
                        <div className='noDataFound'>
                            <h3 className='title'>No Data Found.</h3>
                        </div>
                    </td>
                </tr>)
            } else {
                labelArray = []
                if (!creditPointDataStatus && customer_type == 'guest') {
                    labelArray.push(<WithoutLoginMessageShow
                        isMessageShow={true}
                        message="Your login credentials have been sent to your email. Please log in first to access and review your booking details"
                        isRedirectPageButtonShow={true}
                        redirectButtonLabel="Go To Login Page"
                        redirectButtonUrl="/login"
                        redirectFromUrl="/credit_note"
                    />)
                }
            }

        }

        return labelArray
    }

    const handleChangeUserFilter = (event) => {
        if (event) {
            if (event.value != selectedStatus.value) {
                setSelectedStatus(event);
                setCreditNoteOffset(0)
                setCreditNoteLimit(10)
                setCreditNoteData([])
                setStatusFilterFlag(true)
            }
        } else {
            setSelectedStatus("")
        }

    }

    const handleSearchValue = (e) => {
        if (e.target.value != "") {
            setSearchCreditNoteData(e.target.value)
        } else {
            setSearchCreditNoteData("")
        }
        setCreditNoteOffset(0)
        setCreditNoteLimit(10)
        setCreditNoteData([])
    }

    const clearSearchValue = () => {
        setSearchCreditNoteData("")
        creditNoteList();
        setCreditNoteOffset(0)
        setCreditNoteLimit(12)
        setCreditNoteData([])
    }

    const onChangeDateRange = (dates) => {
        const [start, end] = dates;
        setSearchStartDate(start);
        setSearchEndDate(end);
        //setSearchBookingData("")
        setCreditNoteOffset(0)
        setCreditNoteLimit(12)
        setCreditNoteData([])
        setDateRangeFilterFlag(true)
    }

    useEffect(() => {
        if (searchStartDate && searchStartDate != "" && searchEndDate && searchEndDate != "" && dateRangeFilterFlag && !isGuestFlag) {
            creditNoteList();
        }
        else if (searchStartDate == null && searchEndDate == null && dateRangeFilterFlag && !isGuestFlag) {
            creditNoteList();
        }
    }, [searchEndDate, dateRangeFilterFlag])

    const getNextData = () => {
        creditNoteList()
    }

    const creditNoteList = () => {
        if (customer_type != 'guest') {
            const { loaderStateTrue, loaderStateFalse } = props;
            let promise = new Promise((resolve, reject) => {
                setCreditPointDataStatus(false)
                loaderStateTrue();
                let filters = {};
                let filter_op = {};

                let globalQueryParamshash = {};

                globalQueryParamshash["limit"] = creditNoteLimit.toString();
                globalQueryParamshash["offset"] = creditNoteOffset.toString();

                globalQueryParamshash['status'] = 2;

                if (selectedStatus != "") {
                    globalQueryParamshash['status'] = selectedStatus.value;
                    //sortModel.push({ "sort": "asc", "colId": "status" })
                }

                if (searchCreditNoteData != "") {
                    globalQueryParamshash['search_attr'] = searchCreditNoteData
                    filter_op = Object.assign(filter_op, { "search_attr": "substring" });
                }

                if (searchStartDate && searchStartDate != "" && searchEndDate && searchEndDate != "") {
                    let from_date = moment(new Date(searchStartDate)).format('YYYY-MM-DD')
                    let to_date = moment(new Date(searchEndDate)).format('YYYY-MM-DD')
                    globalQueryParamshash['selected_date'] = [from_date, to_date];

                    filter_op = Object.assign(filter_op, { "selected_date": "between" })
                }

                globalQueryParamshash['sorting'] = [{ "sort": "asc", "colId": "status" }];

                if (Object.keys(filter_op).length > 0) {
                    filters["filter_op"] = JSON.stringify(filter_op);
                }
                filters['filters'] = JSON.stringify(globalQueryParamshash)

                creditPointListing(filters).then((response) => {
                    if (response.success) {
                        resolve(response)
                    } else {
                        setCreditPointDataStatus(true)
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    console.error("************error*************", error)
                    if (error) {

                    }
                    loaderStateFalse();
                    setCreditPointDataStatus(true)
                    if (error.message == "Network Error") {
                    }
                });

            })
            promise.then((res) => {
                if (res.total > 0 || res.data.length > 0) {
                    setHasMoreCreditNoteList(true)
                    if (creditNoteOffset <= res.total) {
                        let tempOffset = creditNoteOffset + creditNoteLimit
                        setCreditNoteOffset(tempOffset)
                        let tempAllBookingRoomData = creditNoteData.slice()
                        const finalBookingRoomData = tempAllBookingRoomData.concat(res.data);

                        setTimeout(() => {
                            setCreditNoteData(finalBookingRoomData)
                            setCreditPointDataStatus(true)
                        }, 500)
                    } else {
                        setHasMoreCreditNoteList(false)
                        setTimeout(() => {
                            setCreditPointDataStatus(true)
                        }, 500)
                    }
                    if (res.data.length == 0) {
                        setHasMoreCreditNoteList(false)
                        setTimeout(() => {
                            setCreditPointDataStatus(true)
                        }, 500)
                    }
                } else {
                    setHasMoreCreditNoteList(false)
                    setTimeout(() => {
                        setCreditPointDataStatus(true)
                    }, 500)
                }


            })
        }
    }

    const homeMenuClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }
    const hotelListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/search`);
    }
    const hotelDetailsClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
    }
    const bookingListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }
    const creditNoteClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/credit_note`);
    }

    return (
        <>
            <div className="booking-history-page">
                <div className='filterBoxCover'>
                    <div className='container'>
                        <div className='filterBox bookingHistory filterBoxMBottom'>
                            <div className='leftSectionFilter'>
                                <h2 className='pageTitle'>Credit Notes</h2>
                            </div>
                            <div className='rightSectionFilter'>
                                <div className="dropdownbox statusdropcontainer">
                                    <AutosuggestComponent
                                        handleOnChange={(e) => { handleChangeUserFilter(e) }}
                                        options={statusList}
                                        selectedValue={selectedStatus}
                                        name=''
                                        isMulti={false}
                                        placeholder="Status"
                                        isDisabled={false}
                                        isClearable={false}
                                        closeButton={false}
                                        menuHeader="Status"
                                        isSearchable={true}
                                    />
                                </div>
                                <div className='search-input'>
                                    <div className="pageinnersearch searchBox">
                                        <button type="button" className="btn btn-link search_btn_addon">
                                            <img className="searchicon" src={require('../../../../Utility/Public/images/searchicon1.png')} />
                                        </button>
                                        <DebounceInput
                                            minLength={2}
                                            debounceTimeout={500}
                                            onChange={(e) => handleSearchValue(e)}
                                            placeholder="Search Reference No."
                                            value={searchCreditNoteData}
                                        />
                                        {searchCreditNoteData != "" ?
                                            < button type="button" className="btn btn-link dropclosebtn"
                                                onClick={clearSearchValue}
                                            >
                                                {/* <img src={require('../../../../Utility/Public/images/dropcloseicon.png')} className="searchClearIcon" /> */}
                                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg searchClearIcon"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                            </button>
                                            : null}
                                    </div>
                                </div>
                                <div className='search-range'>
                                    <ErrorBoundary title="CommonDateTimePicker Error">
                                        <img className="ico ico-date" src={require('../../../../Utility/Public/images/calendar-outline1.png')} />
                                        <CommonDateTimePicker
                                            selected={searchStartDate}
                                            onChange={(e) => onChangeDateRange(e)}
                                            showTimeSelect={false}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Issued Date / Valid Till"
                                            startDate={searchStartDate}
                                            endDate={searchEndDate}
                                            minDate=""
                                            //minTime={props.startDateminTime}
                                            // maxTime={props.startDatemaxTime}
                                            dateRange={true}
                                            selectsRange={true}
                                            isClearable={true}
                                        />
                                        {/* <div className="col-md-12 errorClass error_div">{props.roomBookErrorFormData.start_date}</div> */}
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='container'>
                    <div className='container paddingL0'>
                        <BreadcrumbMenu
                            homeMenuDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('home') ? true : false}
                            hotelListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('search') ? true : false}
                            hotelDetailsDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('hotel_details') ? true : false}
                            bookingListDisplay={false}
                            creditNoteListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('credit_note') ? true : false}
                            homeMenuClick={homeMenuClick}
                            hotelListClick={hotelListClick}
                            hotelDetailsClick={hotelDetailsClick}
                            bookingListClick={bookingListClick}
                            creditNoteClick={creditNoteClick}
                            activeCreditNoteList={true}
                        />
                    </div>

                    <div className="bookingHistoryContainer creditNoteSec"
                    // style={{ }}
                    >
                        <div id="scrollableDiv" className='bookingHistoryContainerInner creditNotesInner' >
                            <InfiniteScroll
                                dataLength={creditNoteData.length}
                                next={getNextData}
                                hasMore={hasMoreCreditNoteList}
                                scrollableTarget="scrollableDiv"
                            >
                                <div className='booking-history-boxes'>
                                    <table className="table creditNoteTable">
                                        <thead>
                                            <tr>
                                                <th scope="col">Reference No.</th>
                                                <th scope="col">Issued Date</th>
                                                <th scope="col">Valid Till</th>
                                                <th scope="col">Credit Notes</th>
                                                <th scope="col">Redeemed Date</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {creditNoteDataUi()}
                                        </tbody>
                                    </table>
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(CreditNotes));

