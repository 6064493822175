import React, { useState } from 'react';
import '../../Utility/Public/css/common.scss';
import '../../Utility/Public/css/commonresponsive.scss';


function ComingSoonPage() {
    return (
        <div className='page-section'>
            <div className='header-inner'>
                <div className='bottom-header'>
                    <div className='container'>
                        <div className='websiteLogo'>
                            <img src={require('../Public/images/stayingLogoWhite.png')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='innerContainer'>
                    <h1 className='coming-soon-label'>Coming Soon</h1>
                </div>
            </div>
        </div>
    )
}



export default ComingSoonPage