import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import Auth from "../../../../Layout/Auth";
import BookingHistory from "../Pages/BookingHistory";
import BookingDetails from "../Pages/BookingDetails";
import CreditNotes from "../Pages/CreditNotes"


function BookingRoute({ isLoggedIn, ...routeProps }) {
    return (
        <Switch>
            <Route
                exact
                path="/:lng/booking_history/:customer_type?"
                render={() => (
                    <Auth history={routeProps.history} allowedRoles={['customer']}>
                        <BookingHistory isLoggedIn={isLoggedIn} history={routeProps.history} />
                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/booking_details"
                render={() => (
                    <Auth history={routeProps.history} allowedRoles={['customer']}>
                        <BookingDetails isLoggedIn={isLoggedIn} history={routeProps.history} />
                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/credit_note"
                render={() => (
                    <Auth history={routeProps.history} allowedRoles={['customer']}>
                        <CreditNotes isLoggedIn={isLoggedIn} history={routeProps.history} />
                    </Auth>
                )}
            />
        </Switch>
    );
}

export default BookingRoute;
