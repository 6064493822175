// store.js
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './Reducers'; // You'll create this
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
};
const axiosInstance = {}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    applyMiddleware(thunk.withExtraArgument(axiosInstance))
);



export default store;
