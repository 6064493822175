import React, { useState, useEffect, useRef } from 'react'
import { loaderStateTrue, loaderStateFalse } from '../../Actions/AllAction';
import { setUserCredentials } from '../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function SkeletonThemeContentList(props) {


    return (
        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
            <SkeletonTheme baseColor="#f3f3f3" highlightColor="#ebebeb">
                <div className='skeleton-animation-section hotelListAnimation'>
                    <div className='filter-hotel-list-box'>
                        <div className='hotel-list-section'>
                            <div className='hotel-list-row'>
                                <div className='hotel-list-img-box'>
                                    <Skeleton width="100%" height={200} />
                                </div>
                                <div className='hotel-list-details-box'>
                                    <div className='hotel-list-details-box1'>
                                        <div className='hotelname'>
                                            <Skeleton width="100%" height={20} borderRadius={0} />
                                        </div>
                                        <div className='hoteladdress'>
                                            <Skeleton width="100%" height={50} borderRadius={0} />
                                        </div>
                                        <div className='cancellationrule'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box2'>
                                        <div className='propertytype'>
                                            <Skeleton width={70} height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box3'>
                                        <div className='reviewscorebox'>
                                            <div className='reviewscorebox1'>
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                            </div>
                                            <div className='reviewscorebox2'>
                                                <Skeleton width={45} height={45} />
                                            </div>
                                        </div>
                                        <div className='perNightRate'>
                                            <Skeleton width="45%" height={20} />
                                        </div>
                                        <div className='availability_btn'>
                                            <Skeleton width="60%" height={40} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='hotel-list-row'>
                                <div className='hotel-list-img-box'>
                                    <Skeleton width="100%" height={200} />
                                </div>
                                <div className='hotel-list-details-box'>
                                    <div className='hotel-list-details-box1'>
                                        <div className='hotelname'>
                                            <Skeleton width="100%" height={20} borderRadius={0} />
                                        </div>
                                        <div className='hoteladdress'>
                                            <Skeleton width="100%" height={50} borderRadius={0} />
                                        </div>
                                        <div className='cancellationrule'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box2'>
                                        <div className='propertytype'>
                                            <Skeleton width={70} height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box3'>
                                        <div className='reviewscorebox'>
                                            <div className='reviewscorebox1'>
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                            </div>
                                            <div className='reviewscorebox2'>
                                                <Skeleton width={45} height={45} />
                                            </div>
                                        </div>
                                        <div className='perNightRate'>
                                            <Skeleton width="45%" height={20} />
                                        </div>
                                        <div className='availability_btn'>
                                            <Skeleton width="60%" height={40} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='hotel-list-row'>
                                <div className='hotel-list-img-box'>
                                    <Skeleton width="100%" height={200} />
                                </div>
                                <div className='hotel-list-details-box'>
                                    <div className='hotel-list-details-box1'>
                                        <div className='hotelname'>
                                            <Skeleton width="100%" height={20} borderRadius={0} />
                                        </div>
                                        <div className='hoteladdress'>
                                            <Skeleton width="100%" height={50} borderRadius={0} />
                                        </div>
                                        <div className='cancellationrule'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                        <div className='advancerequired'>
                                            <Skeleton width="100%" height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box2'>
                                        <div className='propertytype'>
                                            <Skeleton width={70} height={15} borderRadius={0} />
                                        </div>
                                    </div>
                                    <div className='hotel-list-details-box3'>
                                        <div className='reviewscorebox'>
                                            <div className='reviewscorebox1'>
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                                <Skeleton width="100%" height={15} borderRadius={0} />
                                            </div>
                                            <div className='reviewscorebox2'>
                                                <Skeleton width={45} height={45} />
                                            </div>
                                        </div>
                                        <div className='perNightRate'>
                                            <Skeleton width="45%" height={20} />
                                        </div>
                                        <div className='availability_btn'>
                                            <Skeleton width="60%" height={40} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    );
}

SkeletonThemeContentList.defaultProps = {

}

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(SkeletonThemeContentList));