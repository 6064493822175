import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/bookingdoc.scss';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary'
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker'
import CustomInput from '../../../../Utility/Components/CustomInput'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PartialPaymentModal from '../../../../Utility/Components/PaymentModal'
import ModalGlobal from '../../../../Utility/Components/ModalGlobal'
import { DebounceInput } from 'react-debounce-input';
import { bookingList, viewBookingDetailsApi, bookingsIndividualDetails, bookingCancelled, uploadScreenshot } from '../Controller/BookingController';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import Utility from '../../../../Utility/Utility'
import ScaleLoader from "react-spinners/ScaleLoader";
import ReviewRating from '../Components/ReviewRating';
import Tooltip from 'react-bootstrap/Tooltip';
import ViewBookingDetails from '../Components/ViewBookingDetails';
import ConfirmationAlert from '../../../../Utility/Components/ConfirmationAlert'
import ValidateUtility from '../../../../Utility/ValidateUtility';
import BookingCancellationDetails from '../Components/BookingCancellationDetails';
import GenerateInvoiceContent from '../Components/GenerateInvoiceContent';
import PaymentViewModalContent from '../../Home/Components/PaymentViewModalContent';
import BreadcrumbMenu from '../../../../Utility/Components/BreadcrumbMenu'
import EventEmitter from '../../../../Utility/Components/EventEmitter';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';
import WithoutLoginMessageShow from '../../../../Utility/Components/WithoutLoginMessageShow';
import { useParams } from 'react-router-dom';
import BookingCancellation from '../Components/BookingCancellation';
import { withNamespaces } from 'react-i18next';
import LazyImage from '../../../../Utility/Components/LazyImageComponent';


function BookingHistory(props) {
    const { customer_type } = useParams();
    const [userDetailsData, setUserDetailsData] = useState({})
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [bookingHistoryData, setBookingHistoryData] = useState([]);
    const [bookingStartDate, setBookingStartDate] = useState(null);
    const [bookingEndDate, setBookingEndDate] = useState(null);
    const [searchBookingData, setSearchBookingData] = useState("");
    const [serachFlag, setSerachFlag] = useState(false);

    const [bookingRoomOffset, setBookingRoomOffset] = useState(0);
    const [bookingRoomLimit, setBookingRoomLimit] = useState(3);
    const [hasMoreBookingRoomList, setHasMoreBookingRoomList] = useState(true)
    const [hotelBookingData, setHotelBookingData] = useState([])
    const bookingRoomContentRef = useRef(true);
    const [dateRangeFilterFlag, setDateRangeFilterFlag] = useState(false);
    const [bookingDataStatus, setBookingDataStatus] = useState(false);
    const [reviewRatingModalFlag, setReviewRatingModalFlag] = useState(false);
    const [selectedGridObj, setSelectedGridObj] = useState(false);
    const [viewBookingDetailsModalFlag, setViewBookingDetailsModalFlag] = useState(false);
    const [viewBookingId, setViewBookingId] = useState("");
    const [viewDetailsData, setViewDetailsData] = useState({});

    //=========booking cancellation flag start===========
    const [bookingCancellationModalFlag, setBookingCancellationModalFlag] = useState(false);
    const [bookingDetailsData, setBookingDetailsData] = useState({});
    const [bookingCancellationDetailsModalFlag, setBookingCancellationDetailsModalFlag] = useState(false);
    const [bookingRulesFlag, setBookingRulesFlag] = useState(true);
    const [cancellationAmount, setCancellationAmount] = useState(0);
    const [cancellationAmountError, setCancellationAmountError] = useState("");
    const [cancellationDetailsPopUpModalFlag, setCancellationDetailsPopUpModalFlag] = useState(false);
    const [cancellationDetailsPopUpData, setCancellationDetailsPopUpData] = useState({});
    const [bookingCancellationId, setBookingCancellationId] = useState({});
    const [paymentModeFormData, setPaymentModeFormData] = useState({
        "paymentModeData": "",
        "amount": "",
        "transaction_date": "",
        "account_holder_name": "",
        "account_number": "",
        "ifsc_code": "",
        "bank_name": "",
        "branch_name": "",
        "upi_id": "",
        "cancellation_reason": "",
        "credit_notes_validity": moment().add(1, 'year').toDate(),
        "reference_id": "",
        "screenshot ": ""
    })
    const [paymentModeFormDataError, setPaymentModeFormDataError] = useState({
        "paymentModeData": "",
        "amount": "",
        "transaction_date": "",
        "account_holder_name": "",
        "account_number": "",
        "ifsc_code": "",
        "bank_name": "",
        "branch_name": "",
        "upi_id": "",
        "cancellation_reason": "",
        "credit_notes_validity": "",
        "reference_id": "",
        "screenshot ": ""
    })

    const paymentModeRef = useRef(null);
    //const [selectedPaymentModeData, setSelectedPaymentModeData] = useState({});
    const [selectedPaymentModeData, setSelectedPaymentModeData] = useState({ label: "Online", value: "online" });
    const [paymentModeList, setPaymentModeList] = useState([
        /*{ label: "Cash", value: "cash" },*/
        { label: "Online", value: "online" },
        { label: "Credit Note", value: "credit_note" }
    ]);
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [selectedCreditNoteValidityDateTime, setSelectedCreditNoteValidityDateTime] = useState(moment().add(1, 'year').toDate());
    const [uploadFile, setUploadFile] = useState([]);
    const [addPreviewImage, setAddPreviewImage] = useState([]);
    const [addPreviewImageError, setAddPreviewImageError] = useState("");
    const [selectedImageName, setSelectedImageName] = useState("");
    const [isUpiIdGiven, setIsUpiIdGiven] = useState(false);
    const [totalAmountExAllTaxesFlag, setTotalAmountExAllTaxesFlag] = useState(false);
    const reasonForCancellationRef = useRef(null);

    //=========booking cancellation flag end===========
    //=========invoice flag start===========
    const [createInvoiceModalFlag, setCreateInvoiceModalFlag] = useState(false);
    //=========invoice flag end===========
    const [bookingId, setBookingId] = useState("");
    const [bookingMasterId, setBookingMasterId] = useState("");
    const [paymentDetailsModalFlag, setPaymentDetailsModalFlag] = useState(false);
    const [bookingStatus, setBookingStatus] = useState('');
    const [paymentHistoryShowFlag, setPaymentHistoryShowFlag] = useState(false);
    const [isGuestFlag, setIsGuestFlag] = useState(false);


    const [selectedStatus, setSelectedStatus] = useState({ label: "Yet to confirm", value: "Yet to confirm" });
    const [statusList, setStatusList] = useState([
        { label: "Yet to confirm", value: "Yet to confirm" },
        { label: "Confirmed", value: "confirmed" },
        { label: "Checked-In", value: "checked-in" },
        { label: "Cancelled", value: "cancelled" },
        { label: "Completed", value: "completed" },
        { label: "Request Cancellation", value: "Request Cancellation" },
    ]);
    const [statusFilterFlag, setStatusFilterFlag] = useState(false);
    const [scrollEnabled, setScrollEnabled] = useState(true);

    const [isToday, setIsToday] = useState(true);

    const [bookingCancellationIndividualData, setBookingCancellationIndividualData] = useState({});



    const history = useHistory();

    // useEffect(() => {
    //     //console.log("isLoggedIn props bookingHistory", props)
    //     if (!props.isLoggedIn) {
    //         history.push(`/${localStorage.getItem('i18nextLng')}/login`);
    //     } else {
    //         sessionStorage.setItem('previousUrl', `/${localStorage.getItem('i18nextLng')}/booking_history`);
    //     }
    // }, [props.isLoggedIn]);

    const getCurrentTime = () => {
        const currentDate = new Date();
        return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes()
        );
    };

    useEffect(() => {
        if (selectedDateTime) {
            setIsToday(selectedDateTime.toDateString() === getCurrentTime().toDateString())
        } else {
            setIsToday(true)
        }
    }, [selectedDateTime])

    const createStarIconFn = (hotel_star) => {
        let stars = [];
        for (let i = 0; i < hotel_star; i++) {
            stars.push(<i key={i} className='fa fa-star'></i>);
        }
        return stars;
    }

    const mealStringFn = (hotelObj) => {
        let mealArray = []
        if (hotelObj.breakfast == "yes") {
            mealArray.push("Breakfast");
        }

        if (hotelObj.breakfast == "yes") {
            mealArray.push("Lunch");
        }

        if (hotelObj.breakfast == "yes") {
            mealArray.push("Dinner");
        }
        return mealArray.length > 0 ? mealArray.join(", ") + " included" : "";
    }

    const bookingDetails = (e, index) => {
        // props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_details`);
        setSelectedGridObj(hotelBookingData[index])
        setViewBookingId(hotelBookingData[index].id)
    }

    const makePayment = (bookingObj, type) => {
        // console.log("type======", type)
        // console.log("bookingObj======", bookingObj)
        setSerachFlag(false)
        setShowPaymentModal(true);
        setUserDetailsData({
            currency: bookingObj.currency,
            due_amount: bookingObj.due_amount,
            total_amount: bookingObj.total_amount,
            booking_id: bookingObj.booking_id,
            booking_master_id: bookingObj.id,
            customer_id: bookingObj.customer_id,
            BookingMasterBranchData: bookingObj.BookingMasterBranchData
        });
        setBookingStatus(bookingObj.book_status)
        // setBookingMasterId(params.id)
        // setBookingId(params.booking_id)
        // setCustomerId(params.customer_id)
        setPaymentDetailsModalFlag(true);
        if (type == 'history')
            setPaymentHistoryShowFlag(true)
    }

    const closePaymentModal = () => {
        setShowPaymentModal(false);
    }

    useEffect(() => {
        if (bookingRoomContentRef.current) {
            bookingRoomContentRef.current = false;
            // console.log("Entry================1");
            bookingRoomList();
        }
        // console.log("customer_type---------->", customer_type)
        if (customer_type == 'guest') {
            setIsGuestFlag(true);
        }
    }, []);

    useEffect(() => {

    }, [isGuestFlag])


    const bookingRoomList = () => {
        if (customer_type != 'guest') {
            const { loaderStateTrue, loaderStateFalse } = props;
            let promise = new Promise((resolve, reject) => {
                //setActionModalflag(false)
                //setActionModalflagOnlyViewPayment(false)
                setBookingDataStatus(false)
                loaderStateTrue();
                setScrollEnabled(false)
                let filters = {};
                let filter_op = {};
                let globalQueryParamshash = {};
                globalQueryParamshash["limit"] = bookingRoomLimit.toString();
                globalQueryParamshash["offset"] = bookingRoomOffset.toString();

                let startDate = moment(bookingStartDate).format('YYYY-MM-DD')
                let endDate = moment(bookingEndDate).format('YYYY-MM-DD')

                // globalQueryParamshash['book_from'] = moment().format('YYYY-MM-DD') + " " + "00:00:00"
                // globalQueryParamshash['book_to'] = moment().add(15, 'days').format('YYYY-MM-DD') + " " + "23:59:59";

                if (bookingStartDate && bookingStartDate != "" && bookingEndDate && bookingEndDate != "") {
                    globalQueryParamshash['book_from'] = startDate + " " + "00:00:00"
                    globalQueryParamshash['book_to'] = endDate + " " + "23:59:59";
                }

                if (searchBookingData !== "") {
                    globalQueryParamshash['search_attr'] = searchBookingData;
                    filter_op = Object.assign(filter_op, { "search_attr": "substring" });
                }

                if (selectedStatus != "") {
                    globalQueryParamshash['book_status'] = selectedStatus.value;
                }

                // if (hotelBranchFilterSelectedValue && hotelBranchFilterSelectedValue != "") {
                //     globalQueryParamshash['branch_id'] = hotelBranchFilterSelectedValue.value
                // }

                if (Object.keys(filter_op).length > 0) {
                    filters["filter_op"] = JSON.stringify(filter_op);
                }

                filters['filters'] = JSON.stringify(globalQueryParamshash);
                bookingList(filters).then((response) => {
                    if (response.success) {
                        // setCheckinCheckoutStatusFlag(false)
                        // setPaymentModalSuccessFlag(false)
                        setScrollEnabled(true)
                        resolve(response)
                    } else {
                        setBookingDataStatus(true)
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    console.error("************error*************", error)
                    if (error) {

                    }
                    loaderStateFalse();
                    setBookingDataStatus(true)
                    if (error.message == "Network Error") {
                    }
                });

            })
            promise.then((res) => {
                if (res.total > 0 || res.data.length > 0) {
                    setHasMoreBookingRoomList(true)
                    if (bookingRoomOffset <= res.total) {
                        let tempOffset = bookingRoomOffset + bookingRoomLimit
                        setBookingRoomOffset(tempOffset)
                        let tempAllBookingRoomData = hotelBookingData.slice()
                        let finalBookingRoomData = tempAllBookingRoomData.concat(res.data);

                        // finalBookingRoomData = [...finalBookingRoomData].sort((a, b) => new Date(a.booking_date) - new Date(b.booking_date));

                        setTimeout(() => {
                            setHotelBookingData(finalBookingRoomData)
                            setBookingDataStatus(true)
                            setScrollEnabled(true)
                        }, 500)
                    } else {
                        setHasMoreBookingRoomList(false)
                        setScrollEnabled(true)
                        setTimeout(() => {
                            setBookingDataStatus(true)
                        }, 500)

                    }
                    if (res.data.length == 0) {
                        setHasMoreBookingRoomList(false)
                        setScrollEnabled(true)
                        setTimeout(() => {
                            setBookingDataStatus(true)
                        }, 500)
                    }
                } else {
                    setHasMoreBookingRoomList(false)
                    setScrollEnabled(true)
                    setTimeout(() => {
                        setBookingDataStatus(true)
                    }, 500)
                }


            })
        }
    }

    const getNextData = () => {
        // console.log("Entry================2");
        bookingRoomList()
    }

    const onChangeDateRange = (dates) => {
        const [start, end] = dates;
        setBookingStartDate(start);
        setBookingEndDate(end);
        //setSearchBookingData("")
        setHotelBookingData([])
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        //
        setDateRangeFilterFlag(true)
    }

    useEffect(() => {
        if (bookingRoomOffset == 0 && !isGuestFlag && hotelBookingData.length == 0) {
            setHotelBookingData([])

        }
    }, [bookingRoomOffset])


    useEffect(() => {
        if (bookingStartDate && bookingStartDate != "" && bookingEndDate && bookingEndDate != "" && dateRangeFilterFlag && !isGuestFlag) {
            // console.log("Entry================4");
            bookingRoomList();
        }
        else if (bookingStartDate == null && bookingEndDate == null && dateRangeFilterFlag && !isGuestFlag) {
            // console.log("Entry================5");
            bookingRoomList();

        }
    }, [bookingEndDate, dateRangeFilterFlag])

    const handleSearchValue = (e) => {
        if (e.target.value != "") {
            setSearchBookingData(e.target.value)
            // setBookingStartDate(null);
            // setBookingEndDate(null);
            setBookingRoomOffset(0)
            setHotelBookingData([])
            setBookingRoomLimit(3)

        } else {
            setSearchBookingData("")
            // setBookingStartDate(null);
            // setBookingEndDate(null);
            setBookingRoomOffset(0)
            setBookingRoomLimit(3)
            // setHotelBookingData([])
        }
        setSerachFlag(true)

    }

    const clearSearchValue = () => {
        setSearchBookingData("")
        setSerachFlag(true)
        setHotelBookingData([])
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        //setHotelBookingData([])
    }

    useEffect(() => {
        if (searchBookingData != "" && !isGuestFlag) {
            // setHotelBookingData([])
            setBookingRoomOffset(0)
            setBookingRoomLimit(3)
        }
        if (searchBookingData.length == 0 && !isGuestFlag) {
            setBookingRoomOffset(0)
            setBookingRoomLimit(3)
            //setHotelBookingData([])
        }
        // console.log("serachFlag===========", serachFlag)
        if (serachFlag) {
            // console.log("Entry================78");
            bookingRoomList();
        }


    }, [searchBookingData, serachFlag])

    useEffect(() => {
    }, [searchBookingData, bookingStartDate, bookingEndDate])


    useEffect(() => {
    }, [hasMoreBookingRoomList])

    const reviewRatingModalOpenFn = (e, index) => {
        setSerachFlag(false)
        setSelectedGridObj(hotelBookingData[index])
        setReviewRatingModalFlag(true)
    }
    const reviewRatingModalCloseFn = () => {
        // setSerachFlag(true)
        // setBookingRoomOffset(0)
        // setBookingRoomLimit(3)
        // setHotelBookingData([])
        setReviewRatingModalFlag(false)
    }

    const reviewPostSuccessFn = () => {
        setReviewRatingModalFlag(false);
        setSerachFlag(true)
        setHotelBookingData([])
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        //setHotelBookingData([])
    }

    useEffect(() => {
        if (viewBookingId) {
            viewBookingDetailsFn(viewBookingId, "")
        }
    }, [viewBookingId])

    const viewBookingDetailsFn = (bookingId, type = "") => {
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();
        viewBookingDetailsApi(bookingId).then((response) => {
            if (response.success) {
                let tempResponce = response.hasOwnProperty('data') && response.data ? response.data : {}
                tempResponce["invoiceId"] = response.data.hasOwnProperty('booking_invoice') && response.data.booking_invoice && response.data.booking_invoice.hasOwnProperty('invoice_number') && response.data.booking_invoice.invoice_number && response.data.booking_invoice.invoice_number != "" ? response.data.invoice_number : new Date().valueOf();
                tempResponce["invoice_date"] = moment().format('DD-MM-YYYY');
                setViewDetailsData(tempResponce);
                if (type != "generateInvoice") {
                    setSerachFlag(false)
                    setViewBookingDetailsModalFlag(true)
                }
                if (type == "generateInvoice") {
                    setSerachFlag(false)
                    setCreateInvoiceModalFlag(true);
                }
            } else {
                setViewDetailsData({})
                if (type != "generateInvoice") {
                    setSerachFlag(false)
                    setViewBookingDetailsModalFlag(true)
                }
            }
            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
                //Utility.toastNotifications(error.message, "Error", "error");
            }
            loaderStateFalse();
            if (error.message == "Network Error") {

            }
        });
    }

    const closePaymentModalFunction = () => {
        // alert(1);
        setHotelBookingData([])
        setSerachFlag(true)
        // setHotelBookingData([])
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        setPaymentDetailsModalFlag(false)
        setPaymentHistoryShowFlag(false)
    }

    const closePaymentModalFn = () => {
        // alert(2);
        setHotelBookingData([])
        setSerachFlag(true)
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        // setHotelBookingData([])
        setPaymentDetailsModalFlag(false)
        setPaymentHistoryShowFlag(false)
    }
    const skipPaymentFn = () => { }

    const hotelBookingDataUi = () => {
        let labelArray = []
        if (hotelBookingData.length > 0) {
            hotelBookingData.map((bookingObj, idx) => {
                //console.log("billing_amt==========", parseFloat(bookingObj.billing_amt).toFixed(2))
                labelArray.push(<div className='booking-history-box' key={idx} onDoubleClick={() => handleRowDoubleClick(bookingObj, idx)}>
                    <div className='booking-history-box-inner'>
                        <div className='leftSection'>
                            <div className='hotel-room-img'>
                                {bookingObj.hasOwnProperty('branch_images') && bookingObj.branch_images.hasOwnProperty('image_url') && bookingObj.branch_images.image_url != "" && JSON.parse(bookingObj.branch_images.image_url).hasOwnProperty('img_url') && JSON.parse(bookingObj.branch_images.image_url).img_url ?
                                    // <img src={JSON.parse(bookingObj.branch_images.image_url).img_url} alt='Hotel' className="hotelRoom" /> 
                                    <LazyImage
                                        src={JSON.parse(bookingObj.branch_images.image_url).img_url}
                                        alt={require('../../../../Utility/Public/images/hotel.png')}
                                        loaderImage={require('../../../../Utility/Public/images/hotel.png')}
                                        className={"hotelRoom"}
                                    />
                                    :
                                    <LazyImage
                                        src={require('../../../../Utility/Public/images/hotel.png')}
                                        alt={require('../../../../Utility/Public/images/hotel.png')}
                                        loaderImage={require('../../../../Utility/Public/images/hotel.png')}
                                        className={"hotelRoom"}
                                    />
                                    // <img src={require('../../../../Utility/Public/images/hotel.png')} alt='Hotel' className="hotelRoom" />
                                }
                            </div>
                            <div className='hotel-room-details'>
                                <div className='booking-history-branch-details'>
                                    <div className='hotel-name'>
                                        {/* <h3>{bookingObj.hasOwnProperty('BookingMasterHotelData') && bookingObj.BookingMasterHotelData && bookingObj.BookingMasterHotelData.hasOwnProperty('hotel_name') && bookingObj.BookingMasterHotelData.hotel_name != "" && bookingObj.BookingMasterHotelData.hotel_name ? bookingObj.BookingMasterHotelData.hotel_name : ""}
                                            {/* <span className='ratingStar'>{createStarIconFn(bookingObj.hotel_star)}
                                    </span> */}

                                        {/* </h3>  */}
                                        <h3 className="hotelContactLabel">{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('branch_name') && bookingObj.BookingMasterBranchData.branch_name ? <>
                                            {bookingObj.BookingMasterBranchData.branch_name.length > 25 ?
                                                <OverlayTrigger overlay={<Tooltip>{bookingObj.BookingMasterBranchData.branch_name}</Tooltip>}>
                                                    <span>{(bookingObj.BookingMasterBranchData.branch_name).substring(0, 22) + "..."}</span>
                                                </OverlayTrigger>
                                                :
                                                <span>{bookingObj.BookingMasterBranchData.branch_name}</span>}
                                        </> : ""}</h3>
                                        <h2 className='bookingId'>[<span>Booking ID:</span> {bookingObj.hasOwnProperty('booking_id') && bookingObj.booking_id && bookingObj.booking_id != "" ? bookingObj.booking_id : ""}]</h2>
                                    </div>
                                    <div className='hotelCheckinCheckoutInner'>
                                        <div className='bookinginnerBox bookingStatusInner'>
                                            <div className="hotelContact">{bookingObj.hasOwnProperty('BookingMasterHotelData') && bookingObj.BookingMasterHotelData && bookingObj.BookingMasterHotelData.hasOwnProperty('email') && bookingObj.BookingMasterHotelData.email ? <>
                                                {bookingObj.BookingMasterHotelData.email.length > 30 ?
                                                    <OverlayTrigger overlay={<Tooltip>{bookingObj.BookingMasterHotelData.email}</Tooltip>}>
                                                        <span><i className="fa fa-envelope" aria-hidden="true"></i> {(bookingObj.BookingMasterHotelData.email).substring(0, 27) + "..."}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span><i className="fa fa-envelope" aria-hidden="true"></i> {bookingObj.BookingMasterHotelData.email}</span>}
                                            </> : ""}</div>
                                            <div className="hotelContact">{<i className="fa fa-phone-square" aria-hidden="true"></i>} {bookingObj.hasOwnProperty('BookingMasterHotelData') && bookingObj.BookingMasterHotelData && bookingObj.BookingMasterHotelData.hasOwnProperty('contact_number') && bookingObj.BookingMasterHotelData.contact_number ? bookingObj.BookingMasterHotelData.contact_number.includes("+") ? bookingObj.BookingMasterHotelData.contact_number : "+" + bookingObj.BookingMasterHotelData.contact_number : ""}</div>
                                            {/* <h2 className='bookingId'><span>Booking ID:</span> {bookingObj.hasOwnProperty('booking_id') && bookingObj.booking_id && bookingObj.booking_id != "" ? bookingObj.booking_id : ""}</h2> */}
                                            <div className='bookingStatus'>
                                                <span className={bookingObj.book_status.replace(/\s/g, '')} style={{ Color: bookingObj.book_status == "Yet to confirm" ? 'red' : "" }}>{bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status != "" ? bookingObj.book_status : ""}</span>
                                                {bookingObj.hasOwnProperty('is_early_checkout') && bookingObj.is_early_checkout != null && bookingObj.is_early_checkout == true ? <div className='subStatus'>Early Checkout</div> : null}
                                            </div>
                                        </div>
                                        <div className='bookinginnerBox hotelContact'>
                                            <div className="hotelContactLabel title">Branch Contact</div>
                                            {/* <div className="hotelContactLabel">{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('branch_name') && bookingObj.BookingMasterBranchData.branch_name ? <>
                                                {bookingObj.BookingMasterBranchData.branch_name.length > 25 ?
                                                    <OverlayTrigger overlay={<Tooltip>{bookingObj.BookingMasterBranchData.branch_name}</Tooltip>}>
                                                        <span>{(bookingObj.BookingMasterBranchData.branch_name).substring(0, 22) + "..."}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span>{bookingObj.BookingMasterBranchData.branch_name}</span>}
                                            </> : ""}</div> */}
                                            <div className="hotelContactLabel">{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('email1') && bookingObj.BookingMasterBranchData.email1 ? <>
                                                {bookingObj.BookingMasterBranchData.email1.length > 30 ?
                                                    <OverlayTrigger overlay={<Tooltip>{bookingObj.BookingMasterBranchData.email1}</Tooltip>}>
                                                        <span><i className="fa fa-envelope" aria-hidden="true"></i> {(bookingObj.BookingMasterBranchData.email1).substring(0, 27) + "..."}</span>
                                                    </OverlayTrigger>
                                                    :
                                                    <span><i className="fa fa-envelope" aria-hidden="true"></i> {bookingObj.BookingMasterBranchData.email1}</span>}
                                            </> : ""}</div>
                                            <div className="hotelContactLabel">{<i className="fa fa-phone-square" aria-hidden="true"></i>} {bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('contact1') && bookingObj.BookingMasterBranchData.contact1 ? bookingObj.BookingMasterBranchData.contact1.includes("+") ? bookingObj.BookingMasterBranchData.contact1 : "+" + bookingObj.BookingMasterBranchData.contact1 : ""}</div>
                                        </div>
                                        <div className='bookinginnerBox hotelCheckinTime'>
                                            <div className='checkLabel'>Check-in</div>
                                            {/* <div className='dateLabel'>{bookingObj.hasOwnProperty('book_from') && bookingObj.book_from && bookingObj.book_from != "" ? moment(bookingObj.book_from.toString()).format('DD-MM-YYYY HH:mm') : "-"}
                                            </div> */}
                                            {bookingObj.hasOwnProperty('book_from') && bookingObj.book_from && bookingObj.book_from != "" ?
                                                <>
                                                    {Utility.bookingDateUi('dateLabel', bookingObj.book_from, bookingObj.actual_checked_in, 'Check-in')}
                                                </> :
                                                <div className='dateLabel'>-</div>
                                            }
                                        </div>
                                        <div className='bookinginnerBox hotelCheckoutTime'>
                                            <div className='checkLabel'>Check-out</div>
                                            {/* <div className='dateLabel'>{bookingObj.hasOwnProperty('book_to') && bookingObj.book_to && bookingObj.book_to != "" ? moment(bookingObj.book_to.toString()).format('DD-MM-YYYY HH:mm') : "-"}
                                            </div> */}
                                            {bookingObj.hasOwnProperty('book_to') && bookingObj.book_to && bookingObj.book_to != "" ?
                                                <>
                                                    {Utility.bookingDateUi('dateLabel', bookingObj.book_to, bookingObj.actual_checked_out, 'Check-out')}
                                                </> :
                                                <div className='dateLabel'>-</div>
                                            }
                                        </div>
                                        
                                        {/* <div className='bookinginnerBox otherDetails'>
                                            <div className='roomType'>{bookingObj.room_type}</div>
                                            <div className='meals'>{mealStringFn(bookingObj)}</div>
                                        </div> */}

                                    </div>
                                    <div className='bookinginnerBox amountSection'>
                                            <div className='amountLabel'>Booking Amount: <span className='amount'>{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('currency') && bookingObj.BookingMasterBranchData.currency ? Utility.getCurrencySymbol(bookingObj.BookingMasterBranchData.currency) : Utility.getCurrencySymbol('INR')} {bookingObj.hasOwnProperty('total_amt') && bookingObj.total_amt && bookingObj.total_amt != "" ? parseFloat(bookingObj.total_amt).toFixed(2) : ""}</span></div>

                                            <div className='amountLabel'>Billing Amount: <span className='amount'>{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('currency') && bookingObj.BookingMasterBranchData.currency ? Utility.getCurrencySymbol(bookingObj.BookingMasterBranchData.currency) : Utility.getCurrencySymbol('INR')} {bookingObj.hasOwnProperty('billing_amt') && bookingObj.billing_amt && bookingObj.billing_amt != "" && bookingObj.billing_amt != "NaN" ? parseFloat(bookingObj.billing_amt).toFixed(2) : parseFloat(0.00).toFixed(2)}</span></div>

                                            <div className='amountLabel'>Paid Amount: <span className='amount'>{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('currency') && bookingObj.BookingMasterBranchData.currency ? Utility.getCurrencySymbol(bookingObj.BookingMasterBranchData.currency) : Utility.getCurrencySymbol('INR')} {bookingObj.hasOwnProperty('paid_amt') && bookingObj.paid_amt && bookingObj.paid_amt != "" ? parseFloat(bookingObj.paid_amt).toFixed(2) : ""}</span></div>

                                            {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status != "Cancelled" && <div className='amountLabel'>Due Amount: <span className='amount'>{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('currency') && bookingObj.BookingMasterBranchData.currency ? Utility.getCurrencySymbol(bookingObj.BookingMasterBranchData.currency) : Utility.getCurrencySymbol('INR')} {bookingObj.hasOwnProperty('due_amt') && bookingObj.due_amt && bookingObj.due_amt != "" ? parseFloat(bookingObj.due_amt).toFixed(2) : parseFloat(0.00).toFixed(2)}</span></div>}

                                            {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status == "Cancelled" && bookingObj.hasOwnProperty('refunded_amount') && bookingObj.refunded_amount != null && Number(bookingObj.refunded_amount) != 0 && <div className='amountLabel'>Refunded Amount: <span className='amount'>{bookingObj.hasOwnProperty('BookingMasterBranchData') && bookingObj.BookingMasterBranchData && bookingObj.BookingMasterBranchData.hasOwnProperty('currency') && bookingObj.BookingMasterBranchData.currency ? Utility.getCurrencySymbol(bookingObj.BookingMasterBranchData.currency) : Utility.getCurrencySymbol('INR')} {bookingObj.hasOwnProperty('refunded_amount') && bookingObj.refunded_amount && bookingObj.refunded_amount != "" ? parseFloat(bookingObj.refunded_amount).toFixed(2) : ""}</span></div>}

                                            {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status === "Cancelled" && bookingObj.hasOwnProperty('refunded_id') && bookingObj.refunded_id != null && bookingObj.refunded_id !== "" && (
                                                <div className='amountLabel'>
                                                    Credit Notes Ref. ID: <span className='amount' style={{ color: bookingObj.hasOwnProperty('is_expired') && bookingObj.is_expired != null && bookingObj.is_expired ? "red" : "green" }}>
                                                        {bookingObj.hasOwnProperty('refunded_id') && bookingObj.refunded_id && bookingObj.refunded_id !== "" ? bookingObj.refunded_id : ""}
                                                    </span>
                                                </div>
                                            )}

                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className='rightSection'>
                            <div className='hotel-room-action'>
                                <div className="group_single_action">
                                    <OverlayTrigger
                                        rootClose
                                        trigger="click"
                                        placement="bottom"
                                        overlay={
                                            <Popover className="group_single_action_inner">
                                                <Popover.Body>
                                                    <div className="group_action_row">
                                                        {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status == "Confirmed" &&
                                                            <button className="group_action_btn_popup" onClick={(e) => { bookingCancellation(bookingObj) }}>
                                                                <span className='group_action_btn_popup_text'>Cancel Booking</span>
                                                            </button>
                                                        }
                                                        < button className="group_action_btn_popup">
                                                            <span className='group_action_btn_popup_text' onClick={(e) => { bookingDetails(e, idx) }}>Booking details</span>
                                                        </button>
                                                        {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && (bookingObj.book_status == "Confirmed" || bookingObj.book_status == "Checked-In" || bookingObj.book_status == "Yet to confirm") ?
                                                            <button className="group_action_btn_popup" onClick={(e) => { makePayment(bookingObj, 'pay') }}>
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Payments</span></span>
                                                            </button>
                                                            : <button className="group_action_btn_popup">
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text' onClick={(e) => { makePayment(bookingObj, 'history') }}>Payments</span></span>
                                                            </button>}



                                                        {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status != "Confirmed" && bookingObj.book_status != "Cancelled" && bookingObj.book_status != "Yet to confirm" && bookingObj.book_status != "Request Cancellation" &&
                                                            <button className="group_action_btn_popup" onClick={(e) => { reviewRatingModalOpenFn(e, idx) }}>
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>{bookingObj.BookingMasterRatingRelation.length > 0 ? 'Review Rating' : "Add Review Rating"}</span></span>
                                                            </button>
                                                        }
                                                        {bookingObj.hasOwnProperty('book_status') && bookingObj.book_status && bookingObj.book_status == "Completed" &&
                                                            <button className="group_action_btn_popup" onClick={(e) => { createInvoiceFn(bookingObj) }}>
                                                                <span className='group_action_btn_popup_text'><span className='group_action_btn_popup_text'>Download Invoice</span></span>
                                                            </button>
                                                        }
                                                    </div>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <button className='ellipsisBtn'><i className="fa fa-ellipsis-v"></i></button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >)
            })
        } else if (hotelBookingData.length == 0) {
            labelArray = []
            if (bookingDataStatus == true) {
                labelArray.push(<div className='noDataFound'>
                    {bookingDataStatus && props.isLoggedIn &&
                        <h3 className='title'>No Data Found.</h3>
                    }
                </div>)
            } else {
                labelArray = []
                if (!bookingDataStatus && !props.isLoggedIn && customer_type == 'guest') {
                    labelArray.push(<WithoutLoginMessageShow
                        isMessageShow={true}
                        message="Your login credentials have been sent to your email. Please log in first to access and review your booking details"
                        isRedirectPageButtonShow={true}
                        redirectButtonLabel="Go To Login Page"
                        redirectButtonUrl="/login"
                        redirectFromUrl="/booking_history"
                    />)
                }
            }

        }

        return labelArray
    }


    const closeViewBookingDetails = () => {
        // setSerachFlag(true)
        setViewBookingDetailsModalFlag(false)
        // setOpenGenerateInvoiceModal(false)
        setViewBookingId("")
        setViewDetailsData({})
        // resetInvoiceFromData();

    }

    const handleRowDoubleClick = (data, idx) => {
        // setSerachFlag(false)
        bookingDetails(data, idx);
    }

    //=========booking cancellation work start==============

    const handleChangeBookingRules = (e, type) => {
        const { checked } = e.target;
        let paidAmount = bookingDetailsData.hasOwnProperty('total_paid') && bookingDetailsData.total_paid
        let totalAmount = bookingDetailsData.hasOwnProperty('total_amount') && bookingDetailsData.total_amount
        if (type === "follow") {
            if (checked) {
                setBookingRulesFlag(true);
                setTotalAmountExAllTaxesFlag(false)
            }
        }
        if (type === "unfollow") {
            if (checked) {
                setBookingRulesFlag(false);
                setCancellationAmount(0)
            }
        }

        if (type === "total_amount_ex_all_taxes") {
            if (checked) {
                setTotalAmountExAllTaxesFlag(false);
            }
        }
        if (type === "total_amount_room_ex_all_taxes") {
            if (checked) {
                setTotalAmountExAllTaxesFlag(true);
            }
        }

        if (type == "cancellation_amount") {
            var expr = /^[0-9.]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    setCancellationAmount("");
                    setCancellationAmountError("");
                } else {
                    if (e.target.value > totalAmount) {
                        setCancellationAmount("");
                        setCancellationAmountError("");
                        Utility.toastNotifications("The cancellation amount cannot surpass the total amount.", "Warning", "warning")
                    } else {
                        setCancellationAmount(e.target.value);
                        setCancellationAmountError("");
                    }

                }
            }
        }
    }

    useEffect(() => {
        // console.log("customAmount==========", cancellationAmount)
        // console.log("customAmountError==========", cancellationAmountError)
    }, [bookingRulesFlag, cancellationAmount, cancellationAmountError, totalAmountExAllTaxesFlag])

    const cancellationModalHide = () => {
        setBookingCancellationModalFlag(false);
    }

    const bookingCancellationCancleButton = () => {
        setBookingCancellationModalFlag(false);
    }

    const bookingCancellationConfirmButton = () => {
        //bookingCancellationApi()
        //bookingsIndividualDetailsFn()
        setBookingCancellationDetailsModalFlag(true)
    }

    const bookingCancellation = (bookingRowData) => {
        // console.log("bookingRowData==========", bookingRowData)
        setSerachFlag(false)
        setBookingCancellationModalFlag(true);
        setBookingCancellationId(bookingRowData.id)
        setBookingCancellationIndividualData(bookingRowData)
        setBankDetailsIfExists(bookingRowData)
    }


    const setBankDetailsIfExists = (bookingCancellationIndividualData) => {

        if (selectedPaymentModeData.value == "online" && bookingCancellationIndividualData.hasOwnProperty('bank_details_for_refund') && bookingCancellationIndividualData.bank_details_for_refund.length > 0) {

            setPaymentModeFormData({
                "account_holder_name": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('payee_ac_holder_name') ? bookingCancellationIndividualData.bank_details_for_refund[0].payee_ac_holder_name : "",

                "bank_name": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('bank_name') ? bookingCancellationIndividualData.bank_details_for_refund[0].bank_name : "",

                "account_number": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('payee_bank_ac_no') ? bookingCancellationIndividualData.bank_details_for_refund[0].payee_bank_ac_no : "",

                "ifsc_code": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('payee_bank_ifsc_code') ? bookingCancellationIndividualData.bank_details_for_refund[0].payee_bank_ifsc_code : "",

                "branch_name": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('payee_bank_branch_name_code') ? bookingCancellationIndividualData.bank_details_for_refund[0].payee_bank_branch_name_code : "",

                "upi_id": bookingCancellationIndividualData.bank_details_for_refund[0].hasOwnProperty('upi_id') ? bookingCancellationIndividualData.bank_details_for_refund[0].upi_id : "",

            })
        }

    }

    useEffect(() => {
        // console.log("paymentModeFormData==================", paymentModeFormData)
    }, [paymentModeFormData])


    const validationCancellationData = () => {
        let valid = true;
        let paidAmount = bookingDetailsData.hasOwnProperty('total_paid') && bookingDetailsData.total_paid
        let totalAmount = bookingDetailsData.hasOwnProperty('total_amount') && bookingDetailsData.total_amount

        let tempPaymentModeFormData = { ...paymentModeFormData };
        let tempPaymentModeFormDataError = { ...paymentModeFormDataError }

        // if (!props.bookingRulesFlag) {
        //     if (cancellationAmount > totalAmount) {
        //         valid = false;
        //         Utility.toastNotifications("The cancellation amount cannot surpass the total amount.", "Warning", "warning")
        //     }
        // }

        if (tempPaymentModeFormData.cancellation_reason === undefined || tempPaymentModeFormData.cancellation_reason.trim() === "") {
            tempPaymentModeFormDataError['cancellation_reason'] = "Required field"
            valid = false
        }
        //let amountRefunded = bookingDetailsData.hasOwnProperty('amount_refunded') && bookingDetailsData.amount_refunded


        //if (amountRefunded != "" && Number(amountRefunded) > 0) {
        if (Object.keys(selectedPaymentModeData).length == 0) {
            tempPaymentModeFormDataError['paymentModeData'] = "Required field"
            valid = false
        }

        // if (selectedPaymentModeData.value == "cash") {

        //     if (tempPaymentModeFormData.transaction_date == "") {
        //         tempPaymentModeFormDataError['transaction_date'] = "Required field"
        //         valid = false
        //     }

        // }

        if (selectedPaymentModeData.value == "online") {
            if (!isUpiIdGiven) {
                if ((tempPaymentModeFormData.account_holder_name).trim() == "") {
                    tempPaymentModeFormDataError['account_holder_name'] = "Required field"
                    valid = false
                }

                if (tempPaymentModeFormData.account_number == "") {
                    tempPaymentModeFormDataError['account_number'] = "Required field"
                    valid = false
                }

                if (tempPaymentModeFormData.bank_name == "") {
                    tempPaymentModeFormDataError['bank_name'] = "Required field"
                    valid = false
                }
            } else {
                tempPaymentModeFormDataError['account_holder_name'] = ""
                tempPaymentModeFormDataError['account_number'] = ""
                tempPaymentModeFormDataError['bank_name'] = ""
            }

            if (tempPaymentModeFormData.account_number != "") {
                let accountNumberValidate = ValidateUtility.validateBankAccountNumber(tempPaymentModeFormData.account_number);
                if (accountNumberValidate) {

                } else {
                    tempPaymentModeFormDataError['account_number'] = props.t('bankAccountNumber')
                    valid = false
                }
            }

            if (tempPaymentModeFormData.ifsc_code != "") {
                let IFSCValidate = ValidateUtility.validateIFSC(tempPaymentModeFormData.ifsc_code);
                if (IFSCValidate) {

                } else {
                    tempPaymentModeFormDataError['ifsc_code'] = props.t('bankIFSC')
                    valid = false
                }
            }


        }





        if (paymentModeFormData.upi_id != "") {
            if (!Utility.validateUPI(paymentModeFormData.upi_id)) {
                valid = false
                tempPaymentModeFormDataError['upi_id'] = "Invalid UPI ID"
            }
        }

        setPaymentModeFormDataError(tempPaymentModeFormDataError)

        return valid
    }

    const formatCancellationData = () => {

        let tempPaymentModeFormData = { ...paymentModeFormData };
        let tempPaymentModeFormDataError = { ...paymentModeFormDataError }

        let dataset = [];
        let hash = {}

        if (tempPaymentModeFormData.cancellation_reason != "") {
            hash['reason_for_cancellation'] = tempPaymentModeFormData.cancellation_reason;

        }

        // if (bookingCancellationId != "") {
        //     hash['book_status'] = "Cancelled"
        //     hash['is_rule_following'] = bookingRulesFlag ? true : false
        //     if (!bookingRulesFlag) {
        //         hash['cancellation_amount'] = parseFloat(cancellationAmount).toFixed(2)
        //     }
        // }

        // if (bookingRulesFlag) {
        //     hash['is_room_rate_only'] = totalAmountExAllTaxesFlag ? true : false
        // }

        //let amountRefunded = bookingDetailsData.hasOwnProperty('amount_refunded') && bookingDetailsData.amount_refunded

        //if (amountRefunded != "" && Number(amountRefunded) > 0) {
        // if (selectedPaymentModeData.value == "cash") {

        //     if (selectedPaymentModeData.value != "") {
        //         hash['payment_mode'] = selectedPaymentModeData.value;
        //     }

        //     if (tempPaymentModeFormData.amount != "") {
        //         hash['amount'] = tempPaymentModeFormData.amount;
        //     }

        //     if (tempPaymentModeFormData.transaction_date != "" || tempPaymentModeFormData.transaction_date != null) {
        //         hash['transaction_date'] = moment(tempPaymentModeFormData.transaction_date).format('YYYY-MM-DD HH:mm');
        //     }

        // }


        if (selectedPaymentModeData.value == "online") {

            if (selectedPaymentModeData.value != "") {
                hash['payment_mode'] = selectedPaymentModeData.value;
            }

            // if (tempPaymentModeFormData.amount != "") {
            //     //hash['amount'] = tempPaymentModeFormData.amount;
            //     hash['amount'] = amountRefunded
            // }

            if (tempPaymentModeFormData.account_holder_name != "") {
                hash['ac_holder_name'] = tempPaymentModeFormData.account_holder_name;
            }

            if (tempPaymentModeFormData.account_number != "") {
                hash['account_number'] = tempPaymentModeFormData.account_number;
            }

            hash['ifsc_code'] = tempPaymentModeFormData.ifsc_code;

            if (tempPaymentModeFormData.bank_name != "") {
                hash['bank_name'] = tempPaymentModeFormData.bank_name;
            }

            hash['branch_name'] = tempPaymentModeFormData.branch_name;

            if (tempPaymentModeFormData.upi_id != "") {

                hash['upi_id'] = tempPaymentModeFormData.upi_id;
            }


            // if (tempPaymentModeFormData.reference_id != "") {
            //     hash['reference_id'] = tempPaymentModeFormData.reference_id;
            // }

            // if (tempPaymentModeFormData.transaction_date != "" || tempPaymentModeFormData.transaction_date != null) {
            //     hash['transaction_date'] = moment(tempPaymentModeFormData.transaction_date).format('YYYY-MM-DD HH:mm');
            // }

        }

        if (selectedPaymentModeData.value == "credit_note") {

            if (selectedPaymentModeData.value != "") {
                hash['payment_mode'] = selectedPaymentModeData.value == "credit_note" ? "credit note" : "";
            }

            // if (tempPaymentModeFormData.amount != "") {
            //    //hash['amount'] = tempPaymentModeFormData.amount;
            //    hash['amount'] = amountRefunded
            // }

            // if (tempPaymentModeFormData.credit_notes_validity != "" || tempPaymentModeFormData.credit_notes_validity != null) {
            //     hash['credit_note_valid_till'] = moment(tempPaymentModeFormData.credit_notes_validity).format('YYYY-MM-DD');
            // }

        }
        // if (selectedPaymentModeData.value == "credit_note") {

        //     if (selectedPaymentModeData.value != "") {
        //         hash['payment_mode'] = selectedPaymentModeData.value == "credit_note" ? "credit note" : "";
        //     }

        //     // if (tempPaymentModeFormData.amount != "") {
        //     //     hash['amount'] = tempPaymentModeFormData.amount;
        //     // }

        //     let refundedAmount = bookingDetailsData.hasOwnProperty('amount_refunded') && bookingDetailsData.amount_refunded
        //     let cancellation_gst_percentage = bookingDetailsData.hasOwnProperty('cancellation_gst_percentage') && bookingDetailsData.cancellation_gst_percentage

        //     let paidAmount = bookingDetailsData.hasOwnProperty('total_paid') && bookingDetailsData.total_paid
        //     let cancellationAmount = bookingDetailsData.hasOwnProperty('cancellation_amount') && bookingDetailsData.cancellation_amount

        //     let gstAmount = 0

        //     if (cancellationAmount > 0) {
        //         gstAmount = (cancellation_gst_percentage / 100) * cancellationAmount;
        //     }
        //     let withoutFlowCancellationPoliciesRefundedAmount = Number(paidAmount) - (Number(gstAmount) + Number(cancellationAmount));


        //     if (bookingRulesFlag) {
        //         hash['amount'] = parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2);
        //     } else {
        //         hash['amount'] = parseFloat(refundedAmount).toFixed(2)
        //     }

        //     // if (tempPaymentModeFormData.credit_notes_validity != "" || tempPaymentModeFormData.credit_notes_validity != null) {
        //     //     hash['credit_note_valid_till'] = moment(tempPaymentModeFormData.credit_notes_validity).format('YYYY-MM-DD HH:mm');
        //     // }

        //     if (selectedCreditNoteValidityDateTime && selectedCreditNoteValidityDateTime != "" && selectedCreditNoteValidityDateTime != null) {
        //         hash['credit_note_valid_till'] = moment(selectedCreditNoteValidityDateTime).format('YYYY-MM-DD');
        //     }

        // }
        //}

        //dataset.push(hash)
        return hash;
    }

    const bookingCancellationApi = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let vaildData = validationCancellationData();
        let dataset = formatCancellationData();
        // return false;
        if (vaildData) {
            let hash = {}
            hash['refund_bank_details'] = JSON.stringify(dataset)
            hash['book_status'] = 'Request Cancellation';
            let id = ""
            if (bookingCancellationId != "") {
                id = bookingCancellationId
            }
            //return false
            loaderStateTrue();
            bookingCancelled(id, hash).then((response) => {
                if (response.success) {
                    if (uploadFile[0]) {
                        uploadScreenshotImage(response)
                    } else {
                        setCancellationDetailsPopUpModalFlag(true);
                        setCancellationDetailsPopUpData(response.data ? response.data : {})
                        setBookingRoomOffset(0)
                        setBookingRoomLimit(3)
                        setHotelBookingData([])
                        cancellationModalHide()
                        cancellationDetailsModalHide()
                        resetPaymentModeFormData()
                        loaderStateFalse();
                        //setSerachFlag(true)
                        Utility.toastNotifications(response.message, "Success", "success")
                    }
                } else {
                    loaderStateFalse();
                    Utility.toastNotifications(response.message, "Error", "error")
                }
            }).catch((error) => {
                loaderStateFalse();
                cancellationModalHide()
                Utility.toastNotifications(error?.response?.data?.message[0]?.message, "Warning", "warning")
            });
        }

    }

    const cancellationDetailsModalHide = () => {
        setBookingCancellationDetailsModalFlag(false)
        setBookingCancellationId("")
        cancellationModalHide()
        setBookingRulesFlag(true);
        setCancellationAmount(0);
        setCancellationAmountError("");
        setIsUpiIdGiven(false)
        resetPaymentModeFormData();
        setTotalAmountExAllTaxesFlag(false)
        setHotelBookingData([])
        setSerachFlag(true)
        setBookingRoomOffset(0)
        setBookingRoomLimit(3)
        //setHotelBookingData([])
    }
    const bookingCancellationDetalsSave = () => {
        if (reasonForCancellationRef.current) {
            reasonForCancellationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        bookingCancellationApi();
    }

    const bookingsIndividualDetailsFn = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();

        bookingsIndividualDetails(bookingCancellationId).then((response) => {
            if (response.success) {
                setBookingDetailsData(response.data)
                //setCancellationPaymentData(response.data)
                //setBookingCancellationDetailsModalFlag(true)
            } else {
                setBookingDetailsData({})
            }
            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
                //Utility.toastNotifications(error.message, "Error", "error");
            }
            loaderStateFalse();
            if (error.message == "Network Error") {

            }
        });
    }


    // const setCancellationPaymentData = (dataSet) => {

    //     setPaymentModeFormData({
    //         "account_holder_name": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('payee_ac_holder_name') && dataSet.customer_bank_details.payee_ac_holder_name != "" && dataSet.customer_bank_details.payee_ac_holder_name != null ? dataSet.customer_bank_details.payee_ac_holder_name : "",

    //         "bank_name": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('bank_name') && dataSet.customer_bank_details.bank_name != "" && dataSet.customer_bank_details.bank_name != null ? dataSet.customer_bank_details.bank_name : "",

    //         "account_number": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('payee_bank_ac_no') && dataSet.customer_bank_details.payee_bank_ac_no != "" && dataSet.customer_bank_details.payee_bank_ac_no != null ? dataSet.customer_bank_details.payee_bank_ac_no : "",

    //         "ifsc_code": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('payee_bank_ifsc_code') && dataSet.customer_bank_details.payee_bank_ifsc_code != "" && dataSet.customer_bank_details.payee_bank_ifsc_code != null ? dataSet.customer_bank_details.payee_bank_ifsc_code : "",

    //         "branch_name": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('payee_bank_branch_name_code') && dataSet.customer_bank_details.payee_bank_branch_name_code != "" && dataSet.customer_bank_details.payee_bank_branch_name_code != null ? dataSet.customer_bank_details.payee_bank_branch_name_code : "",

    //         "upi_id": dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('upi_id') && dataSet.customer_bank_details.upi_id != "" && dataSet.customer_bank_details.upi_id != null ? dataSet.customer_bank_details.upi_id : "",

    //     })

    //     if (dataSet.hasOwnProperty('customer_bank_details') && dataSet.customer_bank_details.hasOwnProperty('upi_id') && dataSet.customer_bank_details.upi_id != "" && dataSet.customer_bank_details.upi_id != null) {
    //         setIsUpiIdGiven(true)
    //     } else {
    //         setIsUpiIdGiven(false)
    //     }

    // }

    const resetPaymentModeFormData = () => {
        setPaymentModeFormData({
            "paymentModeData": "",
            "amount": "",
            "transaction_date": "",
            "account_holder_name": "",
            "account_number": "",
            "ifsc_code": "",
            "bank_name": "",
            "branch_name": "",
            "upi_id": "",
            "cancellation_reason": "",
            "credit_notes_validity": moment().add(1, 'year').toDate(),
            "reference_id": "",
            "screenshot ": ""
        })
        setPaymentModeFormDataError({
            "paymentModeData": "",
            "amount": "",
            "transaction_date": "",
            "account_holder_name": "",
            "account_number": "",
            "ifsc_code": "",
            "bank_name": "",
            "branch_name": "",
            "upi_id": "",
            "cancellation_reason": "",
            "credit_notes_validity": "",
            "reference_id": "",
            "screenshot ": ""
        })
        setSelectedPaymentModeData({ label: "Online", value: "online" })
        setSelectedDateTime(null)
        setSelectedCreditNoteValidityDateTime(moment().add(1, 'year').toDate())
        setUploadFile([]);
        setAddPreviewImage([]);
        setSelectedImageName("");
    }

    const handelChangePaymentMode = (e, type) => {
        let tempPaymentModeFormData = { ...paymentModeFormData };
        let tempPaymentModeFormDataError = { ...paymentModeFormDataError }

        let paidAmount = bookingDetailsData.hasOwnProperty('total_paid') && bookingDetailsData.total_paid
        let cancellation_gst_percentage = bookingDetailsData.hasOwnProperty('cancellation_gst_percentage') & bookingDetailsData.cancellation_gst_percentage
        let gstAmount = 0
        if (cancellationAmount > 0) {
            gstAmount = (cancellation_gst_percentage / 100) * cancellationAmount;
        }
        let withoutFlowCancellationPoliciesRefundedAmount = Number(paidAmount) - (Number(gstAmount) + Number(cancellationAmount));
        let amountRefunded = bookingDetailsData.hasOwnProperty('amount_refunded') && bookingDetailsData.amount_refunded


        if (type == "payment_mode") {
            if (e != null) {

                tempPaymentModeFormData["paymentModeData"] = e.value
                tempPaymentModeFormDataError["paymentModeData"] = ""

                tempPaymentModeFormData["amount"] = !bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)
                tempPaymentModeFormData["transaction_date"] = ""
                tempPaymentModeFormData["credit_notes_validity"] = moment().add(1, 'year').toDate()
                tempPaymentModeFormData["reference_id"] = ""
                tempPaymentModeFormDataError["transaction_date"] = ""
                tempPaymentModeFormDataError["account_holder_name"] = ""
                tempPaymentModeFormDataError["account_number"] = ""
                tempPaymentModeFormDataError["ifsc_code"] = ""
                tempPaymentModeFormDataError["bank_name"] = ""
                tempPaymentModeFormDataError["branch_name"] = ""
                tempPaymentModeFormDataError["upi_id"] = ""
                tempPaymentModeFormDataError["credit_notes_validity"] = ""
                tempPaymentModeFormDataError["reference_id"] = ""
                tempPaymentModeFormDataError["screenshot"] = ""
                setSelectedDateTime(null)
                setSelectedCreditNoteValidityDateTime(moment().add(1, 'year').toDate())
                setSelectedPaymentModeData(e)
            } else {
                tempPaymentModeFormData["paymentModeData"] = ""
                tempPaymentModeFormDataError["paymentModeData"] = ""
                setSelectedPaymentModeData({ label: "Online", value: "online" })
            }

        }

        if (type == "amount") {
            var expr = /^[0-9.]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempPaymentModeFormData["amount"] = ""
                    tempPaymentModeFormDataError["amount"] = ""
                } else {

                    tempPaymentModeFormData["amount"] = e.target.value
                    tempPaymentModeFormDataError["amount"] = ""

                }
            }
        }

        if (type == "account_holder_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempPaymentModeFormDataError['account_holder_name'] = ""
                    tempPaymentModeFormData["account_holder_name"] = ""
                } else {
                    tempPaymentModeFormData["account_holder_name"] = e.target.value
                    tempPaymentModeFormDataError['account_holder_name'] = ""
                }
            }
        }

        // if (type == "transaction_date") {

        //     if (e != "" && e != null) {
        //         const currentDateTime = new Date();
        //         const selectedDateTime = new Date(e);
        //         const hasTime = selectedDateTime.getHours() !== 0 || selectedDateTime.getMinutes() !== 0 || selectedDateTime.getSeconds() !== 0;
        //         if (!hasTime) {
        //             selectedDateTime.setHours(currentDateTime.getHours());
        //             selectedDateTime.setMinutes(currentDateTime.getMinutes());
        //             selectedDateTime.setSeconds(currentDateTime.getSeconds());
        //         }
        //         setSelectedDateTime(selectedDateTime);
        //         tempPaymentModeFormData["transaction_date"] = selectedDateTime
        //         tempPaymentModeFormDataError["transaction_date"] = ""

        //     } else {
        //         tempPaymentModeFormData["transaction_date"] = ""
        //         tempPaymentModeFormDataError["transaction_date"] = ""
        //     }
        // }

        if (type == "account_number") {
            if (e.target.value == "") {
                tempPaymentModeFormData['account_number'] = "";
                tempPaymentModeFormDataError['account_number'] = ""
            } else {
                let accountNumberValidate = ValidateUtility.validateBankAccountNumber(e.target.value);
                if (accountNumberValidate) {
                    tempPaymentModeFormData['account_number'] = e.target.value.toUpperCase();
                    tempPaymentModeFormDataError['account_number'] = ""
                } else {
                    tempPaymentModeFormData['account_number'] = e.target.value.toUpperCase();
                    tempPaymentModeFormDataError['account_number'] = "Please enter a correct account number"
                }
            }
        }

        if (type == "ifsc_code") {
            if (e.target.value == "") {
                tempPaymentModeFormData['ifsc_code'] = "";
                tempPaymentModeFormDataError['ifsc_code'] = ""
            } else {
                let IFSCValidate = ValidateUtility.validateIFSC(e.target.value);
                if (IFSCValidate) {
                    tempPaymentModeFormData['ifsc_code'] = e.target.value.toUpperCase();
                    tempPaymentModeFormDataError['ifsc_code'] = ""
                } else {
                    tempPaymentModeFormData['ifsc_code'] = e.target.value.toUpperCase();
                    tempPaymentModeFormDataError['ifsc_code'] = "Please enter a correct IFSC code"
                }
            }
        }

        if (type == "bank_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempPaymentModeFormDataError['bank_name'] = ""
                    tempPaymentModeFormData["bank_name"] = ""
                } else {
                    tempPaymentModeFormData["bank_name"] = e.target.value
                    tempPaymentModeFormDataError['bank_name'] = ""
                }
            }
        }

        if (type == "branch_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value == "") {
                    tempPaymentModeFormDataError['branch_name'] = ""
                    tempPaymentModeFormData["branch_name"] = ""
                } else {
                    tempPaymentModeFormData["branch_name"] = e.target.value
                    tempPaymentModeFormDataError['branch_name'] = ""
                }
            }
        }

        if (type == "upi_id") {

            if (e.target.value == "") {
                setIsUpiIdGiven(false)
                tempPaymentModeFormDataError['upi_id'] = ""
                tempPaymentModeFormData["upi_id"] = ""
            } else {
                setIsUpiIdGiven(true)
                tempPaymentModeFormData["upi_id"] = e.target.value
                tempPaymentModeFormDataError['upi_id'] = ""
                tempPaymentModeFormDataError['account_holder_name'] = ""
                tempPaymentModeFormDataError['account_number'] = ""
                tempPaymentModeFormDataError['bank_name'] = ""
            }

        }


        // if (type == "reference_id") {
        //     var expr = /^[a-zA-Z0-9-/]*$/;
        //     if (!expr.test(e.target.value)) {

        //     } else {
        //         if (e.target.value == "") {
        //             tempPaymentModeFormDataError['reference_id'] = ""
        //             tempPaymentModeFormData["reference_id"] = ""
        //         } else {
        //             tempPaymentModeFormData["reference_id"] = e.target.value
        //             tempPaymentModeFormDataError['reference_id'] = ""
        //         }
        //     }
        // }

        if (type == "cancellation_reason") {
            if (e.target.value == "") {
                tempPaymentModeFormDataError['cancellation_reason'] = ""
                tempPaymentModeFormData["cancellation_reason"] = ""
            } else {
                tempPaymentModeFormData["cancellation_reason"] = e.target.value
                tempPaymentModeFormDataError['cancellation_reason'] = ""
            }
        }
        // if (type == "credit_notes_validity") {

        //     if (e != "" && e != null) {
        //         const currentDateTime = new Date();
        //         const selectedDateTime = new Date(e);
        //         // Check if the selected date has a time (hours, minutes, seconds)
        //         const hasTime = selectedDateTime.getHours() !== 0 || selectedDateTime.getMinutes() !== 0 || selectedDateTime.getSeconds() !== 0;
        //         if (!hasTime) {
        //             // If no time is selected, set it to the current time
        //             selectedDateTime.setHours(currentDateTime.getHours());
        //             selectedDateTime.setMinutes(currentDateTime.getMinutes());
        //             selectedDateTime.setSeconds(currentDateTime.getSeconds());
        //         }
        //         setSelectedCreditNoteValidityDateTime(selectedDateTime);
        //         tempPaymentModeFormData["credit_notes_validity"] = e
        //         tempPaymentModeFormDataError["credit_notes_validity"] = ""

        //     } else {
        //         tempPaymentModeFormData["credit_notes_validity"] = moment().add(1, 'year').toDate()
        //         tempPaymentModeFormDataError["credit_notes_validity"] = ""
        //     }

        // }

        // if (type == "screenshot") {
        //     const file = e.target.files[0];
        //     let targetFileSplit = e.target.files[0].name.split('.');
        //     let lastElement = targetFileSplit.pop();
        //     if (lastElement == 'JPEG' || lastElement == 'jpeg' || lastElement == 'jpg' || lastElement == 'JPG' || lastElement == 'png' || lastElement == 'PNG') {
        //         const fsize = e.target.files[0].size;
        //         const filesize = Math.round((fsize / 1024));
        //         if (filesize >= 300) {
        //             Utility.toastNotifications("File is too Big, please select a file less than 300kb", "Warning", "warning");
        //         } else {
        //             setUploadFile([file]);
        //             const reader = new FileReader();
        //             reader.onloadend = () => {
        //                 setAddPreviewImage([reader.result]);
        //             }
        //             if (file) {
        //                 setSelectedImageName(file.name);
        //                 reader.readAsDataURL(file);
        //             }
        //             setAddPreviewImageError("")
        //         }
        //     } else {
        //         Utility.toastNotifications("Please upload JPG ,JPEG or PNG file format", "Warning", "warning");
        //     }
        // }

        setPaymentModeFormData(tempPaymentModeFormData)
        setPaymentModeFormDataError(tempPaymentModeFormDataError)

    }

    useEffect(() => {
    }, [paymentModeFormData, paymentModeFormDataError, selectedPaymentModeData])

    const uploadScreenshotImage = (responseData) => {
        const { loaderStateTrue, loaderStateFalse } = props;

        let tempPaymentModeFormData = { ...paymentModeFormData };
        let tempPaymentModeFormDataError = { ...paymentModeFormDataError }

        let imageDetails = [{ payment_id: responseData && responseData.hasOwnProperty('data') && responseData.data.hasOwnProperty('result') && responseData.data.result.hasOwnProperty('id') ? responseData.data.result.id : "" }];
        const saveImageData = [
            {
                "type": 'payment_screensort',
                "payee_ac_holder_name": responseData && responseData.hasOwnProperty('data') && responseData.data.hasOwnProperty('result') && responseData.data.result.hasOwnProperty('payee_ac_holder_name') ? responseData.data.result.payee_ac_holder_name : "",
                "image_details": imageDetails
            }
        ];
        const formData = new FormData();
        formData.append('formData', JSON.stringify(saveImageData));
        formData.append('file', uploadFile[0])

        loaderStateTrue();
        uploadScreenshot(formData).then((response) => {
            if (response[0].success) {
                //setSerachFlag(true)
                setCancellationDetailsPopUpModalFlag(true);
                setCancellationDetailsPopUpData(responseData.data ? responseData.data : {})
                //bookingRoomList();
                setHotelBookingData([])
                setBookingRoomOffset(0)
                setBookingRoomLimit(3)
                cancellationModalHide()
                cancellationDetailsModalHide()
                resetPaymentModeFormData()
                loaderStateFalse();
                Utility.toastNotifications(responseData.message, "Success", "success")
            } else {
                loaderStateFalse();
                Utility.toastNotifications(response[0].message, "Error", "error")
            }
            //loaderStateFalse();
        }).catch((error) => {
            loaderStateFalse();
        });
    }

    //=========booking cancellation work end==============

    //=========Invoice work start=========================
    const createInvoiceFn = (roWData) => {
        setSerachFlag(false)
        viewBookingDetailsFn(roWData.id, "generateInvoice")
    }
    const closeInvoiceModalFn = () => {
        // setSerachFlag(true)
        // setBookingRoomOffset(0)
        // setBookingRoomLimit(3)
        // setHotelBookingData([])
        setCreateInvoiceModalFlag(false)
    }

    const textStyle = {
        color: selectedGridObj.book_status === "Yet to confirm" ? "red" : "inherit",
    };


    //=========Invoice work end=========================

    const homeMenuClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }
    const hotelListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/search`);
    }
    const hotelDetailsClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
    }
    const bookingListClick = () => {
        props.history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }

    useEffect(() => {
        breadcrumbUrlFn()
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    }, [])

    const breadcrumbUrlFn = () => {
        let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
        let newArray = breadcrumb.slice(0, breadcrumb.indexOf('booking_history') + 1);
        if (!breadcrumb.includes('booking_history')) {
            breadcrumb.push('booking_history')
            localStorage.setItem('pageArr', JSON.stringify(breadcrumb));
        } else {
            localStorage.setItem('pageArr', JSON.stringify(newArray));
        }
    }

    const handleChangeUserFilter = (type, event) => {
        if (type == 'status') {
            if (event) {
                if (event.value != selectedStatus.value) {
                    setSelectedStatus(event)
                    //setSearchBookingData("")
                    setBookingRoomOffset(0)
                    setBookingRoomLimit(3)
                    setHotelBookingData([])
                }
            } else {
                setSelectedStatus("")
                //setSearchBookingData("")
                setBookingRoomOffset(0)
                setBookingRoomLimit(3)
                setHotelBookingData([])
            }
            setStatusFilterFlag(true)
        }
    }

    useEffect(() => {
        if (selectedStatus != "" && selectedStatus.hasOwnProperty('value') && statusFilterFlag && !isGuestFlag) {
            // console.log("Entry================9");
            bookingRoomList();
        }
        if (selectedStatus == "" && statusFilterFlag && !isGuestFlag) {
            // console.log("Entry================10");
            bookingRoomList();
        }
        // console.log("selectedStatus================>", selectedStatus);

    }, [selectedStatus])

    useEffect(() => {
        if (!scrollEnabled) {
            document.body.style.overflow = 'hidden';
            document.body.className = 'bodyScroll bodyScrollSpace';
        } else {
            document.body.style.overflow = '';
            document.body.className = 'bodyScroll';
        }
    }, [scrollEnabled])

    return (
        <>
            <div className="booking-history-page">
                <div className='filterBoxCover'>
                    <div className='container'>
                        <div className='filterBox bookingHistory filterBoxMBottom'>

                            <div className='leftSectionFilter'>
                                <h2 className='pageTitle'>Booking History</h2>
                            </div>
                            <div className='rightSectionFilter'>
                                <div className="dropdownbox statusdropcontainer">
                                    <AutosuggestComponent
                                        handleOnChange={(e) => { handleChangeUserFilter('status', e) }}
                                        options={statusList}
                                        selectedValue={selectedStatus}
                                        name=''
                                        isMulti={false}
                                        placeholder="Status"
                                        isDisabled={false}
                                        isClearable={true}
                                        closeButton={false}
                                        menuHeader="Status"
                                        isSearchable={true}
                                    />
                                </div>
                                <div className='search-input'>
                                    <div className="pageinnersearch searchBox">
                                        <button type="button" className="btn btn-link search_btn_addon">
                                            <img className="searchicon" src={require('../../../../Utility/Public/images/searchicon1.png')} />
                                        </button>
                                        <DebounceInput
                                            minLength={2}
                                            debounceTimeout={500}
                                            onChange={(e) => handleSearchValue(e)}
                                            placeholder="Search Booking ID"
                                            value={searchBookingData}
                                        />
                                        {searchBookingData != "" ?
                                            < button type="button" className="btn btn-link dropclosebtn"
                                                onClick={clearSearchValue}
                                            >
                                                {/* <img src={require('../../../../Utility/Public/images/dropcloseicon1.png')} className="searchClearIcon" /> */}
                                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg searchClearIcon"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                                            </button>
                                            : null}
                                    </div>
                                </div>
                                <div className='search-range'>
                                    <ErrorBoundary title="CommonDateTimePicker Error">
                                        <img className="ico ico-date" src={require('../../../../Utility/Public/images/calendar-outline1.png')} />
                                        <CommonDateTimePicker
                                            selected={bookingStartDate}
                                            onChange={(e) => onChangeDateRange(e)}
                                            showTimeSelect={false}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="Select Date Range"
                                            startDate={bookingStartDate}
                                            endDate={bookingEndDate}
                                            minDate=""
                                            //minTime={props.startDateminTime}
                                            // maxTime={props.startDatemaxTime}
                                            dateRange={true}
                                            selectsRange={true}
                                            isClearable={true}
                                        />
                                        {/* <div className="col-md-12 errorClass error_div">{props.roomBookErrorFormData.start_date}</div> */}
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='container'>
                    <div className='paddingL0'>
                        <BreadcrumbMenu
                            homeMenuDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('home') ? true : false}
                            hotelListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('search') ? true : false}
                            hotelDetailsDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('hotel_details') ? true : false}
                            bookingListDisplay={JSON.parse(localStorage.getItem('pageArr')).includes('booking_history') ? true : false}
                            homeMenuClick={homeMenuClick}
                            hotelListClick={hotelListClick}
                            hotelDetailsClick={hotelDetailsClick}
                            bookingListClick={bookingListClick}
                            activeBookingList={true}
                        />
                    </div>
                    <div className="bookingHistoryContainer"
                    // style={{ }}
                    >
                        <div id="scrollableDiv" className='bookingHistoryContainerInner' >
                            <InfiniteScroll
                                dataLength={hotelBookingData.length}
                                next={getNextData}
                                hasMore={hasMoreBookingRoomList}
                                //loader={<div className="text-center"><ScaleLoader className="fadeloader_property" color="#45c1ff" size={40} /></div>}
                                scrollableTarget="scrollableDiv"
                            >
                                <div className='booking-history-boxes'>
                                    {hotelBookingDataUi()}

                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
            {/* {showPaymentModal ? <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={showPaymentModal}
                    onHide={closePaymentModal}
                    title="Payment"
                    className="modalcustomize mondimension partialPaymentModal"
                    footer={true}
                    closeButton={true}
                    saveShow={false}
                    cancelShow={true}
                    cancelButtonLabel="Cancel"
                    cancelButtonClassName="cancel-btn"
                    onCancel={closePaymentModal}
                    body={
                        <>
                            <ErrorBoundary title="PartialPaymentModal Error">
                                <PartialPaymentModal bookingDetails={userDetailsData} closePaymentModal={closePaymentModal} />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary> : ""} */}

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={reviewRatingModalFlag}
                    onHide={reviewRatingModalCloseFn}
                    title="Review"
                    className="modalcustomize mondimension customerReviewModal"
                    footer={false}
                    closeButton={true}
                    saveShow={false}
                    cancelShow={true}
                    cancelButtonLabel="Cancel"
                    cancelButtonClassName="cancel-btn"
                    onCancel={reviewRatingModalCloseFn}
                    body={
                        <>
                            <ErrorBoundary title="PartialPaymentModal Error">
                                <ReviewRating
                                    reviewPostSuccessFn={reviewPostSuccessFn}
                                    selectedGridObj={selectedGridObj}
                                    isEdit={selectedGridObj.hasOwnProperty('BookingMasterRatingRelation') && selectedGridObj.BookingMasterRatingRelation && selectedGridObj.BookingMasterRatingRelation.length == 0 ? false : true}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={viewBookingDetailsModalFlag}
                    onHide={closeViewBookingDetails}
                    title={selectedGridObj.book_status === "Yet to confirm" ? <div>Booking Details <span className='yet_to_confirm'>(Yet to confirm)</span></div> : <div>Booking Details</div>}
                    className="modalcustomize mondimension viewBookingDetailsModal"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Confirm"
                    saveButtonClassName="btn btn-primary savebtn"
                    cancelShow={true}
                    body={
                        <>
                            <ErrorBoundary title="ViewBookingDetails Error">
                                <ViewBookingDetails
                                    viewDetailsData={viewDetailsData}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={bookingCancellationModalFlag}
                    onHide={cancellationModalHide}
                    className="modalcustomize confirmationalertmodal"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Cancel This Booking ?"
                    footer={false}
                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <ConfirmationAlert
                                    BodyFirstContent="Are you sure, you want to cancel the booking?"
                                    BodyThirdContent="This action cannot be reversable."
                                    confirmationButtonContent="Confirm"
                                    cancelButtonContent="Cancel"
                                    deleteConfirmButton={bookingCancellationConfirmButton}
                                    deleteCancleButton={bookingCancellationCancleButton}
                                    deleteIconVissable={false}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={bookingCancellationDetailsModalFlag}
                    onHide={cancellationDetailsModalHide}
                    className="modalcustomize bookingCancellationDetails"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Booking Cancellation"
                    closeButton={true}
                    footer={true}
                    saveButtonLabel="Proceed"
                    cancelButtonLabel="Cancel"
                    saveButtonClassName="btn btn-primary modbtn savebtn"
                    onSave={bookingCancellationDetalsSave}
                    onCancel={cancellationDetailsModalHide}
                    cancelShow={false}
                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <BookingCancellation
                                    bookingDetailsData={bookingDetailsData}
                                    bookingRulesFlag={bookingRulesFlag}

                                    handelChangePaymentMode={handelChangePaymentMode}
                                    selectedPaymentModeData={selectedPaymentModeData}
                                    paymentModeFormData={paymentModeFormData}
                                    paymentModeFormDataError={paymentModeFormDataError}
                                    paymentModeList={paymentModeList}
                                    selectedDateTime={selectedDateTime}
                                    isToday={isToday}
                                    selectedCreditNoteValidityDateTime={selectedCreditNoteValidityDateTime}
                                    selectedImageName={selectedImageName}
                                    addPreviewImage={addPreviewImage}
                                    paymentModeRef={paymentModeRef}
                                    isUpiIdGiven={isUpiIdGiven}

                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            {/* <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={bookingCancellationDetailsModalFlag}
                    onHide={cancellationDetailsModalHide}
                    className="modalcustomize bookingCancellationDetails"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Booking Cancellation"
                    closeButton={true}
                    footer={true}
                    saveButtonLabel="Proceed"
                    cancelButtonLabel="Cancel"
                    saveButtonClassName="btn btn-primary modbtn savebtn"
                    onSave={bookingCancellationDetalsSave}
                    onCancel={cancellationDetailsModalHide}
                    cancelShow={false}
                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <BookingCancellationDetails
                                    bookingDetailsData={bookingDetailsData}
                                    handleChangeBookingRules={handleChangeBookingRules}
                                    bookingRulesFlag={bookingRulesFlag}
                                    cancellationAmount={cancellationAmount}
                                    cancellationAmountError={cancellationAmountError}
                                    handelChangePaymentMode={handelChangePaymentMode}
                                    selectedPaymentModeData={selectedPaymentModeData}
                                    paymentModeFormData={paymentModeFormData}
                                    paymentModeFormDataError={paymentModeFormDataError}
                                    paymentModeList={paymentModeList}
                                    selectedDateTime={selectedDateTime}
                                    selectedCreditNoteValidityDateTime={selectedCreditNoteValidityDateTime}
                                    selectedImageName={selectedImageName}
                                    addPreviewImage={addPreviewImage}
                                    paymentModeRef={paymentModeRef}
                                    isUpiIdGiven={isUpiIdGiven}
                                    totalAmountExAllTaxesFlag={totalAmountExAllTaxesFlag}
                                    reasonForCancellationRef={reasonForCancellationRef}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary> */}

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={createInvoiceModalFlag}
                    onHide={closeInvoiceModalFn}
                    title="Invoice"
                    className="modalcustomize mondimension invoiceModalContent"
                    footer={false}
                    closeButton={true}
                    onCancel={closeInvoiceModalFn}
                    cancelShow={true}

                    body={
                        <>
                            <ErrorBoundary title="GenerateInvoiceContent Error">
                                <GenerateInvoiceContent
                                    invoiceData={viewDetailsData}
                                //billingFromData={billingFromData}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            {paymentDetailsModalFlag && <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={paymentDetailsModalFlag}
                    onHide={paymentHistoryShowFlag ? closePaymentModalFunction : closePaymentModalFn}
                    title={bookingStatus == "Yet to confirm" ? <div>Payment <span className='yet_to_confirm'>(Yet to confirm)</span></div> : <div>Payment</div>}
                    className="modalcustomize mondimension paymentModalBX"
                    footer={false}
                    closeButton={true}
                    //saveButtonLabel="Add Payment"
                    //saveButtonClassName="savebtn modbtn"
                    //cancelShow={true}
                    //saveShow={true}
                    //onCancel={closePaymentModalFn}
                    //onSave={openPaymentModeModalFn}
                    cancelButtonLabel={"Cancel"}
                    cancelButtonClassName="modbtn cancelbtn"
                    footerClassName="text-center topspace"
                    body={
                        <>
                            <ErrorBoundary title="BranchAddEditModalContent Error">
                                <PaymentViewModalContent
                                    bookingId={userDetailsData.booking_id}
                                    bookingMasterId={userDetailsData.booking_master_id}
                                    customerId={userDetailsData.customer_id}
                                    closePaymentModalFn={paymentHistoryShowFlag ? closePaymentModalFunction : closePaymentModalFn}
                                    skipPaymentFn={skipPaymentFn}
                                    bookingStatus={bookingStatus == "Confirmed" || bookingStatus == "Checked-In" || bookingStatus == "Yet to confirm" ? true : false}
                                    paymentHistoryShowFlag={paymentHistoryShowFlag ? true : false}
                                    //paymentModeModalHeaderTitle={paymentModeModalHeaderTitle}
                                    OriginalbookingStatus={bookingStatus}
                                    paymentModalCallFrom="bookingHistory"
                                    bankInfoForPayments={userDetailsData}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>}

        </>
    )
}



const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withNamespaces()(BookingHistory));

