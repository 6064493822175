import React, { useState, useEffect, useRef } from 'react';
import '../Assets/css/logindoc.scss';
import '../Assets/css/loginresponsivedoc.scss';
import { connect } from 'react-redux';
import { getfetchApiKey } from '../../Login/Controller/LoginController'
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
const AppLandingPage = (props) => {

	const [state, setState] = useState({

	})
	const setLanguage = () => {

		props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
	}
	useEffect(() => {
		//console.log("Entry app landing page")
		localStorage.setItem('api_key', "");
		setLanguage();
	}, [state.loginCredentials]);



	// useEffect(() => {
	// 	getApiKey().then((res) => {
	// 		if (res.success) {
	// 			localStorage.setItem('api_key', res.api_key);
	// 			setLanguage()
	// 		}
	// 	})
	// }, []);

	const getApiKey = () => {
		const domain = window.location.host;
		const { loaderStateTrue, loaderStateFalse } = props;
		return new Promise((resolve, reject) => {


			loaderStateTrue();
			//console.log("Entry function")
			let data = {
				"domain": domain
			}

			getfetchApiKey(data).then((response) => {
				if (response.success) {
					resolve(response)
					//setLanguage()
				} else {
					reject(response);
				}
				loaderStateFalse();
			}).catch((error) => {
				reject(error);
				loaderStateFalse();

			});

		})


	}

	return (
		<></>
	);
}


//export default (withRouter(AppLandingPage));

const mapStateToProps = (globalState) => {
	// console.log("globalState=========applanding page", globalState)
	return {
		token: globalState.LoginReducer.token
	};
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })(AppLandingPage);


