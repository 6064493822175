import React, { Component, lazy, Suspense } from 'react';
import { createHashHistory } from 'history';
import { Routes, Route, Switch, Redirect } from "react-router-dom";

import HomeRoute from "../Modules/Home/Routes/HomeRoute";
import PaymentRoute from "../Modules/Payment/Routes/PaymentRoute";
import LoginRoute from "../../GlobalModules/Login/Routes/LoginRoute";
import BookingRoute from "../Modules/Booking/Routes/BookingRoute";
import { connect } from 'react-redux';
import { setToken } from '../../GlobalModules/Login/Actions/LoginAction';
import { withNamespaces } from 'react-i18next';
import ProfileRoute from "../../GlobalModules/UserProfile/Routes/ProfileRoute";
import FooterListRoute from "../../GlobalModules/FooterList/Routes/FooterListRoute";


class index extends Component {
  render() {

    const { isLoggedIn, userCredentials } = this.props;
   // console.log("userCredentials=======", userCredentials)

    return (

      <Switch>       

        <Route exact path="/" component={HomeRoute} /> 

        <Route
          path="/:lng/home"
          render={(routeProps) => (
            <HomeRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />
        <Route
          path="/:lng/search"
          render={(routeProps) => (
            <HomeRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />

        <Route exact path="/:lng/notfound" component={HomeRoute} />

        <Route exact path="/:lng/login/:type?" component={LoginRoute} />
        <Route exact path="/:lng/forgot_password/:type?" component={LoginRoute} />
        <Route exact path="/:lng/register/:type?" component={LoginRoute} />
        <Route exact path="/:lng/comingsoon" component={HomeRoute} />

        <Route
          path="/:lng/manage_account"
          render={(routeProps) => (
            <ProfileRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />

        <Route
          path="/:lng/change_password"
          render={(routeProps) => (
            <ProfileRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />

        <Route
          path="/:lng/booking_history/:customer_type?"
          render={(routeProps) => (
            <BookingRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />
        <Route
          path="/:lng/booking_details"
          render={(routeProps) => (
            <BookingRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />

        <Route
          path="/:lng/hotel_details"
          render={(routeProps) => (
            <HomeRoute {...routeProps} isLoggedIn={isLoggedIn} />
          )}
        />

        <Route
          path="/:lng/about_us"
          render={(routeProps) => (
            <FooterListRoute {...routeProps} />
          )}
        />
         <Route
          path="/:lng/terms_Conditions"
          render={(routeProps) => (
            <FooterListRoute {...routeProps} />
          )}
        />
        <Route
          path="/:lng/faq"
          render={(routeProps) => (
            <FooterListRoute {...routeProps} />
          )}
        />
        <Route
          path="/:lng/privacy_policy"
          render={(routeProps) => (
            <FooterListRoute {...routeProps} />
          )}
        />
        <Route
          path="/:lng/sitemap"
          render={(routeProps) => (
            <FooterListRoute {...routeProps} />
          )}
        />
        <Route
          path="/:lng/credit_note"
          render={(routeProps) => (
            <BookingRoute {...routeProps} />
          )}
        />
        <Route
          path="*"
          render={() => {
            const lng = localStorage.getItem('i18nextLng') || 'en';
            return <Redirect to={`/${lng}/notfound`} />;
          }}
        />
        <Route
          path="*"
          render={() => {
            const lng = localStorage.getItem('i18nextLng') || 'en';
            return <Redirect to={`/${lng}/comingsoon`} />;
          }}
        />

      </Switch>


    );
  }
}
// export default index;

const mapStateToProps = (globalState) => {
  //console.log("globalState.LoginReducer.token>>>>>", globalState.LoginReducer.token);
  return {
    userCredentials: globalState.LoginReducer.userCredentials,
    token: globalState.LoginReducer.token,
    isLoggedIn: globalState.LoginReducer.token !== '',

  };
}

export default connect(mapStateToProps, { setToken })(withNamespaces()(index));