export const hotelRoomTypesSearchListGet = (data) => {
    return data.data;
}

export const destinationListGet = (data) => {
    return data.data;
}
export const filterPanelListGet = (data) => {
    return data.data;
}
export const paymentPriceDistributionGet = (data) => {
    return data.data;
}
export const paymentCreatePost = (data) => {
    return data.data;
}
export const paymentCreatePatch = (data) => {
    return data.data;
}
export const uploadScreenshotPost = (data) => {
    return data.data;
}
export const creditPointGet = (data) => {
    return data.data;
}
export const subscribApiCallFn = (data) => {
    return data.data;
}
export const getPaymentDetailsGet = (data) => {
    return data.data;
}
export const paymentDetailsDeleteApi = (data) => {
    return data.data;
}
export const guestCreateApi = (data) => {
    return data.data;
}
export const emailCheckApiGet = (data) => {
    return data.data;
}
export const signupCustomerApi = (data) => {
    return data.data;
}


