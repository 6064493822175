import React from 'react';
import { get, post, put, del, patch } from '../Http';
import { checkLiveOrNotGet, customerWorkStatusSave } from '../Model/UtilityModel';
export const checkLiveOrNot = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}check/live`, data);
    return checkLiveOrNotGet(response);
};
export const customerWorkStatus = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}customer/visitor`, data);
    return customerWorkStatusSave(response);
};

