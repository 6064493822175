import * as Store from '../store'
import LoginActionTypes from '../GlobalModules/Login/Utility/LoginActionTypes';
//import AllActionTypes from './AllActionTypes'
import axios from 'axios';
import LoginUtility from '../GlobalModules/Login/Utility/LoginUtility'
import Utility from './Utility'
import { createHashHistory } from 'history';
import moment from 'moment';

export const get = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		var params = data ? data.filters : {};
		let invokeError = true;
		if (typeof params == "string") {
			params = JSON.parse(params)
			if (params.size_width && params.size_height) invokeError = false;
		}
		axios.get(apiBaseUrl, {
			params: data,
			headers: getHeaders(header, url)
		}).then((response) => {
			//console.log("response====>111111111111111111111111",response)
			resolve(response);
		}).catch((error) => {
			//console.log("error====>111111111111111111111111",error.response)
			reject(error);
			if (invokeError) {
				errorHandlingBlock(error)
			}
		})
	});
}
export const post = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.post(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}
export const patch = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.patch(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}
export const put = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.put(apiBaseUrl, data, { headers: getHeaders(header, url) }).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}
export const del = (url, data, header = "global") => {

	//console.log("header===============del",header)

	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.delete(apiBaseUrl, {
			data: data,
			headers: getHeaders(header, url)
		}).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}

export const download = (url, data, header = "global") => {
	return new Promise((resolve, reject) => {
		let apiBaseUrl = `${process.env.BASE_URL}${url}`;
		axios.get(apiBaseUrl, {
			params: data,
			headers: getHeaders(header, url),
			responseType: 'arraybuffer'
		}).then((response) => {
			resolve(response);
		}).catch((error) => {
			reject(error);
			errorHandlingBlock(error)
		})
	});
}



export const getHeaders = (header, path = "") => {
	// console.log("Store.default.getState().LoginReducer-------->", Store.default.getState().LoginReducer)
	checkExpiryOfToken()
	let headers = {};
	headers['language'] = localStorage.getItem('i18nextLng');
	headers['api_key'] = localStorage.getItem('api_key');
	// headers['user_type'] = 'customer';
	if (header == null) {
		// console.log("entry-------->1")
		headers['language'] = localStorage.getItem('i18nextLng');
	} else if (header == "global") {
		// console.log("entry-------->2")
		if (path == `${process.env.EXTENDED_URL}users/mfa/verify`) {
			headers["Session"] = Store.default.getState().LoginReducer.session
		} else if (path == `${process.env.EXTENDED_URL}users/usr_mfa_assign`) {
            
		} else if(path == `${process.env.EXTENDED_URL}users/associate_software_token`){
            headers["AccessToken"] = Store.default.getState().LoginReducer.accessToken;
		}else if(path == `${process.env.EXTENDED_URL}users/verify_software_token`){
			headers["AccessToken"] = Store.default.getState().LoginReducer.accessToken;
		}else {
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
		}
		// console.log("entry-------->5")
	} else if (Object.keys(header).length > 0) {
		// console.log("entry-------->6")
		if (path == `${process.env.EXTENDED_URL}users/userforcepasswordchange`) {
			Object.keys(header).map((key, idx) => {
				headers[key] = header[key];
			})
			// console.log("entry-------->7")
		} else if (path == `${process.env.EXTENDED_URL}users/signout`) {
			// console.log("entry-------->8")
			localStorage.setItem('i18nextLng', 'en');
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
			headers["Accesstoken"] = Store.default.getState().LoginReducer.accessToken;
		} else if (path == `${process.env.EXTENDED_URL}users/changepassword`) {
			// console.log("entry-------->9")
			headers["Authorization"] = Store.default.getState().LoginReducer.token;
			headers["Accesstoken"] = Store.default.getState().LoginReducer.accessToken;
		}
	}
	return headers
};

export const checkExpiryOfToken = () => {
	let currentDateTime = new Date();
	const expiryTime = new Date(Store.default.getState().LoginReducer.expiresIn.expiryTime)
	const loggedInTime = new Date(Store.default.getState().LoginReducer.expiresIn.loggedInTime)
	const expiryInterval = Store.default.getState().LoginReducer.expiresIn.expiryInterval

	// console.log("logut=========expiryTime===", expiryTime)
	// console.log("logut=========loggedInTime===", loggedInTime)
	// console.log("logut=========expiryInterval===", expiryInterval)
	// console.log("logut=========currentDateTime===", currentDateTime)

	if (expiryTime != "") {
		let deltaDifference = ((currentDateTime == "" ? 0 : currentDateTime) - (loggedInTime == "" ? 0 : loggedInTime)) / 1000
		if (currentDateTime >= expiryTime && (deltaDifference <= expiryInterval)) {
			refershToken();
		}
	}
};

export const refershToken = () => {
	//console.log("Call refershToken===============>>>")
	const history = createHashHistory();
	let res = new Promise((resolve, reject) => {
		let header = {};
		header["Authorization"] = Store.default.getState().LoginReducer.token;
		let apiBaseUrl = `${process.env.BASE_URL}${process.env.EXTENDED_URL}users/refreshtoken`;
		let data = {}
		data["refreshToken"] = Store.default.getState().LoginReducer.refreshToken;
		axios.patch(apiBaseUrl, data, { headers: header }).then((response) => {
			resolve(response);
		}).catch((error) => {
			//console.log("error=============", error)
			logoutApp()
			//reject(error);
		})
	});
	res.then((result) => {
		//console.log("result=============refresh token", result);

		let finalResponse = result.data
		if (finalResponse.success) {
			const finalIdToken = finalResponse.data.tokenType + ' ' + finalResponse.data.idToken;
			const accessToken = finalResponse.data.accessToken
			const expiresIn = LoginUtility.getExpiryDetails(finalResponse.data.expiresIn)

			Store.default.dispatch({
				type: LoginActionTypes.SET_TOKEN,
				payload: finalIdToken
			});
			Store.default.dispatch({
				type: LoginActionTypes.SET_ACCESS_TOKEN,
				payload: accessToken
			});
			Store.default.dispatch({
				type: LoginActionTypes.SET_TOKEN_EXPIRE_TIME,
				payload: expiresIn
			});

		} else {
			Utility.toastNotifications("Oops session has been expired!!!", "Error", "error")
			logoutApp()
		}
	})
}


// export const refershToken = () => {
// 	//console.log("Call refershToken===============>>>")
// 	const history = createHashHistory();
// 	let res = new Promise((resolve, reject) => {
// 		let header = {};
// 		header["Authorization"] = Store.default.getState().LoginReducer.token;
// 		let apiBaseUrl = `${process.env.BASE_URL}${process.env.EXTENDED_URL}auth/users/refreshtoken`;
// 		let data = {}
// 		data["refreshToken"] = Store.default.getState().LoginReducer.refreshToken;
// 		axios.patch(apiBaseUrl, data, { headers: header }).then((response) => {
// 			resolve(response);
// 		}).catch((error) => {
// 			//console.log("error=============", error)
// 			logoutApp()
// 			//reject(error);
// 		})
// 	});
// 	res.then((result) => {
// 		let finalResponse = result.data
// 		if (finalResponse.success) {
// 			const finalIdToken = finalResponse.data.tokenType + ' ' + finalResponse.data.idToken;
// 			const accessToken = finalResponse.data.accessToken
// 			const expiresIn = LoginUtility.getExpiryDetails(finalResponse.data.expiresIn)
// 			Store.default.dispatch({
// 				type: LoginActionTypes.SET_TOKEN,
// 				payload: finalIdToken
// 			});
// 			Store.default.dispatch({
// 				type: LoginActionTypes.SET_ACCESS_TOKEN,
// 				payload: accessToken
// 			});
// 			Store.default.dispatch({
// 				type: LoginActionTypes.SET_TOKEN_EXPIRE_TIME,
// 				payload: expiresIn
// 			});
// 		} else {
// 			Utility.toastNotifications("Oops session has been expired!!!", "Error", "error")
// 			logoutApp()
// 		}
// 	})

// };
export const logoutApp = async () => {
	localStorage.setItem('i18nextLng', 'en');
	const history = createHashHistory();

	try {
		const header = {
			"Authorization": Store.default.getState().LoginReducer.token,
			"Accesstoken": Store.default.getState().LoginReducer.accessToken
		};

		const apiBaseUrl = `${process.env.BASE_URL}${process.env.EXTENDED_URL}users/signout`;

		await axios.delete(apiBaseUrl, {
			data: {},
			headers: header
		});

		Store.default.dispatch({
			type: LoginActionTypes.LOGOUT,
			payload: { "token": "", "accessToken": "", "refreshToken": "", "expiresIn": { "loggedInTime": "", "expiryTime": "", "expiryInterval": "" }, "userCredentials": {}, version: [], "roleWisePermission": {}, "session": "" }
		});

		Store.default.dispatch({
			type: AllActionTypes.CLEAR_DATA,
			payload: { "loaderState": false, "leftbar": false, "activeLink": { 'accName': "", 'activeClass': "" }, "roleWisePermission": {} }
		});

		history.push(`/${localStorage.getItem('i18nextLng')}/home`);
	} catch (error) {
		// Handle error here
		console.error(error);
	}
};

export const errorHandlingBlock = (error) => {
	if (error.response) {
		if (error.response.status == 409) {
			if (Array.isArray(error.response.data)) {
				//error.response.data.map((value)=>{
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
				//})
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}
		if (error.response.status == 406) {
			if (Array.isArray(error.response.data)) {
				//error.response.data.map((value)=>{
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
				//})
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}
		if (error.response.status == 400) {
			if (Array.isArray(error.response.data)) {
				//error.response.data.map((value)=>{
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
				//})
			} else {
				if (error.response.data.message.indexOf("Invalid code or auth state for the user.") !== -1) {
					Utility.toastNotifications('Invalid code', "Error", "error");
				} else {
					Utility.toastNotifications(error.response.data.message, "Error", "error")
				}
				// Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}

		if (error.response.status == 404) {
			if (Array.isArray(error.response.data)) {
				//error.response.data.map((value)=>{
				Utility.toastNotifications(error.response.data[0].message, "Error", "error");
				//})
			} else {
				Utility.toastNotifications(error.response.data.message, "Error", "error")
			}
		}
		if (error.response.status == 401) {
			logoutApp()
			if (error.response.data.message == "The incoming token has expired") {
				Utility.toastNotifications("Oops session has been expired!!!", "Error", "error")
			}
		} else if (error.response.data) {
			Utility.toastNotifications(error.response.data.message, "Error", "error")
		}
	} else {
		Utility.toastNotifications(error.message, "Error", "error")
	}
}
export const setToken = (value) => {
	Store.default.dispatch({
		type: LoginActionTypes.SET_TOKEN,
		payload: value
	})
};
