import React from 'react';
import { get, post, put, del, patch } from '../../../../Utility/Http';
import {
    hotelRoomTypesSearchListGet,
    destinationListGet,
    filterPanelListGet,
    paymentPriceDistributionGet,
    paymentCreatePost,
    paymentCreatePatch,
    uploadScreenshotPost,
    creditPointGet,
    subscribApiCallFn,
    getPaymentDetailsGet,
    paymentDetailsDeleteApi,
    guestCreateApi,
    emailCheckApiGet,
    signupCustomerApi
} from '../Model/HomeModel';

export const hotelRoomTypesSearchList = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}customer/search`, data);
    return hotelRoomTypesSearchListGet(response);
};

export const destinationList = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}customer/location`, data);
    return destinationListGet(response);
};

export const filterPanelList = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}customer/filters`, data);
    return filterPanelListGet(response);
};
export const paymentPriceDistribution = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}customer/price_distribution`, data);
    return paymentPriceDistributionGet(response);
};


export const paymentCreate = (data, id, type) => {
    if (type == 'post') {
        return post(`${process.env.EXTENDED_URL}customer/payment`, data).then((response) => {
            return paymentCreatePost(response)
        });
    } else {
        return patch(`${process.env.EXTENDED_URL}customer/payment/${id}`, data).then((response) => {
            return paymentCreatePatch(response)
        });
    }
};
export const uploadScreenshot = (data) => {
    return post(`${process.env.EXTENDED_URL}customer/payment/upload_screensort`, data).then((response) => {
        return uploadScreenshotPost(response)
    });
};
export const getCreditPoint = (data) => {
    return get(`${process.env.EXTENDED_URL}customer/payment/get_credit_points`, data).then((response) => {
        return creditPointGet(response)
    });
};
export const subscribApiCall = async (data) => {
    const response = await post(`${process.env.EXTENDED_URL}subscription_details`, data);
    return subscribApiCallFn(response);
};
export const getPaymentDetails = (id, data) => {
    return get(`${process.env.EXTENDED_URL}customer/payment?booking_id=${id}`, data).then((response) => {
        return getPaymentDetailsGet(response)
    });
};
export const paymentDetailsDelete = (data) => {
    return del(`${process.env.EXTENDED_URL}customer/payment`, data).then((response) => {
        return paymentDetailsDeleteApi(response)
    });
};

export const guestCreate = async (data, headers) => {
    const response = await post(`${process.env.EXTENDED_URL}customer/create`, data, headers);
    return guestCreateApi(response);
};
export const emailCheckApi = async (data) => {
    const response = await get(`${process.env.EXTENDED_URL}customer/check_email`, data);
    return emailCheckApiGet(response);
};
export const signupCustomer = async (data, veryfiFlag) => {
    if (!veryfiFlag) {
        const response = await post(`${process.env.EXTENDED_URL}users/signup_customer`, data);
        return signupCustomerApi(response);
    }
    if (veryfiFlag) {
        const response = await post(`${process.env.EXTENDED_URL}users/confirm_signup`, data);
        return signupCustomerApi(response);
    }


};