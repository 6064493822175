import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Assets/css/footerListdoc.scss';
import '../Assets/css/footerListresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu';

function TermsConditions(props) {


    const history = useHistory();

    useEffect(() => {


    }, []);

    const homeMenuClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }


    return (

        <div className="aboutUsPage">
            <div className='footerBoxCover'>
                <div className='container'>
                    <div className="filterBox filterBoxMBottom">
                        <div className="leftSectionFilter">
                            <h2 className="pageTitle">Terms &amp; Conditions</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    t_c_Display={true}
                    homeMenuClick={homeMenuClick}
                    active_t_c={true}
                />

                <div className='aboutPageDetails'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <h4>Summary of The Staying</h4>
                                <p>Welcome to The Staying! These Terms & Conditions outline the rules and regulations for the use of The Staying Website.</p>
                                <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use The Staying if you do not agree to take all of the terms and conditions stated on this page.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">License</h5>
                                    <p className="card-text">Unless otherwise stated, The Staying and/or its licensors own the intellectual property rights for all material on The Staying Site. All intellectual property rights are reserved. </p>
                                    <br></br>
                                    <h5 className="card-title">You must not</h5>
                                    <p className="card-text">- Republish material from The Staying. </p>
                                    <p className="card-text">- Sell, rent or sub-license material from The Staying. </p>
                                    <p className="card-text">- Reproduce, duplicate or copy material from The Staying. </p>

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Reservation of Rights</h5>
                                    <p className="card-text">We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                                    <br></br>
                                    <h5 className="card-title">Removal of links from our website</h5>
                                    <p className="card-text">If you find any link on our Website that is offensive for any reason, you are.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <h4>Cookies</h4>
                                <p>We employ the use of cookies. By accessing The Staying, you agreed to use cookies in agreement with The Staying Privacy Policy.</p>
                                <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}





const mapStateToProps = (globalState) => {
    return {
        // userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse })
    (withNamespaces()(TermsConditions));