import React, { Component, lazy, Suspense } from 'react';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import Auth from "../../../../Layout/Auth";
import PaymentDetailsPage from '../Pages/PaymentDetailsPage';
import PaymentSuccess from '../Pages/PaymentSuccess';
import DecisionPage from '../Pages/DecisionPage';

function PaymentRoute() {
    const history = useHistory();
    //console.log("payment route history----",history);
    return (

        <Switch>
            <Route
                exact
                path="/:lng/payment/:paymentDetailsHash?"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <Suspense>
                            <PaymentDetailsPage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/payment_success/:userDetailsData?/:paymentDetailsData?/:paidAmount?"
                // path="/:lng/payment_success"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <Suspense>
                            <PaymentSuccess history={history} />
                        </Suspense>

                    </Auth>
                )}
            />
            <Route
                exact
                path="/:lng/decision/:hss?/:pss?/:sss?"
                render={() => (
                    <Auth history={history} allowedRoles={['customer']}>
                        <Suspense>
                            <DecisionPage history={history} />
                        </Suspense>

                    </Auth>
                )}
            />

        </Switch>
    );
}


export default PaymentRoute;
