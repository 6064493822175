import { get, post, put, del, patch } from '../../../../Utility/Http';
import { paymentDetailsApiGet, bookingListGet, reviewPostGet, reviewImagePostGet, viewBookingDetailsGet, bookingsIndividualDetailsGet, bookingCancellationApi, uploadScreenshotPost } from '../Model/BookingModel';


export const paymentDetailsApi = (id) => {
    return get(`${process.env.EXTENDED_URL}onpay/online_payment_detail/${id}`, null).then((response) => {
        return paymentDetailsApiGet(response)
    });
};

export const bookingList = (data) => {
    return get(`${process.env.EXTENDED_URL}customer/bookings`, data).then((response) => {
        return bookingListGet(response)
    });
};

export const reviewPost = (data) => {
    return post(`${process.env.EXTENDED_URL}customer/rating`, data).then((response) => {
        return reviewPostGet(response)
    });
};

export const reviewImagePost = (data) => {
    return post(`${process.env.EXTENDED_URL}customer/rating/upload_image`, data).then((response) => {
        return reviewImagePostGet(response)
    });
};

export const viewBookingDetailsApi = async (id, data) => {
    const response = await get(`${process.env.EXTENDED_URL}customer/bookings/individual/${id}/invoice`, data);
    return viewBookingDetailsGet(response);
};

export const bookingsIndividualDetails = (id, data) => {
    return get(`${process.env.EXTENDED_URL}customer/bookings/individual/${id}/cancelled`, data).then((response) => {
        return bookingsIndividualDetailsGet(response)
    });
};

export const bookingCancelled = (id, data) => {
    return patch(`${process.env.EXTENDED_URL}customer/bookings/individual_update/${id}`, data).then((response) => {
        return bookingCancellationApi(response)
    });
};

export const uploadScreenshot = (data) => {
    return post(`${process.env.EXTENDED_URL}payment/upload_screensort`, data).then((response) => {
        return uploadScreenshotPost(response)
    });
};