import React, { Component } from 'react';
import { logOutApp } from '../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../Utility/Public/css/common.scss';
import '../../Utility/Public/css/commonresponsive.scss';

class NotFound extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="not-found-container">
        <div className="containerBox">
          <h2>Oops!</h2>
          <h1 className="not-found-title"> 404 - Page Not Found</h1>
          <p className="not-found-description">
            The page you are looking for does not exist. Please check the URL or navigate back to the homepage.


          </p>
          {/* {this.props.token != "" ? */}
            <Link to={`/${localStorage.getItem('i18nextLng')}/home`} className="backToHome">Go to Home Page </Link>
            {/* : <Link to={`/${localStorage.getItem('i18nextLng')}/login`} className="backToHome">Go to Login </Link>} */}
        </div>
      </div>

    );
  }
}
//export default NotFound;

NotFound.defaultProps = {
  permission: true,
}

const mapStateToProps = (globalState) => {
  return {
    userCredentials: globalState.mainReducerData.userCredentials,
    token: globalState.LoginReducer.token,
  };
}

export default connect(mapStateToProps, { logOutApp })(NotFound);
