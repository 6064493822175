import React, { Component } from 'react';
function FancyBoxImage(props) {
    const handleNext = () => {
        const currentIndex = props.fancyimageList.indexOf(props.fancyBoxSelectedImg);
        const nextIndex = (currentIndex + 1) % props.fancyimageList.length;
        props.setFancyBoxSelectedImg(props.fancyimageList[nextIndex]);
    };

    const handlePrev = () => {
        const currentIndex = props.fancyimageList.indexOf(props.fancyBoxSelectedImg);
        const prevIndex = (currentIndex - 1 + props.fancyimageList.length) % props.fancyimageList.length;
        props.setFancyBoxSelectedImg(props.fancyimageList[prevIndex]);
    };
    return (
        <>
            <div className="fancyBoxImageBox">
                <img src={props.fancyBoxSelectedImg} className="logoInner" />
            </div>
            {props.fancyimageList.length > 0 &&
                <div className='fancyBoxNextPreviousBox'>
                    <span onClick={handlePrev} className='nextPreviousBtn previous'></span>
                    <span onClick={handleNext} className='nextPreviousBtn next'></span>
                </div>
            }
        </>
    );
}

FancyBoxImage.defaultProps = {
    fancyimageList: [],
};

export default FancyBoxImage;