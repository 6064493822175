export const createPaymentApiGet = (data) => {
    return data.data;
}

export const retrievePaymentIntentGet = (data) => {
    return data.data;
}
export const refundMoneyPost = (data) => {
    return data.data;
}
export const bookingsIndividualDetailsGet = (data) => {
    return data.data;
}

export const paymentDetailsApiGet = (data) => {
    return data.data;
}

export const paymentDetailsDataPost = (data) => {
    return data.data;
}
export const bookingListGet = (data) => {
    return data.data;
}
export const reviewPostGet = (data) => {
    return data.data;
}
export const reviewImagePostGet = (data) => {
    return data.data;
}
export const viewBookingDetailsGet = (data) => {
    return data.data;
}
export const bookingCancellationApi = (data) => {
    return data.data;
}
export const uploadScreenshotPost = (data) => {
    return data.data;
}






