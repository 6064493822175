import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const BreadcrumbMenu = ({ homeMenuDisplay, hotelListDisplay, hotelDetailsDisplay, homeMenuClick, hotelListClick, activeHome, activeHotelList, hotelDetailsClick, activeHotelDetails, bookingListDisplay, bookingListClick, activeBookingList, profileDisplay, activeProfile, profileMenuClick, activeChangePassword, changePasswordClick, changePasswordDisplay, aboutDisplay, activeAbout, active_t_c, t_c_Display, faq_Display, active_faq, PrivacyPolicy_Display, active_PrivacyPolicy, Sitemap_Display, active_Sitemap, creditNoteListDisplay, activeCreditNoteList, creditNoteClick  }) => {
    return (
        <>
            <Breadcrumb className='breadcrumbSec'>
                {homeMenuDisplay &&
                    <Breadcrumb.Item onClick={homeMenuClick} active={activeHome}>Home</Breadcrumb.Item>
                }
                {hotelListDisplay &&
                    <Breadcrumb.Item onClick={hotelListClick} active={activeHotelList}>Property List</Breadcrumb.Item>
                }
                {hotelDetailsDisplay &&
                    <Breadcrumb.Item onClick={hotelDetailsClick} active={activeHotelDetails}>Property Details</Breadcrumb.Item>
                }
                {bookingListDisplay &&
                    <Breadcrumb.Item onClick={bookingListClick} active={activeBookingList}>Booking History</Breadcrumb.Item>
                }
                {profileDisplay &&
                    <Breadcrumb.Item onClick={profileMenuClick} active={activeProfile}>Profile</Breadcrumb.Item>
                }
                {changePasswordDisplay &&
                    <Breadcrumb.Item onClick={changePasswordClick} active={activeChangePassword}>Change Password</Breadcrumb.Item>
                }
                 {aboutDisplay &&
                    <Breadcrumb.Item active={activeAbout}>About Us</Breadcrumb.Item>
                }
                {t_c_Display &&
                    <Breadcrumb.Item active={active_t_c}>Terms &amp; Conditions</Breadcrumb.Item>
                }
                 {faq_Display &&
                    <Breadcrumb.Item active={active_faq}>FAQ</Breadcrumb.Item>
                }
                  {PrivacyPolicy_Display &&
                    <Breadcrumb.Item active={active_PrivacyPolicy}>Privacy Policy</Breadcrumb.Item>
                }
                {Sitemap_Display &&
                    <Breadcrumb.Item active={active_Sitemap}>Sitemap</Breadcrumb.Item>
                }
                
                {creditNoteListDisplay &&
                    <Breadcrumb.Item onClick={creditNoteClick} active={activeCreditNoteList}>Credit Notes</Breadcrumb.Item>
                }
                
                
            </Breadcrumb>
        </>
    );
};

BreadcrumbMenu.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

BreadcrumbMenu.defaultProps = {
    homeMenuDisplay: false,
    hotelListDisplay: false,
    hotelDetailsDisplay: false,
    activeHome: false,
    activeHotelList: false,
    activeHotelDetails: false,
    activeBookingList: false,
    activeProfile: false,
    activeChangePassword: false,
    bookingListDisplay: false,
    profileDisplay: false,
    changePasswordDisplay: false,
    aboutDisplay: false,
    activeAbout: false,
    t_c_Display: false,
    active_t_c: false,
    faq_Display: false,
    active_faq: false,
    PrivacyPolicy_Display: false,
    active_PrivacyPolicy: false,
    Sitemap_Display: false,
    active_Sitemap: false,
    creditNoteListDisplay: false,
    activeCreditNoteList: false
}

export default BreadcrumbMenu;
