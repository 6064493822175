import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/profiledoc.scss';
import '../Assets/css/profileresponsivedoc.scss';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import SearchPanel from '../Components/SearchPanel';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import MultiRangeSlider from "../../../../Utility/Components/MultiRangeSlider";
// import Pagination from 'react-bootstrap/Pagination';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';

import Personal from '../Components/Personal/Personal';

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu'

function ManageAccount(props) {
  const [isViewportBottom, setIsViewportBottom] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!props.isLoggedIn) {
      history.push(`/${localStorage.getItem('i18nextLng')}/login`);
    } else {
      sessionStorage.setItem('previousUrl', `/${localStorage.getItem('i18nextLng')}/manage_account`);
    }
  }, [props.isLoggedIn]);

  const homeMenuClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/home`);
  }
  const profileMenuClick = () => {
    props.history.push(`/${localStorage.getItem('i18nextLng')}/manage_account`);
  }

  useEffect(() => {
    localStorage.setItem('pageArr', JSON.stringify([]))
    breadcrumbUrlFn()
  }, [])

  const breadcrumbUrlFn = () => {
    let breadcrumb = localStorage.getItem('pageArr') ? JSON.parse(localStorage.getItem('pageArr')) : [];
    if (!breadcrumb.includes('home')) {
      let pageArr = []
      pageArr.push('home')
      localStorage.setItem('pageArr', JSON.stringify(pageArr));
    }
  }

  return (
    <>
      <div className='container'>
        <BreadcrumbMenu
          homeMenuDisplay={true}
          profileDisplay={true}
          homeMenuClick={homeMenuClick}
          profileMenuClick={profileMenuClick}
          activeProfile={true}
        />
      </div>
      <div className='manage-account-page'>
        <div className='container'>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={3} md={0} className='tabButton'>
                <Nav variant="pills" className="flex-column tab-list-sec">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <span className='circle'><i className="fa fa-user" aria-hidden="true"></i></span><em>Personal Details</em></Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
              <Nav.Link eventKey="second">Tab 2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="three">Tab 3</Nav.Link>
            </Nav.Item> */}
                </Nav>
              </Col>
              <Col lg={9} md={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Personal />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="second">Second tab content</Tab.Pane> */}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </>
  );

}


export default ((ManageAccount));