import React from 'react';
// import { QRCode } from 'qrcode.react';  // Named import
import { QRCodeSVG } from 'qrcode.react';
const QRCodeGenerator = ({ secretCode, accountName, openAuthenticatorModalFn }) => {
    // console.log('QRCodeGenerator', secretCode, accountName);
    const otpauthUrl = `otpauth://totp/${encodeURIComponent(accountName)}?secret=${secretCode}&issuer=TheStaying`;

    return (
        <div>
            <h3 className='authenticatorText'>Scan the QR code using <span onClick={openAuthenticatorModalFn}>authenticator app</span> to generate a one-time passcode (TOTP). Once generated, enter the code in the input box below to complete the setup.</h3>
            {/* <QRCode value={otpauthUrl} size={256} level={"L"} includeMargin={true} /> */}
            <div className='QRCodeSVGContainer'>
                <QRCodeSVG value={otpauthUrl} />
            </div>
        </div>
    );
};

export default QRCodeGenerator;
