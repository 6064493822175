import React, { useState, useEffect } from 'react';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../../Utility/Public/css/common.scss';
import '../Assets/css/bookingdoc.scss';
import '../Assets/css/bookingresponsivedoc.scss';
import PartialPaymentModal from '../../../../Utility/Components/PaymentModal';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function BookingDetails(props) {
    const [bookingDetailsObj, setBookingDetailsObj] = useState({});
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [userDetailsData, setUserDetailsData] = useState({})

    useEffect(() => {
        getBookingDetails();
    }, [])
    const getBookingDetails = () => {
        setBookingDetailsObj({
            booking_id: "1704175634833642",
            booking_status: "Confirmed",
            hotel_image: "https://hotel-management-asset-uat.s3.ap-south-1.amazonaws.com/hotel_images/Hotel_60/Branch_72/27122023115620_Deluxe%20Double%20Room%20with%20Balcony%20and%20Sea%20View%20.jpg",
            hotel_name: "Hotel Royal Paradise",
            hotel_star: 3,
            checkin_date: "26-01-2024",
            checkin_time: "10:00 - 22:00",
            checkout_date: "27-01-2024",
            checkout_time: "6:00 - 10:00",
            total_amount: 2000,
            due_amount: 1000,
            room_price: 1985,
            service_tax: 125,
            room_type: "Super deluxe",
            breakfast: "yes",
            lunch: "no",
            currency: "inr",
            hotel_phone_no: "+91 8998569859",
            hotel_email: "hotel@yopmail.com",
            total_adults: 2,
            total_childs: 1,
            total_room: 1,
            address: "Digha Bypass Road",
            pincode: 721652,
            country: "India",
            room_type_img: "https://hotel-management-asset-uat.s3.ap-south-1.amazonaws.com/hotel_images/Hotel_60/Branch_72/27122023115620_Deluxe%20Double%20Room%20with%20Balcony%20and%20Sea%20View%20.jpg",
            customer_name: "Rahul Roy",
            facilities: "Private bathroom, Balcony, Terrace, City view, Shower, Safety deposit box, Bidet, Toilet, Sofa"
        });
    }

    const createStarIconFn = (hotel_star) => {
        let stars = [];
        for (let i = 0; i < hotel_star; i++) {
            stars.push(<i key={i} className='fa fa-star'></i>);
        }
        return stars;
    }

    const makePayment = (bookingObj) => {
        setShowPaymentModal(true);
        setUserDetailsData({
            currency: bookingObj.currency,
            due_amount: bookingObj.due_amount,
            total_amount: bookingObj.total_amount,
            booking_id: bookingObj.booking_id
        });
    }

    const closePaymentModal = () => {
        setShowPaymentModal(false);
    }

    const roomImg = {
        loop: true,
        center: true,
        items: 1,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 3000,
        //smartSpeed: 450,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };

    return (
        <div className="booking-details-page">
            <div className='container'>
                <div className='filterBox'>
                    <div className='leftSectionFilter'>
                        <h2 className='pageTitle'>Booking Details</h2>
                    </div>
                </div>
                <div className='booking-details-box'>
                    <div className='booking-details-inner'>
                        <div className='row'>
                            <div className="col-xl-3 col-md-4 col-sm-12">
                                <div className='detailsLeftPanel'>
                                    <div className='bookingidsSection'>
                                        <label>Booking ID: <span>{bookingDetailsObj.booking_id}</span></label>
                                    </div>
                                    <div className='bookingCancelationSection'>
                                        <div className='descriptionSection'>
                                            <h3>Is everything correct?</h3>
                                            <p>You can always view or change your booking online - no registration required.</p>
                                        </div>
                                        <div className='actionSection'>
                                            <ul>
                                                <li><button className='action-btn'><i className="fa fa-times-circle" aria-hidden="true"></i><span>Cancel your booking</span></button></li>
                                                {/* <li><button className='action-btn'><i className="fa fa-arrow-circle-up" aria-hidden="true"></i><span>Upgrade your room</span></button></li>
                                                <li><button className='action-btn'><i className="fa fa-calendar" aria-hidden="true"></i><span>Change dates</span></button></li> */}
                                                <li><button className='action-btn'><i className="fa fa-eye" aria-hidden="true"></i><span>View booking</span></button></li>
                                            </ul>
                                        </div>
                                        <div className='propertyContactSection'>
                                            <h3>Contact property</h3>
                                            <p>Phone : {bookingDetailsObj.hotel_phone_no}</p>
                                            <h3>Send an email </h3>
                                            <p>Email : {bookingDetailsObj.hotel_email}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-md-8 col-sm-12">
                                <div className='hotel-name'>
                                    <h3>{bookingDetailsObj.hotel_name} <span className="ratingStar">{createStarIconFn(bookingDetailsObj.hotel_star)}</span></h3>
                                </div>
                                <div className='detailsRightPanel'>
                                    <div className='booking-details-top-inner'>
                                        <div className='booking-details-top-left'>
                                            <div className='booking-date-section'>
                                                <div className='dateTimeIcon'>
                                                    <img className="icon" src={require('../../../../Utility/Public/images/calendar.png')} alt='Property' />
                                                </div>
                                                <div className='hotelCheckinTime'>
                                                    <div className='checkLabel'>Check-in</div>
                                                    <div className='dateLabel'>{bookingDetailsObj.checkin_date} <span className='timeLabel'>{bookingDetailsObj.checkin_time}</span></div>
                                                </div>
                                                <div className='hotelCheckoutTime'>
                                                    <div className='checkLabel'>Check-out</div>
                                                    <div className='dateLabel'>{bookingDetailsObj.checkout_date} <span className='timeLabel'>{bookingDetailsObj.checkout_time}</span></div>
                                                </div>
                                            </div>
                                            <div className="media locationSection common-box">
                                                <div className='iconBox'>
                                                    <img className="icon" src={require('../../../../Utility/Public/images/details.png')} alt='Property' />
                                                </div>
                                                <div className="media-body">
                                                    <h5 className="mt-0">Booking details</h5>
                                                    <p>{bookingDetailsObj.total_adults} adults {bookingDetailsObj.total_childs > 0 ? bookingDetailsObj.total_childs + " childs" : ""} - 1 night, {bookingDetailsObj.total_room} room</p>
                                                </div>
                                            </div>
                                            <div className="media locationSection common-box">
                                                <div className='iconBox'><img className="icon" src={require('../../../../Utility/Public/images/location.png')} /></div>
                                                <div className="media-body">
                                                    <h5 className="mt-0">Address</h5>
                                                    <p>{bookingDetailsObj.address}, {bookingDetailsObj.pincode}, {bookingDetailsObj.country}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='booking-details-top-right indx_sec1'>
                                            {/* <img src={bookingDetailsObj.hotel_image} alt='Hotel' className="room-img" /> */}
                                            <OwlCarousel
                                                className="owl-theme"
                                                {...roomImg}
                                            >
                                                <div className="item">
                                                    <div className="caro_Itm">
                                                        <div className="pic">
                                                            <div className="pic_Inn">
                                                                <img className="room-img" src="https://hotel-management-asset-uat.s3.ap-south-1.amazonaws.com/hotel_images/Hotel_4/Branch_2/08012024111516_meghalaya3.jpg" alt='Property' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="caro_Itm">
                                                        <div className="pic">
                                                            <div className="pic_Inn">
                                                                <img className="room-img" src="https://hotel-management-asset-uat.s3.ap-south-1.amazonaws.com/hotel_images/Hotel_4/Branch_2/08012024111516_meghalaya3.jpg" alt='Property' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                    <div className='room-details-section'>
                                        <div className='details-title'><h3>Your room details</h3></div>
                                        <div className="media room-type-image">
                                            <div className='room-type-img'><img src={bookingDetailsObj.room_type_img} alt='Room Type' className="room-img" /></div>
                                            <div className="media-body">
                                                <h5 className="mt-0">{bookingDetailsObj.room_type}</h5>
                                                <button className='action-btn'>Cancel your booking </button>
                                            </div>
                                        </div>
                                        <div className='room-type-details'>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Guest name</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>{bookingDetailsObj.customer_name}</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Maximum capacity</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span> 7 guests maximum, of which 4 adults maximum. Your total price is based on the rate for your booked number of guests (2 adults). Extra prices may apply for additional guests, up to the maximum capacity.</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Meal plan</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>There is no meal option with this room. </span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Smoking preference</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>Non-smoking room</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Facilities</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>{bookingDetailsObj.facilities}</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Children and beds</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>Child policies<br />
                                                        Children of any age are welcome.<br />
                                                        Children 18 years and above will be charged as adults at this property.<br />
                                                        Cot and extra bed policies<br />
                                                        No cots or extra beds are available.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='room-details-section price-details-section'>
                                        <div className='details-title'><h3>Final price details</h3></div>
                                        <div className='room-type-details'>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>1 room </span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>₹ {Number(bookingDetailsObj.room_price).toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span> Price <em>(for 2 guests)</em> </span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>  ₹ 1,549</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Additional charges</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>The price you see below is an approximate that may include fees based on the maximum occupancy. This can include taxes set by local governments or charges set by the property. </span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Goods & services tax</span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    <span>₹ {bookingDetailsObj.service_tax}</span>
                                                </div>
                                            </div>
                                            <div className='room-type-details-row final-price-row'>
                                                <div className='room-type-details-left'>
                                                    <span>Final price <em>(taxes included)</em> </span>
                                                </div>
                                                <div className='room-type-details-right'>
                                                    {(bookingDetailsObj.due_amount > 0 && !["Canceled"].includes(bookingDetailsObj.booking_status)) ? <button className='makepaymentBtn' onClick={(e) => { makePayment(bookingDetailsObj) }}>Make Payment</button> : ""}
                                                    <span>₹ {Number(bookingDetailsObj.total_amount).toLocaleString()}</span>
                                                </div>
                                            </div>
                                            <div className='additionalInfoBox'>
                                                <h4>Additional information</h4>
                                                <ul>
                                                    <li>
                                                        <strong> The final price shown is the amount you will pay to the property.</strong>
                                                        <p> Booking.com does not charge guests any reservation, administration or other fees. Your card issuer may charge you a foreign transaction fee.</p>
                                                    </li>
                                                    <li>
                                                        <strong> Payment information</strong>
                                                        <p>Property Swatika Inn Digha handles all payments.</p>
                                                        <p>This property accepts the following forms of payment: Cash only</p>
                                                    </li>
                                                    <li>
                                                        <strong>  Important information </strong>
                                                        <p>Please note that additional supplements (e.g. extra bed) are not added in this total.</p>
                                                        <p>If you cancel, applicable taxes may still be charged by the property.</p>
                                                        <p>If you don’t show up at this booking, and you don’t cancel beforehand, the property is liable to charge you the full reservation amount.</p>
                                                    </li>
                                                </ul>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showPaymentModal ? <PartialPaymentModal bookingDetails={userDetailsData} closePaymentModal={closePaymentModal} /> : ""
            }

        </div >
    )
}



const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(BookingDetails));