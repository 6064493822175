import React from 'react';

function CancellationDetailsModalContent(props) {
    const renderCancellationPolicy = () => {
        return props.viewCancellationData.map(policy => {
            let policyText = "";
            let refundElement = '';
            if (policy.deduct_percent != null && Number(policy.deduct_percent) < 100) {
                policyText = `Cancellations made within ${policy?.days_remaining} days of the booking will incur a deduction of ${policy?.deduct_percent}% of the total booking price${policy.cancellation_gst_percent != null ? `, plus applicable GST at ${policy.cancellation_gst_percent}%.` : '.'}`;
            }
            if (policy.deduct_percent != null && Number(policy.deduct_percent) === 100) {
                refundElement = <span > No refunds will be issued for cancellations made within {policy?.days_remaining} days of the booking. Incurring a deduction of 100% of the total booking price.</span>;
            }

            const className = refundElement ? 'noRefundTxt' : '';

            return (
                <li key={policy.id} style={{ fontSize: '18px', color: '#666', marginBottom: '10px' }} className={className}>
                    {policyText}
                    {refundElement}
                </li>
            );
        });
    };

    return (
        <div className='cancellationRuleBox'>
            <ul>{renderCancellationPolicy()}</ul>
        </div>
    );
}

export default CancellationDetailsModalContent;
