import { get, post, put, del, patch } from '../../../../Utility/Http';
import { paymentDetailsApiGet, createPaymentApiGet, confirmPaymentPost, retrievePaymentIntentGet, refundMoneyPost, verifyUserTokenGet, bookingsIndividualDetailsGet, paymentDetailsDataPost, paymentDetailsDataPatch } from '../Model/PaymentModel';


export const paymentDetailsApi = (id) => {
    return get(`${process.env.EXTENDED_URL}onpay/online_payment_detail/${id}`, null).then((response) => {
        return paymentDetailsApiGet(response)
    });
};

export const createPaymentApi = (data) => {
    return post(`${process.env.EXTENDED_URL}onpay/create_payment_intent`, data).then((response) => {
        return createPaymentApiGet(response)
    });
};

// export const confirmPayment = (data) => {
//     return post(`${process.env.EXTENDED_URL}onpay/confirmPayment`, data).then((response) => {
//         return confirmPaymentPost(response)
//     });
// };

export const retrievePaymentIntent = (data) => {
    return get(`${process.env.EXTENDED_URL}onpay/retrieve_payment_intent`, data).then((response) => {
        return retrievePaymentIntentGet(response)
    });
};

export const refundMoney = (data) => {
    return post(`${process.env.EXTENDED_URL}onpay/refund`, data).then((response) => {
        return refundMoneyPost(response)
    });
};

export const bookingsIndividualDetails = (id,data) => {
    return get(`${process.env.EXTENDED_URL}bookings/individual/${id}`, data).then((response) => {
        return bookingsIndividualDetailsGet(response)
    });
};

export const paymentDetailsDataSave = (data) => {
    return post(`${process.env.EXTENDED_URL}onpay/customer_payment`, data).then((response) => {
        return paymentDetailsDataPost(response)
    });
};




