// logger.js
import log from 'loglevel';
class Logger {
    log(message) {
        // console.log("message======", message);
        if (process.env.NODE_ENV === 'development') {
            log.info(message);
        }
    }

    debug(message) {
        // console.log("message======", message);
        if (process.env.NODE_ENV === 'development') {
            log.debug(message);
        }
    }

    warn(message) {
        if (process.env.NODE_ENV === 'development') {
            log.warn(message);
        }
    }

    // Add methods for info and error as needed
}

export default new Logger();
