import React, { useState, useEffect } from 'react';
//import { loadStripe } from '@stripe/stripe-js';
import { retrievePaymentIntent, refundMoney, bookingsIndividualDetails, paymentDetailsDataSave } from '../Controller/PaymentController';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Utility from '../../../../../src/Utility/Utility';

function PaymentSuccess(props) {
    const [paymentIntent, setpaymentIntent] = useState("");
    const [bookingDetailsData, setBookingDetailsData] = useState({});
    const [transactionId, setTransactionId] = useState("");
    const { userDetailsData, paymentDetailsData, paidAmount } = useParams();
    const [userDetailsDataFromParam, setUserDetailsDataFromParam] = useState({});
    const [paymentDetailsDataFromParam, setPaymentDetailsData] = useState({});
    const [paidAmountFromParam, setPaidAmountFromParam] = useState("");


    useEffect(() => {
       
        if (userDetailsData && paymentDetailsData && paidAmount) {

            let parsedUserDetailsHash = JSON.parse(atob(userDetailsData))
            setUserDetailsDataFromParam(parsedUserDetailsHash)

            let parsedPaymentDetailsHash = JSON.parse(atob(paymentDetailsData))
            setPaymentDetailsData(parsedPaymentDetailsHash)

            setPaidAmountFromParam(atob(paidAmount))
            
          }
    }, [])

    useEffect(() => {

        if(Object.keys(userDetailsDataFromParam).length>0 && Object.keys(paymentDetailsDataFromParam).length>0  && paidAmountFromParam!= "" ){
            checkStatus();
        }
       

    }, [userDetailsDataFromParam, paymentDetailsDataFromParam, paidAmountFromParam])

    async function checkStatus() {
        const { loaderStateTrue, loaderStateFalse } = props;
        const payment_intent = new URLSearchParams(window.location.search).get(
            "payment_intent"
        );
        setpaymentIntent(payment_intent);

        const payment_intent_clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );


        const userDetailsData = new URLSearchParams(window.location.search).get(
            "userDetailsData"
        );

        //console.log("userDetailsData=====", userDetailsData)


        //console.log("payment_intent=====", payment_intent)
        // console.log("payment_intent_clientSecret=====", payment_intent_clientSecret)
        // return false;

        if (!payment_intent) {
            return;
        }

        let data = {
            payment_intent,
            payment_intent_clientSecret
        }

        loaderStateTrue();
        retrievePaymentIntent(data).then((response) => {
            //console.log("response======", response)
            if(response.success){
                //setTransactionId(response.data.id)
                savePaymentDetailsData(response)
            }
            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
            }
            loaderStateFalse();
            if (error.message == "Network Error") {
            }
        });
    }


    const formatPaymentDetailsData= (response)=>{
        let dataSet= []
        let hash ={}

        hash['customer_id']= paymentDetailsDataFromParam.customer_id
        hash['first_name']= userDetailsDataFromParam.first_name
        hash['last_name']= userDetailsDataFromParam.last_name
        hash['contact_number']= userDetailsDataFromParam.contact
        hash['email']= userDetailsDataFromParam.email
        hash['user_type']=  "customer"
        hash['quotation_master_id']= paymentDetailsDataFromParam.quotation_master_id
        hash['amount']= atob(paidAmount)
        hash['payment_mode']= "stripe"
        hash['payment_type']= "online"
        hash['payment_status']= response.data.status
        hash['payment_ref_id']= response.data.id
        hash['payment_card_number']= ""
        hash['cheque_number']= ""
        hash['bank_name']= ""
        hash['payment_flow_type']= "cr"
        hash['booking_number']= paymentDetailsDataFromParam.booking_number
        hash['payment_json']=  JSON.stringify(response.data)
        //hash['online_payment_id']= atob(paymentID)
    
        dataSet.push(hash)
        return dataSet;
      }
    
      const savePaymentDetailsData = (response) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let dataset = formatPaymentDetailsData(response)
        //console.log("++++++++++dataset", dataset)

        loaderStateTrue()
        paymentDetailsDataSave(dataset).then((response) => {
          if (response[0].success) {
                Utility.toastNotifications(response[0].message, "Success", "success");
          }else{
            Utility.toastNotifications(response[0].message, "Error", "error");
          }
          loaderStateFalse();
        }).catch((error) => {
          console.error("************error*************", error)
          if (error) {
          }
          loaderStateFalse();
          if (error.message == "Network Error") {
          }
        });
      }

  
    const refundMoneyFunction = (amount = "") => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let data = {
            paymentIntentId: paymentIntent,
            amount: amount
        }
        loaderStateTrue();
        refundMoney(data).then((response) => {
            //console.log("response======", response)

            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
            }
            loaderStateFalse();
            if (error.message == "Network Error") {
            }
        });
    }

    const BookingDetails = (data) => (
        <section className="section sectionRoomDetails">
            <fieldset>
                <legend><h2 className='sectionDetails'>Booking Details</h2></legend>
                <p className='labelBox'><strong>Booking ID: </strong><span>{data.booking_id}</span></p>
                <p className='labelBox'><strong>Name:</strong><span>{data.first_name + " " + data.last_name}</span></p>
                <p className='labelBox'><strong>Email:</strong><span>{data.customer_email}</span></p>
                <p className='labelBox'><strong>Check-in Date:</strong><span>{data.checked_in}</span></p>
                <p className='labelBox'><strong>Check-out Date:</strong><span>{data.checked_out}</span></p>
                <p className='labelBox'><strong>Number of Adult:</strong> <span>{data.adult}</span></p>
                <p className='labelBox'><strong>Number of Children:</strong> <span>{data.child}</span></p>
            </fieldset>
        </section>
    );

    const RoomDetails = (data) => {
        let uiArry = []
        if (Object.keys(data.length > 0)) {
            let roomTypeBookingDetails = data.hasOwnProperty('room_type_booking_details') ? data.room_type_booking_details : {}
            Object.keys(roomTypeBookingDetails).map((value, i) => {
                uiArry.push(
                    <div key={i} className='roomTypeBox'>
                        <div className='roomTypeBoxInner'><strong>Room Type:</strong> <span>{roomTypeBookingDetails[value].room_type_name}</span></div>
                    </div>
                )
            })
        }
        return uiArry;
    }

    
    const AmountDetails = (data) => {
        let uiArry = []
        let totalAmount = data.total_amount != "" && data.total_amount != null ? parseFloat(data.total_amount).toFixed(2) : "";
        let cgstAmount = data.cgst_amount != "" && data.cgst_amount != null ? parseFloat(data.cgst_amount).toFixed(2) : ""
        let sgstAmount = data.sgst_amount != "" && data.sgst_amount != null ? parseFloat(data.sgst_amount).toFixed(2) : ""
        let igstAmount = data.igst_amount != "" && data.igst_amount != null ? parseFloat(data.igst_amount).toFixed(2) : ""
        let totalPaid = data.total_paid != "" && data.total_paid != null ? parseFloat(data.total_paid).toFixed(2) : ""

        uiArry.push(<section className="section sectionRoomDetails">
            <fieldset>
                <legend><h2 className='sectionDetails'>Amount Details</h2></legend>
                {totalAmount != "" &&
                    <p className='amountLabel'><strong>Total Amount: </strong><span><strong>{Utility.getCurrencySymbol(data?.currency)} {totalAmount}</strong></span></p>}
                {cgstAmount != "" &&
                    <p className='amountLabel gst'>CGST:<span>{Utility.getCurrencySymbol(data?.currency)} {cgstAmount}</span></p>
                }
                {sgstAmount != "" &&
                    <p className='amountLabel gst'>SGST:<span>{Utility.getCurrencySymbol(data?.currency)} {sgstAmount}</span></p>
                }
                {igstAmount != "" &&
                    <p className='amountLabel gst'>IGST:<span>{Utility.getCurrencySymbol(data?.currency)} {igstAmount}</span></p>}
                {totalPaid != "" &&
                    <p className='amountLabel amountLabelPaid'><strong>Total Paid: </strong><span><strong>{Utility.getCurrencySymbol(data?.currency)} {totalPaid}</strong></span></p>
                }

            </fieldset>
        </section>)
        return uiArry;
    };

    const PaymentSuccess = () => (
        <section className="section success">
            <h2 className='sectionDetails'>Payment Successful</h2>
            <p>Thank you for your payment. Your booking is confirmed.</p>
            <p><strong>Transaction ID:</strong>{transactionId}</p>
        </section>
    );

    // useEffect(() => {
    //     bookingsIndividualDetailsFn()
    // }, [bookingID])

    const bookingsIndividualDetailsFn = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();
        bookingsIndividualDetails(atob(bookingID)).then((response) => {
            //console.log("response=========>", response)
            setBookingDetailsData(response.data)
            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
                //Utility.toastNotifications(error.message, "Error", "error");
            }
            loaderStateFalse();
            if (error.message == "Network Error") {

            }
        });
    }



    return (
        <>
            {/* <div onClick={(e) => refundMoneyFunction()}>Refund</div>
            <div onClick={(e) => refundMoneyFunction(2000)}>Partial Refund</div>
            <div>PayentSuccess</div> */}
            <div className="booking-page confirmBookingPage">
                <div className='successIcon'>
                    <img src={require('../../../../Utility/Public/images/check.png')} />
                    <h1>Booking Confirmation</h1>
                </div>
                <main>
                    {BookingDetails(bookingDetailsData)}
                    <section className="section sectionRoomDetails roomTypeSection">
                        <fieldset className="">
                            <legend><h2 className='sectionDetails'>Room Details</h2></legend>
                            {RoomDetails(bookingDetailsData)}
                        </fieldset>
                    </section>
                    {AmountDetails(bookingDetailsData)}
                    {PaymentSuccess(bookingDetailsData)}
                </main>
            </div>
        </>
    )
}



//export default PaymentSuccess;

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(PaymentSuccess));