import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../Utility/Components/Header'
import Footer from '../Utility/Components/Footer'


const AuthLayout = (props) => {
  return (
    <>
      <div className="pageContainer">
        <Header history={props.history} />
        <div className="bodyContainer">{props.children}</div>
        <Footer />
      </div>
    </>
  );

}

export default (AuthLayout);