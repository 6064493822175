import React from 'react';
import { get, post, put, del, patch } from '../../../Utility/Http';
import { userDetailsGet, userDeleteGet, resetPasswordApi, usersGet, currentUser, userChangePasswordApi } from '../Model/ProfileModel';

export const user_Details = (id) => {
    return get(`${process.env.EXTENDED_URL}user_personal_info/${id}`).then((response) => {
    //return get(`${process.env.EXTENDED_URL}admin`).then((response) => {
        return userDetailsGet(response)
    });
};

export const userDelete = (data) => {
    return del(`${process.env.EXTENDED_URL}admin`, data).then((response) => {
        return userDeleteGet(response)
    });
};

export const resetPassword = (data) => {
    return patch(`${process.env.EXTENDED_URL}admin/resetpassword`, data).then((response) => {
        return resetPasswordApi(response)
    });
};

export const users = (data, id) => {
    return patch(`${process.env.EXTENDED_URL}admin/${id}`, data).then((response) => {
        return usersGet(response)
    });
};

export const getCurrentUser = () => {
    return get(`${process.env.EXTENDED_URL}users/currentuser`).then((response) => {
        return currentUser(response)
    })
};

export const userNewPasswordChange = (data) => {
    return patch(`${process.env.EXTENDED_URL}users/changepassword`, data,{passwordChange:true}).then((response) => {
        return userChangePasswordApi(response)
    });
    
};
