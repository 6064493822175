export const createPaymentApiGet = (data) => {
    return data.data;
}

export const retrievePaymentIntentGet = (data) => {
    return data.data;
}
export const refundMoneyPost = (data) => {
    return data.data;
}
export const bookingsIndividualDetailsGet = (data) => {
    return data.data;
}

export const paymentDetailsApiGet = (data) => {
    return data.data;
}

export const paymentDetailsDataPost = (data) => {
    return data.data;
}






