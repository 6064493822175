import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import CustomInput from '../../../../Utility/Components/CustomInput';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent';


function BookingPaymentDetails(props) {

    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension">
                    <div className='tableviewdoc paymentDetailsBoxes'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='cardBoxes'>
                                    <div className='paymentDetailsBox card'>
                                        <p>Total amount</p>
                                        <em>₹ {parseFloat(props.paymentDetailsFromParam.total_booking_amount).toFixed(2)}</em>
                                    </div>
                                    <div className='paymentDetailsBox card'>
                                        <p>Minimum amount <span>({parseFloat(props.paymentDetailsFromParam.minimum_percent).toFixed(2)}% of total amount)</span></p>
                                        <em>₹ {parseFloat(props.paymentDetailsFromParam.minimum_booking_amount).toFixed(2)}</em>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className='paymentDetailsBox'>
                                    <div className="dropdowninnerbox">
                                    <label>Payment type</label>
                                        <AutosuggestComponent
                                            //handleOnChange={(e) => handelChangePaymentType(e)}
                                            options={props.paymentTypeList}
                                            selectedValue={props.selectedPaymentType}
                                            name=''
                                            isMulti={false}
                                            placeholder=""
                                            isDisabled={true}
                                            isSearchable={true}
                                            menuPosition="fixed"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='paymentDetailsBox'>
                                    <label>Please choose the amount</label>
                                    <div className='frm-group authRadioBox'>
                                        <div className="edittext">
                                            <label className="checkboxcontainer">Minimum payment
                                                <input type="radio" name="payment" onClick={(e) => props.handelChangePaymentMode(e, "minimumPayment")} checked={props.paymentAmountType == "minimumPayment" ? true : false} />
                                                <span className="checkmark"></span>
                                            </label>

                                            <label className="checkboxcontainer">Full payment
                                                <input type="radio" name="payment" onClick={(e) => props.handelChangePaymentMode(e, "fullPayment")} checked={props.paymentAmountType == "fullPayment" ? true : false} />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="checkboxcontainer">Custom payment
                                                <input type="radio" name="payment" onClick={(e) => props.handelChangePaymentMode(e, "customPayment")} checked={props.paymentAmountType == "customPayment" ? true : false} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 errorClass error_div"></div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className='paymentDetailsBox'>
                                    <label>Paid amount (₹)</label>
                                    <ErrorBoundary title="CustomInput Error">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            //labelName="Paid amount"
                                            errorLabel={props.paidAmountError}
                                            name="paid_amount"
                                            type="text"
                                            value={props.paymentAmountType == "minimumPayment" || props.paymentAmountType == "fullPayment" ? parseFloat(props.paidAmount).toFixed(2) : props.paidAmount}
                                            labelPresent={false}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'paid_amount')}
                                            readOnly={props.paymentAmountType == "" || props.paymentAmountType == "minimumPayment" || props.paymentAmountType == "fullPayment" ? true : false}
                                        //placeholder={`Minumum payable amount ${props.minimumPayableAmount}`}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row footerSecForPaymentModal">
                <div className="col-md-12 text-center topspace">
                    <button type="button" className="modbtn" onClick={(e) => props.onSubmitBookingPaymentDetails()}>{"Payment"}</button>
                    
                </div>
            </div>
        </div>
    );
}

export default BookingPaymentDetails;
