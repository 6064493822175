import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import '../Assets/css/footerListdoc.scss';
import '../Assets/css/footerListresponsivedoc.scss';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../Actions/AllAction';
import { withNamespaces } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import BreadcrumbMenu from '../../../Utility/Components/BreadcrumbMenu';
import { setUserCredentials } from '../../../GlobalModules/Login/Actions/LoginAction';
function Sitemap(props) {


    const history = useHistory();

    useEffect(() => {


    }, []);

    const homeMenuClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/home`);
    }

    const aboutUs = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/about_us`);
    }
    const termsConditions = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/terms_Conditions`);
    }
    const faq = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/faq`);
    }
    const PrivacyPolicy = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/privacy_policy`);
    }
    const hotelListClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/search`);
    }
    const hotelDetailsClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/hotel_details`);
    }
    const bookingListClick = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }
    const manageAccount = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/manage_account`);
    }

    const changePassword = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/change_password`);
    }

    return (

        <div className="aboutUsPage">
            <div className='footerBoxCover'>
                <div className='container'>
                    <div className="filterBox filterBoxMBottom">
                        <div className="leftSectionFilter">
                            <h2 className="pageTitle">Sitemap</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <BreadcrumbMenu
                    homeMenuDisplay={true}
                    Sitemap_Display={true}
                    homeMenuClick={homeMenuClick}
                    active_Sitemap={true}
                />

                <div className='aboutPageDetails'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='aboutbox'>
                                <div className='siteMapBoxes'>
                                    <ul className="nav nav-list">
                                        <li><label className="tree-toggler nav-header">Customers info.</label>
                                            <ul className="nav nav-list tree">
                                                <li><button onClick={() => { homeMenuClick() }}>Home</button></li>
                                                <li><button onClick={() => { hotelListClick() }}>Property List</button></li>
                                                <li><button onClick={() => { hotelDetailsClick() }}>Property Details</button></li>
                                                {Object.keys(props.userCredentials).length > 0 &&
                                                    <li><button onClick={() => { bookingListClick() }}>Booking History</button></li>
                                                }
                                                <li><button onClick={() => { manageAccount() }}>Profile</button></li>
                                                {Object.keys(props.userCredentials).length > 0 &&
                                                    <li><button onClick={() => { changePassword() }}>Change Password</button></li>
                                                }
                                            </ul>
                                        </li>
                                        <li><label className="tree-toggler nav-header">Platform info.</label>
                                            <ul className="nav nav-list tree">
                                                <li><button onClick={() => { aboutUs() }}>About Us</button></li>
                                                <li><button onClick={() => { termsConditions() }}>Terms &amp; Conditions</button></li>
                                                <li><button onClick={() => { faq() }}>FAQ</button></li>
                                                <li><button onClick={() => { PrivacyPolicy() }}>Privacy Policy</button></li>

                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}





const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}


export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })
    (withNamespaces()(Sitemap));


