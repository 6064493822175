import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, handleActiveLink } from '../../../../Actions/AllAction';
import Utility from '../../../../Utility/Utility';
import moment from 'moment';
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary'
import CustomInput from '../../../../Utility/Components/CustomInput'
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent'
import CommonDateTimePicker from '../../../../Utility/Components/CommonDateTimePicker'

import { withNamespaces } from 'react-i18next';

function BookingCancellationDetails(props) {

    const getBeginningOfDay = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0);
    };

    //let amountRefunded = props.bookingDetailsData.hasOwnProperty('amount_refunded') && props.bookingDetailsData.amount_refunded


    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension">
                    <div className='paymentModeSectionInner'>
                            <div className="dropdowninnerbox">
                                <ErrorBoundary title="CustomInput Error">
                                    <label>Payment Mode <span>*</span></label>
                                    <AutosuggestComponent
                                        handleOnChange={(e) => props.handelChangePaymentMode(e, "payment_mode")}
                                        options={props.paymentModeList}
                                        selectedValue={props.selectedPaymentModeData}
                                        name=''
                                        isMulti={false}
                                        placeholder=""
                                        isSearchable={true}
                                        menuPosition="fixed"
                                    />
                                    <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.paymentModeData}</div>
                                </ErrorBoundary>
                            </div>
                        {props.selectedPaymentModeData.value == "online" &&
                            <div className='col-md-12'>
                                <div className="row">
                                    {/* <div className='col-md-4'>
                                        <ErrorBoundary title="CustomInput Error">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                //labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                labelName={"Amount"}
                                                errorLabel={props.paymentModeFormDataError.amount}
                                                name="amount"
                                                type="text"
                                                value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                labelPresent={true}
                                                requiredStar={true}
                                                isDisabled={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                            />
                                        </ErrorBoundary>
                                    </div> */}
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Account Holder Name"
                                            errorLabel={props.paymentModeFormDataError.account_holder_name}
                                            name="account_holder_name"
                                            type="text"
                                            value={props.paymentModeFormData.account_holder_name}
                                            labelPresent={true}
                                            requiredStar={props.isUpiIdGiven ? false : true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'account_holder_name')}
                                        />

                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Account Number"
                                            errorLabel={props.paymentModeFormDataError.account_number}
                                            name="account_number"
                                            type="text"
                                            value={props.paymentModeFormData.account_number}
                                            labelPresent={true}
                                            requiredStar={props.isUpiIdGiven ? false : true}
                                            onChange={(e) => { props.handelChangePaymentMode(e, "account_number") }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="IFSC Code"
                                            errorLabel={props.paymentModeFormDataError.ifsc_code}
                                            name="ifsc_code"
                                            type="text"
                                            value={props.paymentModeFormData.ifsc_code}
                                            labelPresent={true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'ifsc_code')}
                                            requiredStar={false}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Bank Name"
                                            errorLabel={props.paymentModeFormDataError.bank_name || ""}
                                            name="bank_name"
                                            type="text"
                                            value={props.paymentModeFormData.bank_name}
                                            labelPresent={true}
                                            requiredStar={props.isUpiIdGiven ? false : true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'bank_name')}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Branch Name"
                                            errorLabel={props.paymentModeFormDataError.branch_name}
                                            name="branch_name"
                                            type="text"
                                            value={props.paymentModeFormData.branch_name}
                                            labelPresent={true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'branch_name')}
                                            requiredStar={false}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="UPI Id"
                                            errorLabel={props.paymentModeFormDataError.upi_id}
                                            name="upi_id"
                                            type="text"
                                            value={props.paymentModeFormData.upi_id}
                                            labelPresent={true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'upi_id')}
                                            requiredStar={false}
                                        />
                                    </div>
                                    {/* <div className="col-md-4">
                                        <CustomInput
                                            parentClassName="input_field_inner"
                                            labelName="Reference Id"
                                            errorLabel={props.paymentModeFormDataError.reference_id}
                                            name="reference_id"
                                            type="text"
                                            value={props.paymentModeFormData.reference_id}
                                            labelPresent={true}
                                            requiredStar={true}
                                            onChange={(e) => props.handelChangePaymentMode(e, 'reference_id')}
                                        />
                                    </div> */}
                                    {/* <div className='col-md-4'>
                                        <div className='dropdowninnerbox cancellationDropPosition'>
                                            <label>Transaction Date & Time<span>*</span></label>
                                            <ErrorBoundary title="CommonDateTimePicker Error">
                                                <CommonDateTimePicker
                                                    dateRange={false}
                                                    selected={props.selectedDateTime}
                                                    onChange={(e) => props.handelChangePaymentMode(e, "transaction_date")}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={1}
                                                    dateFormat="dd-MM-yyyy HH:mm"
                                                    placeholderText="Select date"
                                                    minDate={new Date().setFullYear(new Date().getFullYear() - 1)}
                                                    maxDate={new Date()}
                                                    minTime={getBeginningOfDay(new Date())}
                                                    maxTime={props.isToday ? new Date() : new Date(0, 0, 0, 23, 59, 59)}
                                                />
                                                <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.transaction_date}</div>
                                            </ErrorBoundary>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-4 relativeBox">
                                        <div className="screenshotBoxes">
                                            <div className='input_field_inner screenshot'>
                                                <label className="input_label">Upload Screenshot </label>
                                                <div className="input_containers">
                                                    <div className="upload-btn-wrapper">
                                                        <button className="btn">Browse</button>
                                                        <input type="file" name="myfile"
                                                            onChange={(e) => props.handelChangePaymentMode(e, 'screenshot')}
                                                        />
                                                    </div>
                                                    <p>{props.selectedImageName ? props.selectedImageName : ""}</p>

                                                </div>
                                                <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.screenshot}</div>
                                            </div>
                                            <div className="canvas">
                                                <img src={props.addPreviewImage.length > 0 ? props.addPreviewImage[0] : require('../../../../Utility/Public/images/no-picture.jpg')} />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div ref={props.paymentModeRef}></div>
                            </div>
                        }
                    </div>
                    { props.selectedPaymentModeData.value == "credit_note" ?
                        <div className='creditNotesSection'>
                            <div className='col-md-12'>
                                {/* <div className='row'>
                                    <div className='col-md-6'>
                                        <ErrorBoundary title="CustomInput Error">
                                            <CustomInput
                                                parentClassName="input_field_inner"
                                                // labelName={"Amount (" + Utility.getCurrencySymbol(currency) + ")"}
                                                labelName={"Amount"}
                                                errorLabel={props.paymentModeFormDataError.amount}
                                                name="amount"
                                                type="text"
                                                value={!props.bookingRulesFlag ? parseFloat(withoutFlowCancellationPoliciesRefundedAmount).toFixed(2) : parseFloat(amountRefunded).toFixed(2)}
                                                labelPresent={true}
                                                requiredStar={true}
                                                isDisabled={true}
                                                onChange={(e) => props.handelChangePaymentMode(e, 'amount')}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='dropdowninnerbox cancellationDropPosition'>
                                            <label>Credit Note Valid Till <span>*</span></label>
                                            <ErrorBoundary title="CommonDateTimePicker Error">
                                                <CommonDateTimePicker
                                                    //dateRange={false}
                                                    selected={props.selectedCreditNoteValidityDateTime}
                                                    onChange={(e) => props.handelChangePaymentMode(e, "credit_notes_validity")}
                                                    showTimeSelect={false}
                                                    //timeFormat="HH:mm"
                                                    //timeIntervals={1}
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholderText="Select date"
                                                    minDate={new Date(moment.now())}
                                                />

                                                <div className="col-md-12 errorClass error_div">{props.paymentModeFormDataError.credit_notes_validity}</div>
                                            </ErrorBoundary>

                                        </div>
                                    </div>
                                </div>
                                <div className='credit-note-text'>
                                    Note: <span>The refunded amount will be converted into credit notes, and corresponding confirmation will be sent to you over the email.</span>
                                </div> */}
                                <div ref={props.paymentModeRef}></div>
                            </div>
                        </div>
                        : null}

                    <div className='col-md-12 cancellation_reason_box'>
                        <ErrorBoundary title="CustomInput Error">
                            <CustomInput
                                parentClassName="input_field_inner"
                                labelName="Reason for Cancellation"
                                errorLabel={props.paymentModeFormDataError.cancellation_reason}
                                name="cancellation_reason"
                                type="textarea"
                                value={props.paymentModeFormData.cancellation_reason}
                                labelPresent={true}
                                requiredStar={true}
                                onChange={(e) => props.handelChangePaymentMode(e, 'cancellation_reason')}
                            />
                        </ErrorBoundary>
                    </div>
                    <div ref={props.reasonForCancellationRef}></div>
                </div>
            </div >
        </div >
    );
};

const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token
    };
}

export default connect(mapStateToProps, { handleActiveLink, loaderStateTrue, loaderStateFalse })(withNamespaces()(BookingCancellationDetails));
