import React, { useState } from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'


function EmojiContent(props) {
    
    // console.log("props.showEmojiContent------------------>", props.showEmojiContent);
    return (
        <>
            {props.showEmojiContent ?
                <div>
                    <Picker data={data} onEmojiSelect={props.emojiSelect} />

                </div>
                : null
            }
        </>
    )
}

export default EmojiContent