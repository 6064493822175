export const userPersonalInfoGetApi = (data) => {
    return data.data;
}
export const personalDetailsUpdateApi = (data) => {
    return data.data;
}
export const userBasicInfoUpdatePatch = (data) => {
    return data.data;
}

export const currentUser = (currentUserData) => {
    let response = currentUserData.data;
    //console.log("currentUser response ===============>", response)
    let currentUserResData = {};
    if (response) {
        if (response.success) {
            currentUserResData = {
                "success": response.success,
                "data": {
                    "id": response.data.id,
                    //"is_leave_validator": response.data.id == 1 ? true : response.data.is_leave_validator,
                    //"is_trainer": response.data.is_trainer,
                    "user_details": {
                        "first_name": response.data.user_details.first_name,
                        "last_name": response.data.user_details.last_name,
                        "user_email": response.data.user_details.user_email,
                        "active": response.data.user_details.active,
                        "profile_img_url": response.data.user_details.profile_img_url,
                        "role_id": response.data.user_details.role_id,
                        "role_name": response.data.user_details.role_name.toLowerCase(),
                        "user_type": response.data.user_details.user_type,
                        "contact_number": response.data.user_details.contact_number,
                        //"org_id": response.data.user_details.org_id,
                        //"org_name": response.data.user_details.org_name,
                        //"org_logo": response.data.user_details.org_logo,
                        //"marketed_by_logo": response.data.user_details.marketed_by_logo,
                        //"marketed_by_name": response.data.user_details.marketed_by_name,
                        //"marketed_by_url": response.data.user_details.marketed_by_url,
                        //"secret_key": response.data.user_details.secret_key,
                        //"inbound_url": response.data.user_details.inbound_url,
                        "role_permission": response.data.user_details.role_permission,
                        "hotel_id": response.data.user_details.hotel_id,
                        "hotel_name": response.data.user_details.hotel_name,
                        "is_subscribed": response.data.user_details.is_subscribed,
                        // "version_id": response.data.user_details.version_id,
                        // "version_description": response.data.user_details.version_description
                        "mfa": response.data.user_details.mfa,
                    }
                }
            }
        } else {
            currentUserResData = {
                "success": response.success,
                "message": response.message
            }
        }
    }
    return currentUserResData;
}

export const verifySoftwareTokenPut = (roledata) => {
    return roledata.data
}
export const mfaAssignPut = (roledata) => {
    return roledata.data
}