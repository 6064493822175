import React, { Component } from 'react';
import '../Assets/css/paymentdoc.scss';
import '../Assets/css/paymentresponsivedoc.scss';
import { verifyUserToken, paymentDetailsApi } from '../Controller/PaymentController'
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse } from '../../../../Actions/AllAction';
import { withRouter } from 'react-router-dom';
import { setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';

class DecisionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPaid: false,
            paymentDetailsResponseData: {},
            paymentMsg: "",
            textColor: ""
        }
    }


    componentDidMount() {
        //console.log("this.props.match", this.props.match)
        this.verifyUserCredentials();
        //console.log("userCredentials", userCredentials)
    }



    verifyUserCredentials = () => {
        const { userCredentials } = this.props
        const newPaymentTokenHss = this.props.match.params.hss;
        const newPaymentTokenPss = this.props.match.params.pss;
        const newPaymentTokenSss = this.props.match.params.sss;
        const newPaymentToken = newPaymentTokenHss + '.' + newPaymentTokenPss + '.' + newPaymentTokenSss;
        const tokenParts = newPaymentToken.split('.');
        const payloadBase64 = tokenParts[1];
        const decodedPayload = JSON.parse(atob(payloadBase64));
        //console.log("decodedPayload", decodedPayload)

        // {
        //     "quotation_number": "QT - 170477949292356389/2024",
        //     "booking_number": "170477949292356389/2024",
        //     "customer_name": "Soumya Ghosh",
        //     "customer_phone": "+917384245169",
        //     "customer_email": "soumya.ghosh@mettletech.in",
        //     "customer_id": 202,
        //     "type": "quotation",
        //     "minimum_booking_amount": 50.6,
        //     "minimum_percent": 20,
        //     "total_booking_amount": "253.00",
        //     "iat": 1704779494,
        //     "exp": 1704790294
        //   }


        this.checkAllUserDetails(decodedPayload)

        // if (decodedPayload.hasOwnProperty('pay_request_id') && decodedPayload.pay_request_id != null) {
        //     let id = decodedPayload.pay_request_id;
        //     this.getUserPaymentDetails(id, decodedPayload);
        // }

    }

    formatPaymentDetailsDataset = (decodedPayload)=>{
        let paymentDetailsHash= {}

        paymentDetailsHash['quotation_number']= decodedPayload.quotation_number

        return paymentDetailsHash
    }

    checkAllUserDetails = (decodedPayload) => {
        //console.log("decodedPayload", decodedPayload);
        let paymentDetailsDataset= this.formatPaymentDetailsDataset(decodedPayload)
        const { userCredentials } = this.props
        if (Object.keys(userCredentials).length > 0) {

            this.props.history.push(`/${localStorage.getItem('i18nextLng')}/payment/${btoa(JSON.stringify(decodedPayload))}`);

        } else {
            //console.log("===========================7")
            this.props.history.push(`/${localStorage.getItem('i18nextLng')}/login/${btoa(decodedPayload.user_email)}/${btoa(decodedPayload.pay_request_id)}/${btoa(decodedPayload.booking_id)}`);
        }

    }


    // getUserPaymentDetails = (id, decodedPayload) => {
    //     const { loaderStateTrue, loaderStateFalse } = this.props;
    //     console.log("id-------->", id)
    //     loaderStateTrue();
    //     paymentDetailsApi(id).then((response) => {
    //         console.log("response-------->", response)
    //         if (response.success) {
    //             this.setState({
    //                 paymentDetailsResponseData: response.data
    //             })
    //             if (response.data.hasOwnProperty('is_paid') && response.data.is_paid != null) {
    //                 this.setState({
    //                     isPaid: response.data.is_paid
    //                 }, () => {
    //                     if (this.state.isPaid == true) {
    //                         this.setState({
    //                             paymentMsg: "You have already completed your payment.",
    //                             textColor: 'green'
    //                         })
    //                     } else {
    //                         this.checkAllUserDetails(decodedPayload)
    //                     }
    //                 })
    //             }
    //         }
    //         loaderStateFalse();
    //     }).catch((error) => {
    //         console.error("************error*************", error)
    //         if (error) {
    //         }
    //         loaderStateFalse();
    //         if (error.message == "Network Error") {
    //         }
    //     });

    // }

    // checkAllUserDetails = (decodedPayload) => {
    //     console.log("decodedPayload", decodedPayload);
    //     const { userCredentials } = this.props
    //     if (Object.keys(userCredentials).length > 0) {
    //         if (decodedPayload.cognito_status == "FORCE_CHANGE_PASSWORD") {
    //             console.log("===========================1")
    //             this.props.history.push(`/${localStorage.getItem('i18nextLng')}/login/${btoa(decodedPayload.user_email)}/${btoa(decodedPayload.pay_request_id)}/${btoa(decodedPayload.booking_id)}`);
    //         } else {
    //             console.log("===========================2")
    //             const isTokenExpired = decodedPayload.exp && decodedPayload.exp * 1000 < Date.now();
    //             console.log("isTokenExpired", isTokenExpired)
    //             if (isTokenExpired) {
    //                 console.log("===========================3")
    //                 this.setState({
    //                     paymentMsg: "The link has been expired. Please contact with the adminstrator.",
    //                     textColor: 'red'
    //                 })
    //                 // this.props.history.push(`/${localStorage.getItem('i18nextLng')}/login/${decodedPayload.user_email}/${decodedPayload.pay_request_id}`);
    //             } else {
    //                 console.log("===========================4")
    //                 // console.log("decodedPayload.user_email", decodedPayload.user_email)
    //                 // console.log("userCredentials.user_details.user_email", userCredentials.user_details.user_email)

    //                 if (decodedPayload.user_email == userCredentials.user_details.user_email) {
    //                     console.log("===========================5")
    //                     this.props.history.push(`/${localStorage.getItem('i18nextLng')}/payment/${btoa(decodedPayload.user_email)}/${btoa(decodedPayload.pay_request_id)}/${btoa(decodedPayload.booking_id)}`);
    //                 } else {
    //                     console.log("===========================6")
    //                     this.props.history.push(`/${localStorage.getItem('i18nextLng')}/login/${btoa(decodedPayload.user_email)}/${btoa(decodedPayload.pay_request_id)}/${btoa(decodedPayload.booking_id)}`);
    //                 }
    //             }
    //         }
    //     } else {
    //         console.log("===========================7")
    //         this.props.history.push(`/${localStorage.getItem('i18nextLng')}/login/${btoa(decodedPayload.user_email)}/${btoa(decodedPayload.pay_request_id)}/${btoa(decodedPayload.booking_id)}`);
    //     }

    // }

    render() {
        return (
            <div className="">
                <h6 style={{ color: this.state.textColor }}>{this.state.paymentMsg}</h6>
            </div>
        );
    }

}


const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, setUserCredentials })(withRouter(DecisionPage));
